import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";


const Paginate = ({pages,page,keyword,isAdmin,isProductList,isUserList,isOrderList, isMyOrderList}) => {
  return (
    pages> 1 && (
        <Pagination>
            {[...Array(pages).keys()].map((x)=>(
               <LinkContainer
               key={x+1}
               to={
              !isAdmin 
           ?
           (keyword
                   ?`/search/${keyword}/page/${x+1}` 
                   :(isMyOrderList ? `/myorderlist/${x+1}` : `/page/${x+1}`)
             ) : (isProductList ? `/admin/productlist/${x+1}`:(isUserList? `/admin/userlist/${x+1}` :(isOrderList && `/admin/orderlist/${x+1}`)))
               }>
              <Pagination.Item active={x+1===page}>{x+1}</Pagination.Item>
               </LinkContainer> 
            ))}
        </Pagination>
    )
  )
}

export default Paginate