import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    xmlData: '',
  };
const xmlSlice = createSlice({
    name: 'xml',
    initialState,
    reducers: {
        setXmlData : (state,action) => {
            state.xmlData = action.payload;
            return state;
        },
        
    }
});

export const {setXmlData} = xmlSlice.actions; 
export default xmlSlice.reducer; 