import {configureStore} from '@reduxjs/toolkit';
import {apiSlice} from './slices/apiSlice';
import cartSliceReducer from './slices/cartSlice';
import authSliceReducer from './slices/authSlice';
import discountSliceReducer from './slices/discountSlice';
import xmlSliceReducer from './slices/xmlSlice';
const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        cart: cartSliceReducer, 
        auth: authSliceReducer,
        discount: discountSliceReducer,
        xml: xmlSliceReducer,      
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true,
});

export default store;