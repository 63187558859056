import React from 'react'
import {Image} from 'react-bootstrap';

const AboutUs = () => {
  return (
    <div style={{ marginTop: '100px' }} >
    <h4><strong>Hakkımızda </strong> </h4>
    <p>
    ADP Endüstriyel Otomasyon Sistemleri Sanayi ve Ticaret Limited Şirketi, 2013 yılından itibaren gıda, enerji, arıtma, kimya, metal ve geri dönüşüm tesisleri alanında yurtiçi ve yurtdışı birçok projede çözümler üretmiştir. Mühendislik deneyim ve yeteneklerini kullanarak sunduğu endüstriyel çözümleri, çağı yakalayan ve dijital dönüşümü misyon edinen ekipler ile gerçekleştirmektedir. Proses ve Makina otomasyon sistemlerindeki teknolojileri takip edip en güncel çözümleri müşterilerine sunmaktadır. Sürekli gelişmeyi,geliştirmeyi, sürdürebilir yapı ve çevresine azami fayda sağlamayı kendisine vizyon edinen ADP Endüstriyel Otomasyon, müşterilerinin üretimine vermiş olduğu katkılardan dolayı mutluluk duyar.
    </p>
    <h4><strong>Mühendislik </strong> </h4>
    <p>
    Deneyimli teknik ekibimizin sağladığı endüstriyel çözümler, küçük,orta ve büyük ölçekli endüstriyel sistemlerde uygulanabilmektedir.Farklı PLC, DCS, HMI, SCADA, Network, Servo ve Asenkron Motor Sürücü sistemlerine hakim olan ekibimiz belirtilen alanlarda sizlere en uygun hizmeti verebilmektedir.

Fabrika Proses Otomasyonu
Makine Otomasyonu
Hareket Kontrol Sistemleri
SCADA,HMI ve Panel Yazılımları
Veri Depolama,İşleme ve Raporlama Yazılımları
IOT,Bilgisayar ve Mobil Yazılım Uygulamaları
</p>
<h4><strong>Ar-Ge </strong> </h4>
<p>
Endüstri 4.0 normlarında gelişen teknolojiyi takip etmenin yanında tecrübe ve bilgiden faydalanarak üretilen yenilikçi endüstriyel çözümler için yazılım ve donanım tasarımı gerçekleştirmektedir. ADP Endüstriyel Otomasyon bünyesinde tasarlanan prototip endüstriyel yazılım ve donanım sistemleri Tübitak tarafından desteklenmektedir. Dijital dönüşüm ve IOT uygulamaları ADP Endüstriyel Otomasyonun önem verdiği öncelikli projelerdir.
Ar-ge projelerinde bünyesindeki yüksek mühendislerin fikir ve çalışmalarını üniversite akademisyen desteği ile birleştirerek Üniversite-Sanayi işbirliğinden azami şekilde faydalanmaktadır. Türkiye ve dünya çapında üreteceği teknolojik uygulamaların sanayi üretiminde etkin olarak kullanılması, ADP Endüstriyel Otomasyonun ilk sıradaki vizyon hedefidir.
</p>
<h4><strong>Danışmanlık </strong> </h4>
<p>
Makine ve proses imalatı gerçekleştiren işletmelerin otomasyon sistemleri ekipman,pano,şalt malzemeler,sensör ve aktuatör seçimlerinde fiyat performans,tedarik süresi ve çeşitli faktörlere göre uygunluğunu gösteren ve imalat ve devreye alma süreçlerinde planlama,performans,verimlilik ve süreç yönetimi hususunda belirlenen kriterlerin kontrolünü sağlayabilen, işletmedeki yöneticiler için özel uygulama yazılımları geliştirilerek danışmanlık hizmeti verilmektedir.
Öncelikle işletme yöneticileri ile görüşmeler yapılarak işletme için analizler gerçekleştirilir. Sonrasında, kullanıcısına göre kullanım yetki alanlarında belirlenen çıktı ve kontrolleri sağlayan bilgisayar ve mobil uygulama yazılımları hazırlanarak işletmenin verimliliğini artıracak danışmanlık hizmeti sağlanır. Talep doğrultusunda, yapılan yazılım uygulamalarına zamanla revizyonlar ve yeniden yapılandırmalar sağlanarak şartlara göre güncellemeler gerçekleştirilir.
</p>
<h4><strong>Eğitim </strong> </h4>
<p>
Endüstriyel otomasyon projelerinde üretilen yazılımların, kütüphanelerin uygulandığı makina ya da proseslerin çalışma algoritmalarının sonucu olan fonksiyonların çalışması ve kullanımı için yazılı dökümanlar oluşturularak ilgili kullanıcı yetkilisine eğitimleri verilir. Yazılım sonrası proje devreye alma ve sonrasında proses veya makina kullanıcısının ihtiyaç duyacağı çalışma kullanım klavuzu ilgili dökümanlar oluşturulur ve işletmede eğitim hizmeti verilir.

Talep doğrultusunda, çeşitli endüstriyel otomasyon sistemlerinin kullanım, konfigürasyon,yazılım,test,simülasyon ve uygulamaları hususunda farklı marka ürünler için yetkin ekibimiz tarafımızdan eğitimler gerçekleştirilir.
</p>
<h4><strong>Referanslar </strong> </h4>
<Image src="/uploads\Unilever70.jpg" alt="Unilever"/> <Image src="/uploads\Knorr70.jpg" alt="Knorr"/> <Image src="/uploads\Lipton70.jpg" alt="Lipton"/> <Image src="/uploads\Danone70.jpg" alt="Danone"/> <Image src="/uploads\Tübitak70.jpg" alt="Tübitak"/> 
    </div>
  )
}

export default AboutUs