import {useEffect,useState} from 'react';
import { useSelector } from 'react-redux';
import {Row, Col,Button} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import Product from '../components/Product';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import ProductCarousel from '../components/ProductCarousel';
import { useGetProductsQuery } from '../slices/productsApiSlice';




const HomeScreen = () => {
const {userInfo} = useSelector((state)=> state.auth );

const {userDiscount} = useSelector((state)=> state.discount);
const {pageNumber,keyword} = useParams();
const {data, isLoading, error} = useGetProductsQuery({keyword,pageNumber});


const [tempData,setData]=useState(userDiscount);
const[change,setChange] = useState();


useEffect(() =>{
  if (data) {

if(userInfo){
setData({...tempData, page:data.page, pages:data.pages, products:[  ...data.products.map(product=>{
 
  // Siemens
  if(product.brand ===userInfo.discountItems[0].brand && product.category === userInfo.discountItems[0].categories[0].category  ) {
   return  {...product,  
   // discount:product.discount, 
    extraDiscount: userInfo.discountItems[0].categories[0].discount,
   // price: product.advertisementShow ? product.price : 0, 
    discountPrice: (product.price -(product.price*(userInfo.discountItems[0].categories[0].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[0].priceShow,
    extraDiscountShow :userInfo.discountItems[0].extraDiscountShow}; 
 } else if(product.brand === userInfo.discountItems[0].brand && product.category === userInfo.discountItems[0].categories[1].category ){
 return  {...product, 
   // discount:product.discount, 
    extraDiscount: userInfo.discountItems[0].categories[1].discount,
   // price: product.advertisementShow ? product.price : 0, 
    discountPrice: (product.price -(product.price*(userInfo.discountItems[0].categories[1].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[0].priceShow,
    extraDiscountShow :userInfo.discountItems[0].extraDiscountShow};
 }else if(product.brand === userInfo.discountItems[0].brand && product.category === userInfo.discountItems[0].categories[2].category ){
  return  {...product,
   // discount:product.discount, 
    extraDiscount: userInfo.discountItems[0].categories[2].discount, 
   // price: product.advertisementShow ? product.price : 0, 
    discountPrice: (product.price -(product.price*(userInfo.discountItems[0].categories[2].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[0].priceShow,
    extraDiscountShow :userInfo.discountItems[0].extraDiscountShow};
  }else if(product.brand === userInfo.discountItems[0].brand && product.category === userInfo.discountItems[0].categories[3].category ){
    return  {...product,
     // discount:product.discount, 
      extraDiscount: userInfo.discountItems[0].categories[3].discount, 
     // price: product.advertisementShow ? product.price : 0, 
      discountPrice: (product.price -(product.price*(userInfo.discountItems[0].categories[3].discount/100))).toFixed(2),
      priceShow :userInfo.discountItems[0].priceShow,
      extraDiscountShow :userInfo.discountItems[0].extraDiscountShow};
    }

  // Yaskawa
    else if(product.brand ===userInfo.discountItems[1].brand && product.category === userInfo.discountItems[1].categories[0].category  ) {
    return  {...product,  
   // discount:product.discount, 
    extraDiscount: userInfo.discountItems[1].categories[0].discount,
   // price: product.advertisementShow ? product.price : 0, 
    discountPrice: ((product.price -(product.price*(userInfo.discountItems[1].categories[0].discount/100))).toFixed(2)),
    priceShow :userInfo.discountItems[1].priceShow,
    extraDiscountShow :userInfo.discountItems[1].extraDiscountShow};
    }else if(product.brand === userInfo.discountItems[1].brand && product.category === userInfo.discountItems[1].categories[1].category ){
    return  {...product,  
   // discount:product.discount, 
    extraDiscount: userInfo.discountItems[1].categories[1].discount,
   // price: product.advertisementShow ? product.price : 0, 
    discountPrice: ((product.price -(product.price*(userInfo.discountItems[1].categories[1].discount/100))).toFixed(2)),
    priceShow :userInfo.discountItems[1].priceShow,
    extraDiscountShow :userInfo.discountItems[1].extraDiscountShow}; 
    }else if(product.brand === userInfo.discountItems[1].brand && product.category === userInfo.discountItems[1].categories[2].category ){
     return  {...product,  
   // discount:product.discount, 
    extraDiscount: userInfo.discountItems[1].categories[2].discount,
   // price: product.advertisementShow ? product.price : 0, 
    discountPrice: ((product.price -(product.price*(userInfo.discountItems[1].categories[2].discount/100))).toFixed(2)),
    priceShow :userInfo.discountItems[1].priceShow,
    extraDiscountShow :userInfo.discountItems[1].extraDiscountShow}; 
     }

     // C
     else if(product.brand ===userInfo.discountItems[2].brand && product.category === userInfo.discountItems[2].categories[0].category  ) {
      return  {...product,  
   // discount:product.discount, 
    extraDiscount: userInfo.discountItems[2].categories[0].discount,
    price: product.price, 
   // discountPrice: (product.price -(product.price*(userInfo.discountItems[2].categories[0].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[2].priceShow,
    extraDiscountShow :userInfo.discountItems[2].extraDiscountShow};
      }else if(product.brand === userInfo.discountItems[2].brand && product.category === userInfo.discountItems[2].categories[1].category ){
      return  {...product,  
  //  discount:product.discount, 
    extraDiscount: userInfo.discountItems[2].categories[1].discount,
  //  price: product.advertisementShow ? product.price : 0, 
    discountPrice: (product.price -(product.price*(userInfo.discountItems[2].categories[1].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[2].priceShow,
    extraDiscountShow :userInfo.discountItems[2].extraDiscountShow}; 
      }else if(product.brand === userInfo.discountItems[2].brand && product.category === userInfo.discountItems[1].categories[2].category ){
       return  {...product,  
  //  discount:product.discount, 
    extraDiscount: userInfo.discountItems[2].categories[2].discount,
  //  price: product.advertisementShow ? product.price : 0, 
    discountPrice: (product.price -(product.price*(userInfo.discountItems[2].categories[2].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[2].priceShow,
    extraDiscountShow :userInfo.discountItems[2].extraDiscountShow}; 
       }
      // D 
       else if(product.brand ===userInfo.discountItems[3].brand && product.category === userInfo.discountItems[3].categories[0].category  ) {
        return  {...product,  
  //  discount:product.discount, 
    extraDiscount: userInfo.discountItems[3].categories[0].discount,
  //  price: product.advertisementShow ? product.price : 0, 
    discountPrice:(product.price -(product.price*(userInfo.discountItems[3].categories[0].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[3].priceShow,
    extraDiscountShow :userInfo.discountItems[3].extraDiscountShow};
        }else if(product.brand === userInfo.discountItems[3].brand && product.category === userInfo.discountItems[3].categories[1].category ){
        return  {...product,  
  //  discount:product.discount, 
    extraDiscount: userInfo.discountItems[3].categories[1].discount,
  //  price: product.advertisementShow ? product.price : 0, 
    discountPrice:(product.price -(product.price*(userInfo.discountItems[3].categories[1].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[3].priceShow,
    extraDiscountShow :userInfo.discountItems[3].extraDiscountShow}; 
        }else if(product.brand === userInfo.discountItems[3].brand && product.category === userInfo.discountItems[1].categories[2].category ){
         return  {...product,  
  //  discount:product.discount, 
    extraDiscount: userInfo.discountItems[3].categories[2].discount,
  //  price: product.advertisementShow ? product.price : 0, 
    discountPrice:(product.price -(product.price*(userInfo.discountItems[3].categories[2].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[3].priceShow,
    extraDiscountShow :userInfo.discountItems[3].extraDiscountShow}; 
         }  
       // E
       else if(product.brand ===userInfo.discountItems[4].brand && product.category === userInfo.discountItems[4].categories[0].category  ) {
        return  {...product,  
 //   discount:product.discount, 
    extraDiscount: userInfo.discountItems[4].categories[0].discount,
 //   price: product.advertisementShow ? product.price : 0, 
    discountPrice: (product.price -(product.price*(userInfo.discountItems[4].categories[0].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[4].priceShow,
    extraDiscountShow :userInfo.discountItems[4].extraDiscountShow};
        }else if(product.brand === userInfo.discountItems[4].brand && product.category === userInfo.discountItems[4].categories[1].category ){
        return  {...product,  
  //  discount:product.discount, 
    extraDiscount: userInfo.discountItems[4].categories[1].discount,
  //  price: product.advertisementShow ? product.price : 0, 
    discountPrice: (product.price -(product.price*(userInfo.discountItems[4].categories[1].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[4].priceShow,
    extraDiscountShow :userInfo.discountItems[4].extraDiscountShow}; 
        }else if(product.brand === userInfo.discountItems[4].brand && product.category === userInfo.discountItems[4].categories[2].category ){
         return  {...product,  
  //  discount:product.discount,
    extraDiscount: userInfo.discountItems[4].categories[2].discount,
  //  price: product.advertisementShow ? product.price : 0, 
    discountPrice: (product.price -(product.price*(userInfo.discountItems[4].categories[2].discount/100))).toFixed(2),
    priceShow :userInfo.discountItems[4].priceShow,
    extraDiscountShow :userInfo.discountItems[4].extraDiscountShow}; 
         }   

 else{
   return  (product);
 }
})]
})

  }else{
    setData({...tempData});

    
  }

setChange(true);
}
},[data]);

const addToCartHandler2 = () =>{
 
  console.log(keyword);

    }

    

  return (
      <>
      <span>&nbsp;</span>
        <h1>&nbsp;</h1>   
      {!keyword ? (<ProductCarousel/> ): (<Link to='/' className='btn btn-light mb-4'>Geri dön</Link>)}
      { isLoading ? (

        <Loader/>
      ) : error ? (<Message variant='danger'>{ error?.data?.error || error.error }</Message>
      ) : ((tempData || data)&&change) && (
      <> { 
        keyword==="Siemens AC Sürücü ve Aksesuarları"? (<h1>Siemens AC Sürücü ve Aksesuarları</h1>)
      :(keyword==="Siemens Servo Sürücü ve Aksesuarları"?<h1>Siemens Servo Sürücü ve Aksesuarları</h1>  
      :(keyword==="Siemens S7-1200 PLC ve Aksesuarları"?<h1>Siemens S7-1200 PLC ve Aksesuarları</h1>
      :(keyword==="Siemens S7-1500 PLC ve Aksesuarları"?<h1>Siemens S7-1500 PLC ve Aksesuarları</h1> 
      :( keyword==="Siemens HMI" ? <h1>Siemens HMI</h1>
      :( keyword==="Yaskawa AC Sürücü ve Aksesuarları" ? <h1>Yaskawa AC Sürücü ve Aksesuarları</h1>
      :( keyword==="Yaskawa Servo Sürücü ve Aksesuarları" ? <h1>Yaskawa Servo Sürücü ve Aksesuarları</h1>
      :( keyword==="Yaskawa Micro PLC ve Aksesuarları" ? <h1>Yaskawa Micro PLC ve Aksesuarları</h1>
      :( keyword==="Yaskawa Slio PLC ve Aksesuarları" ? <h1>Yaskawa Slio PLC ve Aksesuarları</h1>
      :( keyword==="Yaskawa 300S PLC ve Aksesuarları" ? <h1>Yaskawa 300S PLC ve Aksesuarları</h1>
      :( keyword==="Yaskawa HMI" ? <h1>Yaskawa HMI</h1>
      :( !keyword ?  <h1></h1>

      :<h3> "{keyword}" için arama </h3> )))))))))))
    }
      <Row>

        {(!userInfo)?
      (    data.products.map((product) => (
      <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
          <Product product={product} />
          </Col>
           ))
      )
           :
   (      
           tempData.products.map((product) => (
            <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                <Product product={product} />
                </Col>
                 ))
          )
           }
      </Row>
      
      <Paginate
           
           
      pages={(!userInfo)?(data.pages):(tempData.pages)}
      page= {(!userInfo)?(data.page):(tempData.page)}
      keyword={ keyword ? keyword : ''}
      
      />
  
      </>)}
   {/*    <Button
                className='btn-block'
                type='button'
             
                onClick={addToCartHandler2}>
                   bas
            </Button>
       */}        
      </>
   
  );
};

export default HomeScreen