import React from 'react'
import{Table,Button,Col} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import { useParams } from "react-router-dom";
import {useSelector} from 'react-redux';
import Message from '../components/Message'
import Loader from '../components/Loader'
import {FaTimes} from 'react-icons/fa'
import { useGetMyOrdersQuery,useDeleteOrderMutation} from '../slices/ordersApiSlice'
import Paginate from "../components/Paginate";



const MyOrderListScreen = () => {
 const {pageNumber} = useParams();
 const {data, isLoading, error,refetch } = useGetMyOrdersQuery({pageNumber});
 const {userInfo} = useSelector((state)=> state.auth );
 const [deleteOrder, {isLoading:loadingDelete}] = useDeleteOrderMutation();


 const deleteHandler = async (id) => {
  if(window.confirm('Siparişiniz İptal edilecektir. Emin misiniz?')){
    try {
      await deleteOrder(id);
     
      refetch();
    } catch (err) {
    
    }
  }
};



  return (
    <Col md={15}>
      <h2>Siparişlerim</h2>
      {isLoading ?(
        <Loader/>
      ):error ?(<Message variant='danger'>
      {error?.data?.message || error.error}
      </Message>
      ) :(
        <>
        <Table striped hover responsive className='table-sm'>

         <thead>
          <tr>
            <th>SİPARİŞ NO</th>
            <th>TARİH</th>
            <th>FİYAT</th>
            <th>TEKLİF</th>
            <th>SİPARİŞ</th>
            <th>ÖDENDİ</th>
            <th>KARGODA</th>
            <th>TAKİP NO</th>
          </tr>
          </thead>     
          <tbody>
            {data.orders.map((order)=>( order.isActivated &&
              <tr key={order._id}>
                <td>{order._id}</td>
                <td>{order.createdAt.substring(0,10)}</td>
                {(order.isOffered|| userInfo.isAdmin) ?( <td>{order.totalPrice}€</td>):( <td>-----</td>)}
               
                <td>
                  {order.isOffered ?(
                    order.offeredAt.substring(0,10)
                  ) : (
                  <FaTimes style={{color: 'red'}} />
                  )}
                </td>
                <td>
                  {order.isOrdered ?(
                    order.orderedAt.substring(0,10)
                  ) : (
                  <FaTimes style={{color: 'red'}} />
                  )}
                </td>
                <td>
                  {order.isPaid ?(
                    order.paidAt.substring(0,10)
                  ) : (
                  <FaTimes style={{color: 'red'}} />
                  )}
                </td>
                <td>
                  {order.isDelivered ?(
                    order.deliveredAt.substring(0,10)
                  ) : (
                  <FaTimes style={{color: 'red'}} />
                  )}
                </td>
                <td>
                  {order.isDelivered &&
                    order.deliverNumber
                  }
                </td>
                <td>
                  <LinkContainer to={`/order/${order._id}`}>
                    <Button className='btn-sm' variant='light'>
                      İncele
                    </Button>
                  </LinkContainer>
                  <Button variant='light' className = 'btn-sm' onClick={()=> deleteHandler(order._id)}>
                                İptal
                            </Button>
                </td>

              </tr>
            ))}
          </tbody>
          
        </Table> 
       { <Paginate pages={data.pages} page={data.page} isAdmin={false} isOrderList={false} isUserList={false} isProductList={false} isMyOrderList={true}/> }
        </>
      )}
    </Col>
  )
}

export default MyOrderListScreen