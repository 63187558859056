import {useState,useEffect} from 'react'
import {Form, Button, Col,Row} from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FormContainer from '../components/FormContainer'
import { saveShippingAddress } from '../slices/cartSlice'
import CheckoutStatusBar from '../components/CheckoutStatusBar.jsx'
import countryTR from '../assets/styles/countryTR.js'

const UserFill = () => {
  const cart = useSelector((state)=> state.cart);
  const {shippingAddress} = cart;


  const [companyCity, setCompanyCity] = useState(shippingAddress?.companyCity ||'');
  const [city, setCity] = useState(shippingAddress?.companyCity ||'');
  
  const[cities,setCities]=useState(countryTR.find(ctr=>ctr.il===`${shippingAddress.companyState? shippingAddress.companyState :'Seçiniz'}`).ilceleri ||'');
  const [companyState, setCompanyState] = useState(shippingAddress?.companyState|| '' );
  const [state, setState] = useState(shippingAddress?.companyState|| '' );
  const [address,setAddress] = useState(shippingAddress?.companyAddress||'');
  const [companyName, setCompanyName] = useState(shippingAddress?.companyName|| '' );
  const [companyAddress, setCompanyAddress] = useState(shippingAddress?.companyAddress|| '' );
  const [receiver, setReceiver] = useState(shippingAddress?.receiver|| '' );
  const [name, setName] = useState(shippingAddress?.name|| '' );
  const [surname, setSurname] = useState(shippingAddress?.surname|| '' );
  const [email, setEmail] = useState(shippingAddress?.email|| '' );
  const [ phoneNumber, setPhoneNumber] = useState(shippingAddress?. phoneNumber|| '' );
  const [idNumber, setIdNumber] = useState(shippingAddress?.idNumber|| '' );
  const [taxNumber, setTaxNumber] = useState(shippingAddress?.taxNumber|| '' );
  const [taxState, setTaxState] = useState(shippingAddress?.taxState|| '' );
  
  const [isCompany, setIsCompany] = useState(shippingAddress?.isCompany||'false');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const[formErrors,setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [chng,setchng]= useState('');



  const submitHandler = (e) =>{
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmit(true);
  }
  

  const validate = () => {
    const errors ={};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if(!name)
    {
      errors.name = "Adınızı giriniz";
    }
    if(!surname)
    {
      errors.surname = "Soyadını giriniz"
    }
    if(!email){
      errors.email = "Email Adresini giriniz"
    }
    else if(!regex.test(email)){
      errors.email= "Geçersiz Format";
    }
   
    if(!phoneNumber){
      errors.phoneNumber = "telefon numarası giriniz"
    }
 
    if(!companyCity){
      errors.companyCity = "ilçeyi giriniz"
    }
    if(companyState==="Seçiniz"){
      errors.companyState = "Şehri giriniz"
    }
    if(!companyAddress){
      errors.companyAddress = "Adresi giriniz"
    }
    if(isCompany==="true"){
  
     // setIdNumber(" ");
      if(!taxState){
        errors.taxState = "Vergi Dairesini giriniz"
      }
      if(!taxNumber){
        errors.taxNumber = "Vergi Numarasını giriniz"
      }
      if(!companyName){
        errors.companyName = "irma Adını giriniz"
      }
    }
    
  return errors;
  }
  
  
  useEffect(() =>{
    if (chng==="1") {
      submitHandler2();
    }
   
  },[chng]);
  
  
  useEffect(() =>{
    
    if(Object.keys(formErrors).length === 0 && isSubmit)
    {

      submitHandler1();
     
    }
    else
    {

    }
  },[formErrors]);

  useEffect(() =>{
    
    if(isCompany==="true")
    {
      setTaxNumber("");
      setTaxState("");
     
    }
    else
    {
 
    }
  },[isCompany]);
  
  
  
  
  const submitHandler1 =  () =>{
  
    if(isCompany==="true"){
  
      setIdNumber(" ");
    }else
    {
      if(!idNumber)
      {
        setIdNumber(" ")
      }
      if(!companyName){
        setCompanyName(" ");
      }
  
      setTaxNumber(" ")
      setTaxState(" ");
  
    }
    
   setchng("1");
  }


  const submitHandler2 = () => {
   // e.preventDefault();
   setchng(" ");
    dispatch(saveShippingAddress({companyCity,companyState,companyName,companyAddress,address,state,city,receiver,name,surname,email,phoneNumber,idNumber,taxNumber,taxState,isCompany}));
    navigate('/shipping');
   
  };




  const changeState=(e)=>{

setCompanyState(e.target.value);
setState(e.target.value);
setCities(countryTR.find(ctr=>ctr.il===e.target.value).ilceleri);
setCity(countryTR.find(ctr=>ctr.il===e.target.value).ilceleri[0]);
setCompanyCity(countryTR.find(ctr=>ctr.il===e.target.value).ilceleri[0]);
  
  }




  return (
    <FormContainer>
      <CheckoutStatusBar ></CheckoutStatusBar>
    <h3>Müşteri Bilgileri</h3>
    <Form onSubmit={submitHandler}>
      
    
        <Row>
      <Col>
      <Form.Group controlId='name' className='my-2'>
        <Form.Label> Adı</Form.Label>
        <Form.Control
        type='text'
        placeholder='Adınızı giriniz'
        value= {name}
        onChange={(e)=> {
          setName(e.target.value)
          if(isCompany==='false')
          {
            setCompanyName(`${e.target.value} ${surname}`);
            setReceiver(`${e.target.value} ${surname}`);
          }
         
          }}>

        </Form.Control>
        </Form.Group>
        <p style={{ color: 'red' }}>{formErrors.name}</p> 
        </Col>
        <Col>
        <Form.Group controlId='surname' className='my-2'>
        <Form.Label> Soyadı</Form.Label>
        <Form.Control
        type='text'
        placeholder='Soyadınızı giriniz'
        value= {surname}
        onChange={(e)=> {
          setSurname(e.target.value)
          if(isCompany==='false')
          {
            setCompanyName(`${name} ${e.target.value}`)
            setReceiver(`${name} ${e.target.value}`)
          }
          
          }}>

        </Form.Control>
        </Form.Group>
        <p style={{ color: 'red' }}>{formErrors.surname}</p> 
        </Col>
        </Row>
      
     <Row>
      <Col>
      <Form.Group controlId='email' className='my-2'>
        <Form.Label>E-Posta Adresi </Form.Label>
        <Form.Control
        type='text'
        placeholder='Email Adresinizi giriniz'
        value= {email}
        onChange={(e)=> setEmail(e.target.value)}>
        </Form.Control>
        </Form.Group>
        <p style={{ color: 'red' }}>{formErrors.email}</p> 
      </Col>
      <Col>
      <Row>
      <Col xs={12} md={8} lg={4} className='text-center py-0'>
        <Form.Group controlId='phonenumber' className='my-2' style={{  width: 70 }}>
        <Form.Label className='text-center py-0'> Ülke </Form.Label>
        <Form.Control
        type='text'
        value= "+90">
        </Form.Control>
        </Form.Group>
        </Col>
        <Col>
      <Form.Group controlId='phonenumber' className='my-2'>
        <Form.Label>Cep Telefonu </Form.Label>
        <Form.Control
        type='text'
        placeholder='Telefon Numarası giriniz'
        value= {phoneNumber}
        onChange={(e)=>
          e.target.value.length <11 &&  
          setPhoneNumber(e.target.value)}>
        </Form.Control>
        </Form.Group>
        </Col>
       
        <p style={{ color: 'red' }}>{formErrors.phoneNumber}</p> 
        </Row>
      </Col>

     </Row>
     <h5>Fatura Bilgileri</h5>
     <Row>
      <Col>
      
      <input
                    type='radio'
                    className='my-2'
                    label='Kurumsal'
                    id='isCompany'
                    name='isCompany'
                    value= 'true'
                    checked = {isCompany === 'true'}
                    onChange={(e)=> setIsCompany(e.target.value)}
                    ></input> 
      
                     <label htmlFor="Kurumsal">&nbsp;Kurumsal</label>
                      
      </Col>
      <Col>
      <input
                    type='radio'
                    className='my-2'
                    label='Bireysel'
                    id='isCompany'
                    name='isCompany'
                    value= 'false'
                    checked = {isCompany === 'false'}
                    onChange={(e)=> setIsCompany(e.target.value)}
                    ></input>   
                    <label htmlFor="Bireysel">&nbsp;Bireysel</label>
      </Col>
     </Row>
     <Form.Group controlId='companyName' className='my-2'>
        <Form.Label>Ünvan /Adı Soyadı </Form.Label>
        <Form.Control
        type='text'
        placeholder='Ünvan/Ad Soyad giriniz'
        value= {companyName}
        onChange={(e)=> {
          setCompanyName(e.target.value)
          setReceiver(e.target.value)
          }}>

        </Form.Control>
        </Form.Group>
        <p style={{ color: 'red' }}>{formErrors.companyName}</p>
        {(isCompany === 'true') ?
        (
    <Row>
     <Col>
     <Form.Group controlId='taxNumber' className='my-2'>
        <Form.Label>Vergi Numarası </Form.Label>
        <Form.Control
        type='text'
        placeholder='Vergi Numarası giriniz'
        value= {taxNumber}
        onChange={(e)=> setTaxNumber(e.target.value)}>

        </Form.Control>
        <p style={{ color: 'red' }}>{formErrors.taxNumber}</p>
        </Form.Group>
       
      </Col>
      <Col>
      <Form.Group controlId='taxState' className='my-2'>
        <Form.Label>Vergi Dairesi </Form.Label>
        <Form.Control
        type='text'
        placeholder='Vergi Dairesi giriniz'
        value= {taxState}
        onChange={(e)=> setTaxState(e.target.value)}>
        </Form.Control>
        <p style={{ color: 'red' }}>{formErrors.taxState}</p>
        </Form.Group>  
        
      </Col>

     </Row>
        )
        :
        (
          <Row>
          <Form.Group controlId='idNumber' className='my-2'>
             <Form.Label>T.C. Kimlik Numarası </Form.Label>
             <Form.Control
             type='text'
             placeholder='T.C. Kimlik Numaranızı giriniz'
             value= {idNumber}
             onChange={(e)=> setIdNumber(e.target.value)}>
             </Form.Control>
             </Form.Group>  
     
          </Row>

        )}
     
    

     <Row>

      <Col>
      <Form.Group controlId='companyState' className='my-2'>
        <Form.Label> İl </Form.Label>
 
       <select className="form-control" value= {companyState}  onChange={changeState} >
       
       
       {countryTR.map(ctr=>(
        <option value={ctr.il}>{ctr.il}</option>
       ))}
       </select>
      </Form.Group> 
      <p style={{ color: 'red' }}>{formErrors.companyState}</p>
      </Col>

      <Col>
      <Form.Group controlId='companyCity' className='my-2'>
        <Form.Label> İlçe </Form.Label>
      
     
        <select className="form-control" value= {companyCity}  onChange={(e)=> {
          setCompanyCity(e.target.value)
          setCity(e.target.value)
          }} >
        
       {cities.map(ctr=>(
        <option value={ctr}>{ctr}</option>
       ))}
       </select>

      </Form.Group>
      <p style={{ color: 'red' }}>{formErrors.companyCity}</p>
      </Col>
     </Row>

     <Row>
     <Form.Group controlId='companyAddress' className='my-2'>
        <Form.Label>Fatura Adresi </Form.Label>
        <Form.Control
       as="textarea" rows={3}
        placeholder='Fatura Adresini giriniz'
        value= {companyAddress}
        onChange={(e)=> {
          setCompanyAddress(e.target.value)
          setAddress(e.target.value)
        }}>
        </Form.Control>
        </Form.Group> 
        <p style={{ color: 'red' }}>{formErrors.companyAddress}</p> 
     </Row>
     
     
      <Button type='submit' variant='primary' className='my-2'>
        Devam
        </Button>   
       {/*  <Button
                  type='button'
                  className='btn-block'

                  onClick={placeOrderHandler1}
                >
                
                 bas1
                </Button>
*/}

    </Form>
  </FormContainer>
  )
}

export default UserFill;