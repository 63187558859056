const addDecimals = (num) => {
    return(Math.round(num*100)/100).toFixed(2);
};


export const updateCart = (state) => {


    // Calculate items price
    if(state.cartItems.length>0){

//const offer = state.cartItems.find(({priceShow})=>{return priceShow === false});


//    if( offer){
//        state.itemsPrice = addDecimals(state.cartItems.reduce((acc,item) =>acc + (item.price-(item.price*item.discount/100))*item.qty,0));
//    }else{
        state.itemsPrice = addDecimals(state.cartItems.reduce((acc,item) =>acc + item.discountPrice*item.qty,0));
 //   }
}
    // Calculate shipping price
   // state.shippingPrice=  addDecimals(state.itemsPrice > 100 ? 0 : 10);
    // Calculate tax price
    state.taxPrice = addDecimals(Number((0.20*state.itemsPrice).toFixed(2)));
    // Calculate total price
    state.totalPrice = (
        Number(state.itemsPrice) +
     //   Number(state.shippingPrice) + 
        Number(state.taxPrice)
    ).toFixed(2);
    
   
    localStorage.setItem('cart',JSON.stringify(state));
    return state;
}