import {useState,useEffect} from 'react'
import {Form, Button, Col,Row} from 'react-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FormContainer from '../components/FormContainer'
import { saveShippingAddress } from '../slices/cartSlice'

import CheckoutStatusBar from '../components/CheckoutStatusBar.jsx'
import CheckoutStatusBar1 from '../components/CheckoutStatusBar1.jsx'
import countryTR from '../assets/styles/countryTR.js'

const ShippingScreen = () => {

  const cart = useSelector((state)=> state.cart);
  const {shippingAddress} = cart;
  const {userInfo} = useSelector((state)=> state.auth );
  
  const [companyCity] = useState( shippingAddress?.companyCity ||'');
  const [companyState] = useState(shippingAddress?.companyState|| '' );

  const [companyName] = useState( shippingAddress?.companyName|| '' );
  const [companyAddress] = useState(shippingAddress?.companyAddress|| '' );
 
  const [name] = useState(shippingAddress?.name|| '' );
  const [surname] = useState(shippingAddress?.surname|| '' );
  const [email] = useState(shippingAddress?.email|| '' );
  const [ phoneNumber] = useState(shippingAddress?. phoneNumber|| '' );
  const [idNumber] = useState(shippingAddress?.idNumber|| '' );
  const [taxNumber] = useState(shippingAddress?.taxNumber|| '' );
  const [taxState] = useState(shippingAddress?.taxState|| '' );
  
  const [isCompany] = useState(shippingAddress?.isCompany);



    const [address, setAddress] = useState(shippingAddress?.address|| '' );
    const [city, setCity] = useState(cart.shippingAddress?.city||shippingAddress?.companyCity ||'');
    const [state, setState] = useState(cart.shippingAddress?.state || shippingAddress?.companyState|| '' );
    const [receiver, setReceiver] = useState(cart.shippingAddress?.receiver || shippingAddress?.receiver|| '' );
    const [cargoSelection, setCargoSelection] = useState(cart.shippingAddress?.cargoSelection || shippingAddress?.cargoSelection|| 'Yurtiçi Kargo' );
    const[cities,setCities]=useState(countryTR.find(ctr=>ctr.il===`${shippingAddress.companyState? shippingAddress.companyState :'Seçiniz'}`).ilceleri ||'');
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    
    const[formErrors,setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [chng,setchng]= useState('');



    const submitHandler = (e) =>{
      e.preventDefault();
      setFormErrors(validate());
      setIsSubmit(true);
    }
    
    
  
    const validate = () => {
      const errors ={};
     
      if(!city){
        errors.city = "companyCity is required"
      }
      if(state==="Seçiniz"){
        errors.state = "companyState is required"
      }
      if(!address){
        errors.address = "companyAddress is required"
      }
      
    return errors;
    }
    
    
    useEffect(() =>{
      if (chng==="1") {
        submitHandler2();
      }
     
    },[chng]);
    
    
    useEffect(() =>{
      
      if(Object.keys(formErrors).length === 0 && isSubmit)
      {

        submitHandler1();
       
      }
      else
      {

      }
    },[formErrors]);
    
    
    
    
    const submitHandler1 =  () =>{
     
     setchng("1");
    }
  
    const submitHandler6 =  () =>{
      
      
     }


    const submitHandler2 = () => {
      //e.preventDefault();
      setchng("");
      dispatch(saveShippingAddress({address,city,state,receiver,cargoSelection,companyCity,companyState,companyName,companyAddress,name,surname,email,phoneNumber,idNumber,taxNumber,taxState,isCompany}));
      navigate('/payment');
    };
    const changeState=(e)=>{

      setState(e.target.value);
      setCities(countryTR.find(ctr=>ctr.il===e.target.value).ilceleri);
         
        }

  return (
    
    <FormContainer>
      {userInfo ? ( <CheckoutStatusBar1 ></CheckoutStatusBar1>):( <CheckoutStatusBar userfill ></CheckoutStatusBar>)}
    <h3>Sevkiyat</h3>
    <Form onSubmit={submitHandler}>
    <Row>
      <Col>
      <Form.Group controlId='receiver' className='my-2'>
        <Form.Label> Alıcı</Form.Label>
        <Form.Control
        type='text'
        placeholder='Alıcı giriniz'
        value= {receiver}
        onChange={(e)=> {
         // setName(e.target.value)
          setReceiver(e.target.value)
         
          }}>

        </Form.Control>
        </Form.Group>
        </Col>
</Row>

    <Row>

<Col>
<Form.Group controlId='state' className='my-2'>
  <Form.Label> İl </Form.Label>

 <select className="form-control" value= {state}  onChange={changeState} >

 
 {countryTR.map(ctr=>(
  <option value={ctr.il}>{ctr.il}</option>
 ))}
 </select>
</Form.Group> 
<p style={{ color: 'red' }}>{formErrors.state}</p>
</Col>

<Col>
<Form.Group controlId='city' className='my-2'>
  <Form.Label> İlçe </Form.Label>


  <select className="form-control" value= {city}  onChange={(e)=> {
    setCity(e.target.value)
    }} >
  
 
 {cities.map(ctr=>(
  <option value={ctr}>{ctr}</option>
 ))}
 </select>

</Form.Group>
<p style={{ color: 'red' }}>{formErrors.city}</p>
</Col>
</Row>
<Row>
      <Form.Group controlId='address' className='my-2'>
        <Form.Label> Sevk Adresi</Form.Label>
        <Form.Control
       as="textarea" rows={3}
        placeholder='Sevk Adresini Giriniz'
        value= {address}
        onChange={(e)=> setAddress(e.target.value)}>

        </Form.Control>
       
      </Form.Group>
      <p style={{ color: 'red' }}>{formErrors.address}</p>
      </Row>  
      <h3>Kargo Tercihi</h3>  
      <Row>
     <Col>
      <input
                    type='radio'
                    className='my-2'
                    label='Yurtiçi'
                    id='yurtici'
                    name='cargo'
                    value= 'Yurtiçi Kargo'
                    checked = {cargoSelection === 'Yurtiçi Kargo'}
                    onChange={(e)=> setCargoSelection(e.target.value)}
                    ></input> 
                     <label htmlFor="Yurtiçi Kargo">&nbsp;<img src='/images/yurtici.jpg' /> &nbsp;{'Alıcı Ödemeli'}</label>  
      </Col>
      <Col>
      <input
                    type='radio'
                    className='my-2'
                    label='Aras'
                    id='aras'
                    name='arasCargo'
                    value= 'Aras Kargo'
                    checked = {cargoSelection === 'Aras Kargo'}
                    onChange={(e)=> setCargoSelection(e.target.value)}
                    ></input>   
                    <label htmlFor="Aras Kargo">&nbsp;<img src='/images/aras.jpg' /> {'Alıcı Ödemeli'}</label>
      </Col>

    

     </Row>
     <span>


</span>
     
      <Button type='submit' variant='primary' className='my-2'>
        Devam
        </Button>   
    
    </Form>
  </FormContainer>
    
  ) 
}

export default ShippingScreen