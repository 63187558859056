import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    userDiscount: localStorage.getItem('userDiscount') ? JSON.parse(localStorage.getItem('userDiscount')) : null,
}
const discountSlice = createSlice({
    name: 'discount',
    initialState,
    reducers: {
        setDiscount : (state,action) => {
            state.userInfo = action.payload;
            localStorage.setItem('userDiscount',JSON.stringify(action.payload));
        },
        resetDiscount : (state,action) => {
            state.userInfo = null;
            localStorage.removeItem('userDiscount');
            localStorage.removeItem('cart');
        },

        
    }
});

export const {setDiscount,resetDiscount} = discountSlice.actions; 
export default discountSlice.reducer; 