import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {Form, Button, Col, Row, FormGroup, FormCheck, Placeholder} from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import CheckoutStatusBar from '../components/CheckoutStatusBar.jsx'
import CheckoutStatusBar1 from '../components/CheckoutStatusBar1.jsx'
import { savePaymentMethod,saveCreditCard } from '../slices/cartSlice'
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css'



const PaymentScreen = () => {
 
  const cart = useSelector((state)=>state.cart);
  const {shippingAddress,creditCard} = cart; 
  

const {userInfo} = useSelector((state)=> state.auth );
const [paymentMethod,setPaymentMethod] = useState(cart?.paymentMethod || 'Banka Transferi');


const dispatch = useDispatch();
const navigate = useNavigate();

const [name,setName] = useState(creditCard?.name || '');
const [cvv,setCvv] = useState(creditCard?.cvv || '');
const [expiry,setExpiry] = useState(creditCard?.expiry || '');
const [number,setNumber] = useState(creditCard?.number || '');
const [ip,setIp] = useState(creditCard?.ip || '');


const[formErrors,setFormErrors] = useState({});
const [isSubmit, setIsSubmit] = useState(false);


const offer = cart.cartItems.find(({priceShow,advertisementShow})=>{return priceShow === false && advertisementShow===false});


useEffect(()=>{
  if(paymentMethod==="Kredi Kartı")

  fetch('https://api.ipify.org')
  .then((res)=>res.text())
  .then(ip=> setIp(ip))
  console.log(ip)
},[paymentMethod])


useEffect(()=>{
    if(!shippingAddress){
        navigate('/shipping');
    }
},[shippingAddress,navigate]);


useEffect(() =>{
    
  if(Object.keys(formErrors).length === 0 && isSubmit)
  {
    submitHandler1();
  }
  else
  {

  }
},[formErrors]);


const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));

    if(paymentMethod === 'Kredi Kartı'&& !offer)
    {
    setFormErrors(validate());
    setIsSubmit(true);
    dispatch(saveCreditCard({number,name,expiry,cvv,ip}));
    }else{
      navigate('/placeorder');
    }

    
}


const submitHandler1 = (e) => {
  navigate('/placeorder');
}

const validate = () => {
  const errors ={};
  if(!name)
  {
    errors.name = "Kart üzerindeki adı soyadı giriniz";
  }
  if(number.length<16)
  {
    errors.number = "Kart Numarası eksik";
  }
  if(!number)
  {
    errors.number = "Kart Numarasını giriniz"
  }
  if(expiry.length<4)
  {
    errors.expiry = "Ay/Yıl eksik";
  }
  if(!expiry ){
    errors.expiry = "Ay/Yıl giriniz"
  }
  if(cvv.length<3)
  {
    errors.cvv = "Cvv Numarası eksik";
  }
  if(!cvv  ){
    errors.cvv = "Cvv numarasını giriniz"
  }  
return errors;
}




const placeOrder1= async () => {

  

   
 }

 const placeOrder2 =() => {
  
 }

  return (
    <FormContainer>
        {userInfo ? ( <CheckoutStatusBar1 shipping ></CheckoutStatusBar1>):( <CheckoutStatusBar userfill shipping ></CheckoutStatusBar>)}
       
            <h3>Ödeme Şekli</h3>
            <Form onSubmit={submitHandler}>
           
                <FormGroup>
                    <Form.Label as='legend'>Tercih</Form.Label>
                    <Row>
                    <Col>
                    <Form.Check
                    type='radio'
                    className='my-2'
                    label='Banka Transferi'
                    id='Banka Transferi'
                    name='paymentMethod'
                    value='Banka Transferi'
                    checked = {paymentMethod === 'Banka Transferi'}
                    onChange={(e)=> setPaymentMethod(e.target.value)}
                    ></Form.Check>   
                    </Col>
                    {paymentMethod==="Banka Transferi" &&
                    <Col>
                    <Row>
                     <strong>
                     ADP ENDÜSTRİYEL OTOMASYON SİSTEMLERİ SAN. VE TİC. LTD. ŞTİ.
                        </strong> 
                    </Row>
                    <Row>
                    Kuveyt Türk Katılım Bankası-Fikirtepe Şubesi 
                    </Row>
                    <Row>
                    TRY IBAN:TR82 0020 5000 0977 1989 0000 02 
                    </Row>     
                    </Col>}
                    </Row>
                </FormGroup>
                &nbsp;

                <FormGroup>
                    <Row>
                    <Col>
                    <Form.Check
                    type='radio'
                    className='my-2'
                    label='Kredi Kartı'
                    id='Banka Transferi'
                    name='paymentMethod'
                    value='Kredi Kartı'
                    checked = {paymentMethod === 'Kredi Kartı'}
                    onChange={(e)=> setPaymentMethod(e.target.value)}
                    ></Form.Check>   
                    </Col>
                    <Col>
                    {paymentMethod==="Kredi Kartı" && !offer &&
                 
                    <div id="PaymentForm">
                    <Cards
                      cvc={cvv}
                      expiry={expiry}
                      //focused={cvv}
                      name= {name}
                      number ={number}
                    />
                    <form>
                      <Form>
                      <Row>
                        <Col xs={12} md={12}>
                        <Form.Group className='my-3'>
                        <Form.Control
                        type="text"
                        name="number"
                        placeholder="KART NUMARASI"
                        value= {number}
                        onChange={(e)=> e.target.value.length <17 && 
                          setNumber(e.target.value)}
                        ></Form.Control>  
                      </Form.Group>
                      <p style={{ color: 'red' }}>{formErrors.number}</p> 
            </Col>
            </Row>
            <Row>
            <Col xs={12} md={12}>
            <Form.Group className='my-3'>
            <Form.Control
                        type="text"
                        name="name"
                        placeholder="ADI SOYADI"
                        value= {name}
                        onChange={(e)=> setName(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <p style={{ color: 'red' }}>{formErrors.name}</p> 
                      </Col>
                      
                      </Row>
                      <Row>
                      <Col xs={12} md={6}>
                        <Form.Group className='my-2'>
                        <Form.Control
                        type="text"
                        name="expiry"
                        placeholder="AY/YIL"
                        value= {expiry}
                        onChange={(e)=>
                          e.target.value.length <5 &&  
                          setExpiry(e.target.value)}
                        ></Form.Control>
                     </Form.Group>
                     <p style={{ color: 'red' }}>{formErrors.expiry}</p> 
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className='my-2'>
                        <Form.Control
                        type="text"
                        name="cvv"
                        placeholder="CVV"
                        value= {cvv}
                        onChange={(e)=>
                          e.target.value.length <4 &&  
                          setCvv(e.target.value)}
                        ></Form.Control>
                       </Form.Group>
                       <p style={{ color: 'red' }}>{formErrors.cvv}</p> 
                      </Col>
          </Row>
          
          </Form>
                    </form>
                  </div>
                    
                    
                    
                    }
                    
                    
                    </Col>
                    </Row>
                </FormGroup>
                <Col className='text-left py-5'>
                <Button type='submit' variant='primary'> Devam</Button>
                </Col>
            </Form>
             
             <div>
     
    </div>
                
                 </FormContainer>
                 
        
  )
}

export default PaymentScreen