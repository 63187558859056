import { useState,useEffect } from "react";
import {Link,useNavigate, useParams} from "react-router-dom";
import {Form,Button,Col,Row} from "react-bootstrap";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import FormContainer from "../../components/FormContainer";
import { useGetUserDetailsQuery,useUpdateUserMutation,useAddCategoryMutation } from "../../slices/usersApiSlice";

const UserEditScreen = () => {

    const {id: userId} = useParams();
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [isAdmin,setIsAdmin] = useState(false);
    const [isConfirm,setIsConfirm] = useState(false);

    const[brand,setBrand] = useState();
    const[category,setCategory] = useState();
    const[discount,setDiscount] = useState();
 

    const {data:user, isLoading, refetch, error} = useGetUserDetailsQuery(userId);
   

    const [addCategory] = useAddCategoryMutation();

    const [updateUser, {isLoading: loadingUpdate}] = useUpdateUserMutation();
    const[tempUser, setTempUser] = useState();
    const[tempDiscountItems, setTempDiscountItems] = useState();

    
    const navigate = useNavigate();

    useEffect(()=>{
        if(user){
        setName(user.name);
        setEmail(user.email);
        setIsAdmin(user.isAdmin);
        setIsConfirm(user.isConfirm);
        setTempUser(user);
        console.log(tempUser);
       // setTempDiscountItems(tempUser.discountItems);
       
    }
    },[user]);

    useEffect(()=>{
      if(tempUser){
        setTempDiscountItems(tempUser.discountItems);
      }
    },[tempUser]);
    

const submitHandler= async (e) => {
    e.preventDefault();
   try {
    await updateUser({userId,name,email,isAdmin,isConfirm,tempDiscountItems});
    
    refetch();
    navigate('/admin/userlist')

   } catch (err) {
    
   }
    

   
};  


const placeOrderHandler1= async () => {
 
console.log(tempUser);
 
};  

const addCategory1 =async () => {

 
  
    await addCategory(userId);
  

   
  };  

  return ( 
  <>
  <Link to='/admin/userlist' className="btn btn-light my-3">
    Go Back
  </Link>
  <FormContainer>
   
    {loadingUpdate && <Loader/>}
    {isLoading ? <Loader/> : error ? <Message variant='danger'>
        {error}</Message> :( tempUser &&
          
        <Form onSubmit={submitHandler}>
          <Row>
          
      <Col>
      <h1>Edit User</h1>
            <Form.Group controlId='name' className="my-1">
            <Form.Label>Name</Form.Label>
            <Form.Control
            type='name'
            placeholder="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            ></Form.Control>
            </Form.Group>

            <Form.Group controlId='Email' className="my-1">
            <Form.Label>Email</Form.Label>
            <Form.Control
            type='email'
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
            </Form.Group>

            <Form.Group controlId='isAdmin' className="my-1">
               <Form.Check
               type="checkbox"
               label ="Is Admin"
               checked = {isAdmin}
               onChange={(e)=> setIsAdmin(e.target.checked)}
               ></Form.Check> 

            </Form.Group>


            <Form.Group controlId='isConfirm' className="my-1">
               <Form.Check
               type="checkbox"
               label ="Is Confirm"
               checked = {isConfirm}
               onChange={(e)=> setIsConfirm(e.target.checked)}
               ></Form.Check> 

            </Form.Group>
            
            <Form.Group controlId='brandName' className="my-1">
            <Form.Label>Marka</Form.Label>
            <Form.Control
            type='brandName'
            placeholder="Marka Girin"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            ></Form.Control>

            </Form.Group>

            <Form.Group controlId='categoryName' className="my-1">
            <Form.Label>Katogori</Form.Label>
            <Form.Control
            type='categoryName'
            placeholder="Kategori Girin"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            ></Form.Control>

            </Form.Group>

            <Form.Group controlId='discountName' className="my-1">
            <Form.Label>İndirim</Form.Label>
            <Form.Control
            type='discountName'
            placeholder="Discount Girin"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            ></Form.Control>

            </Form.Group>
            
            <Button
            type='submit'
            variant="primary"
            className="my-1"
            > Update </Button>
    <Button
                  type='button'
                  className='btn-block'

                  onClick={addCategory1}
                >
                
                 Add Category
     </Button>
</Col>
<Col> 
<h1>Edit Discount</h1>
<h5 className="my-1">Siemens</h5>
<Row>

  <Col>
  
<Form.Group controlId='priceShow' className="my-1">
               <Form.Check
               type="checkbox"
               label ="Fiyat Göster"
               checked = {tempUser.discountItems[0].priceShow}
               onChange={(e)=> { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
                if(product.brand==="Siemens") return  {...product,  priceShow : e.target.checked } 
                else{
                 return  (product);
               }
         })]});
         
        
         } }
               
               ></Form.Check> 

            </Form.Group>
            </Col>
            <Col>
            <span>
</span>

            <Form.Group controlId='discountShow' className="my-1">
               <Form.Check
               type="checkbox"
               label ="İndirim Göster"
               checked = {tempUser.discountItems[0].extraDiscountShow}
               onChange={(e)=> { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
                if(product.brand==="Siemens") return  {...product,  extraDiscountShow : e.target.checked } 
                else{
                 return  (product);
               }
         })]});
         
        
         } }

               ></Form.Check> 

            </Form.Group>
            </Col>
        
</Row>
<Row>

<Col>
<h6 className="my-1">Sürücü </h6>
</Col>
<Col>
<h6 className="my-1">PLC </h6>

</Col>
<Col>
<h6 className="my-1">HMI</h6>

</Col>
</Row>


<Row>
  <Col>
<Form.Group controlId='driverDiscount' className="my-1">
            <Form.Control
            type='number'
            placeholder="İskonto"
            value={tempUser.discountItems[0].categories[0].discount}
            onChange={(e) =>{ setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="Siemens") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Driver") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            
            
        



            ></Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='plcDiscount' className="my-1">
            <Form.Control
            type='plc'
            placeholder="İskonto"
            value={tempUser.discountItems[0].categories[1].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="Siemens") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Plc&Module") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
           </Col>

           <Col>
<Form.Group controlId='hnmiDiscount' className="my-1">
            <Form.Control
            type='hmi'
            placeholder="İskonto"
            value={tempUser.discountItems[0].categories[2].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="Siemens") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Hmi") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
          </Col>  
            

</Row>
<Row>
<Row>
<h5 className="my-1">Yaskawa</h5>
</Row>

<Row>

  <Col>
  
<Form.Group controlId='priceShow' className="my-1">
               <Form.Check
               type="checkbox"
               label ="Fiyat Göster"
               checked = {tempUser.discountItems[1].priceShow}
               onChange={(e)=>{ setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
                if(product.brand==="Yaskawa") return  {...product,  priceShow : e.target.checked } 
                else{
                 return  (product);
               }
         })]});
         
        
         } } 

               ></Form.Check> 

            </Form.Group>
            </Col>
            <Col>
            <span>
</span>

            <Form.Group controlId='discountShow' className="my-1">
               <Form.Check
               type="checkbox"
               label ="İndirim Göster"
               checked = {tempUser.discountItems[1].extraDiscountShow}
               onChange={(e)=> { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
                if(product.brand==="Yaskawa") return  {...product,  extraDiscountShow : e.target.checked } 
                else{
                 return  (product);
               }
         })]});
         
        
         } }
               ></Form.Check> 

            </Form.Group>
            </Col>
        
</Row>

<Col>
<h6 className="my-1">Sürücü </h6>
</Col>
<Col>
<h6 className="my-1">PLC </h6>

</Col>
<Col>
<h6 className="my-1">HMI</h6>

</Col>
</Row>
<Row>
  <Col>
<Form.Group controlId='driverDiscount' className="my-1">
            <Form.Control
            type='driver'
            placeholder="İskonto"
            value={tempUser.discountItems[1].categories[0].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="Yaskawa") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Driver") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='plcDiscount' className="my-1">
            <Form.Control
            type='plc'
            placeholder="İskonto"
            value={tempUser.discountItems[1].categories[1].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="Yaskawa") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Plc&Module") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
           </Col>

           <Col>
<Form.Group controlId='hmiDiscount' className="my-1">
            <Form.Control
            type='hmi'
            placeholder="İskonto"
            value={tempUser.discountItems[1].categories[2].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="Yaskawa") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Hmi") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
          </Col>  
            

</Row>


<Row>
<Row>
<h5 className="my-1">C</h5>
</Row>

<Row>

  <Col>
  
<Form.Group controlId='priceShow' className="my-1">
               <Form.Check
               type="checkbox"
               label ="Fiyat Göster"
               checked = {tempUser.discountItems[2].priceShow}
               onChange={(e)=> { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
                if(product.brand==="C") return  {...product,  priceShow : e.target.checked } 
                else{
                 return  (product);
               }
         })]});
         
        
         } }
               ></Form.Check> 

            </Form.Group>
            </Col>
            <Col>
            <span>
</span>

            <Form.Group controlId='discountShow' className="my-1">
               <Form.Check
               type="checkbox"
               label ="İndirim Göster"
               checked = {tempUser.discountItems[2].extraDiscountShow}
               onChange={(e)=>{ setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
                if(product.brand==="C") return  {...product,  extraDiscountShow : e.target.checked } 
                else{
                 return  (product);
               }
         })]});
         
        
         } }
               ></Form.Check> 

            </Form.Group>
            </Col>
        
</Row>

<Col>
<h6 className="my-1">Sürücü </h6>
</Col>
<Col>
<h6 className="my-1">PLC </h6>

</Col>
<Col>
<h6 className="my-1">HMI</h6>

</Col>
</Row>
<Row>
  <Col>
<Form.Group controlId='driverDiscount' className="my-1">
            <Form.Control
            type='driver'
            placeholder="İskonto"
            value={tempUser.discountItems[2].categories[0].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="C") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Driver") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='Yaskawa' className="my-1">
            <Form.Control
            type='plc'
            placeholder="İskonto"
            value={tempUser.discountItems[2].categories[1].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="C") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Plc&Module") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
           </Col>

           <Col>
<Form.Group controlId='Siemens' className="my-1">
            <Form.Control
            type='hmi'
            placeholder="İskonto"
            value={tempUser.discountItems[2].categories[2].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="C") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Hmi") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
          </Col>  
            

</Row>
<Row>
<Row>
<h5 className="my-1">D</h5>
</Row>

<Row>

  <Col>
  
<Form.Group controlId='priceShow' className="my-1">
               <Form.Check
               type="checkbox"
               label ="Fiyat Göster"
               checked = {tempUser.discountItems[3].priceShow}
               onChange={(e)=> { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
                if(product.brand==="D") return  {...product,  priceShow : e.target.checked } 
                else{
                 return  (product);
               }
         })]});
         
        
         } }
               ></Form.Check> 

            </Form.Group>
            </Col>
            <Col>
            <span>
</span>

            <Form.Group controlId='discountShow' className="my-1">
               <Form.Check
               type="checkbox"
               label ="İndirim Göster"
               checked = {tempUser.discountItems[3].extraDiscountShow}
               onChange={(e)=> { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
                if(product.brand==="D") return  {...product,  extraDiscountShow : e.target.checked } 
                else{
                 return  (product);
               }
         })]});
         
        
         } }
               ></Form.Check> 

            </Form.Group>
            </Col>
        
</Row>

<Col>
<h6 className="my-1">Sürücü </h6>
</Col>
<Col>
<h6 className="my-1">PLC </h6>

</Col>
<Col>
<h6 className="my-1">HMI</h6>

</Col>
</Row>
<Row>
  <Col>
<Form.Group controlId='driverDiscount' className="my-1">
            <Form.Control
            type='driver'
            placeholder="İskonto"
            value={tempUser.discountItems[3].categories[0].discount}
            onChange={(e) =>{ setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="D") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Driver") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='plcDiscount' className="my-1">
            <Form.Control
            type='plc'
            placeholder="İskonto"
            value={tempUser.discountItems[3].categories[1].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="D") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Plc&Module") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
           </Col>

           <Col>
<Form.Group controlId='hmiDiscount' className="my-1">
            <Form.Control
            type='hmi'
            placeholder="İskonto"
            value={tempUser.discountItems[3].categories[2].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="D") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Hmi") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
          </Col>  
            

</Row>
<Row>
<Row>
<h5 className="my-1">E</h5>
</Row>

<Row>

  <Col>
  
<Form.Group controlId='priceShow' className="my-1">
               <Form.Check
               type="checkbox"
               label ="Fiyat Göster"
               checked = {tempUser.discountItems[4].priceShow}
               onChange={(e)=> { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
                if(product.brand==="E") return  {...product,  priceShow : e.target.checked } 
                else{
                 return  (product);
               }
         })]});
         
        
         } }
               ></Form.Check> 

            </Form.Group>
            </Col>
            <Col>
            <span>
</span>

            <Form.Group controlId='discountShow' className="my-1">
               <Form.Check
               type="checkbox"
               label ="İndirim Göster"
               checked = {tempUser.discountItems[4].extraDiscountShow}
               onChange={(e)=> { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
                if(product.brand==="E") return  {...product,  extraDiscountShow : e.target.checked } 
                else{
                 return  (product);
               }
         })]});
         
        
         } }
               ></Form.Check> 

            </Form.Group>
            </Col>
        
</Row>

<Col>
<h6 className="my-1">Sürücü </h6>
</Col>
<Col>
<h6 className="my-1">PLC </h6>

</Col>
<Col>
<h6 className="my-1">HMI</h6>

</Col>
</Row>
<Row>
  <Col>
<Form.Group controlId='driverDiscount' className="my-1">
            <Form.Control
            type='driver'
            placeholder="İskonto"
            value={tempUser.discountItems[4].categories[0].discount}
            onChange={(e) =>{ setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="E") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Driver") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='plcDiscount' className="my-1">
            <Form.Control
            type='plc'
            placeholder="İskonto"
            value={tempUser.discountItems[4].categories[1].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="E") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Plc&Module") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
           </Col>

           <Col>
<Form.Group controlId='hmiDiscount' className="my-1">
            <Form.Control
            type='hmi'
            placeholder="İskonto"
            value={tempUser.discountItems[4].categories[2].discount}
            onChange={(e) => { setTempUser({...tempUser,  discountItems:[...tempUser.discountItems.map(product=>{
              if(product.brand==="E") return {...product,  categories:[...product.categories.map(item=> { if (item.category==="Hmi") return {...item, discount: parseFloat( e.target.value) } 
              
              else 
              {
                return (item);}  
              })]
            
            }
            else{
              return  (product);
            }
          }
        )]})}}
            ></Form.Control>
            </Form.Group>
          </Col>  
            

</Row>      

</Col>

</Row>
  
      </Form>
    )}
  </FormContainer>


  <Button
                className='btn-block'
                type='button'
             
                onClick={addCategory1}>
                   bas
            </Button>
    </>
  );
}

export default UserEditScreen