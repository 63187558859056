import { useEffect,useState } from 'react';
import {Card,Row,Col,Button} from 'react-bootstrap';
import { Link,useNavigate,useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import accounting from 'accounting-js'
import { useGetProductDetailsQuery} from '../slices/productsApiSlice';
import { addToCart,removeFromCart } from '../slices/cartSlice';
import '../assets/styles/ImageWithButton.css'
const Product =({product}) => {

    const {userInfo} = useSelector((state)=> state.auth );
    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;
    const {keyword} = useParams();          
    const navigate = useNavigate();


    const dispatch = useDispatch();
    const [qty,setQty] = useState(1);
    const [isHovered, setIsHovered] = useState(false);
   
    
    const{ data: productCart, isLoading, refetch, error} = useGetProductDetailsQuery(product._id);
    const [tempData,setData]=useState(productCart);

 
    useEffect(() =>{

if(product){

   
    if(userInfo){

        const v = userInfo.discountItems.find(x=> x.brand===product.brand);
        const u = v.categories.find(x=>x.category===product.category);     
        
      if(v && !product.advertisementShow)
      {
        
    
         setData({...product, priceShow: v.priceShow,  discount: u.discount, price:(product.price -(product.price*u.discount/100)), discountPrice: (product.price -(product.price*u.discount/100))});
      }
      else
      {
        if(product.advertisementShow)
        {
            setData({...product, price:(product.price -(product.price*product.discount/100)), discountPrice: (product.price -(product.price*product.discount/100)) });

        }

      }
      
      
        }else
        {  
    
            setData({...product, price:(product.price -(product.price*product.discount/100)), discountPrice: (product.price -(product.price*product.discount/100)) });
        
           
        }

   
}},[product])


    const createPost=()=>{

        
        if(tempData){
            dispatch(addToCart({...tempData,qty}));
                  
    }

    }

    const removeCart=()=>{

        
        if(tempData){
            dispatch(removeFromCart(product._id));
                  
    }

    }
   

   

    const onClick = () => {
        navigate(`/product/${product._id}`,
        {
            state: {
                post_id: product._id,
                post_id1: keyword, 
            }
        });


    }
    

    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };


      const addToCartHandler2 = () => {
      }

    return(
        <Card className='my-3 p-3 rounded' onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
           
            {(product) &&(
                
               <span>
                <div className="image-container">
               <Card.Img src={product.image}  className="image" /> 
               {product.countInStock > 0 &&(isHovered && (cartItems.find(x=> x._id===product._id) ? 
               (
                <button className="overlay-button" onClick={() => { removeCart(); 
                }}>Sepetten Çıkar</button>
               )
               :
               (<button className="overlay-button" onClick={() => { createPost(); 
                }}>Sepete Ekle</button>)
                
                ))}
                {product.countInStock > 0 ? (cartItems.find(x=> x._id===product._id)?(<Card.Img src="/uploads\Sepette.jpg"  className="overlay-Image"/>)
                :(
                    <Card.Img src="/uploads\Bos.jpg"  className="overlay-Image" />
                ))
                :
                (
                    <Card.Img src="/uploads\StokYok1.jpg"  className="overlay-Image" />
                )}
                 {
                    product.advertisementShow &&
                    <Card.Img src="/uploads\indirim.jpg"  className="overlay-Advertisement" /> 
                }
                 
               </div>
               </span>
               
)}
                    
                   <Card.Body>
        {(product)&&
            <span >
            <Card.Title as='div'>
                <p onClick={() => {onClick()}}  style={{ cursor: 'pointer', textDecoration: 'underline' }}> {product.name}</p>
            </Card.Title>
            </span>    
        }
     
    <Row> 
                <Col>
                
                {/*Fiyat Kısmı => Liste Fiyatı*/} 
                {(product)&&( product.discountShow === true ?(
                <Card.Text style={{textDecoration: 'line-through'}} as='h5'>
                   {accounting.formatMoney(product.price,{ symbol: "", precision: 2, thousand: ".", decimal: "," })}€
                </Card.Text>
                ):(
                    product.advertisementShow ?
                    (
                        <Card.Text style={{textDecoration: 'line-through'}} as='h5'>
                    {accounting.formatMoney(product.price,{ symbol: "", precision: 2, thousand: ".", decimal: "," })}€
                </Card.Text>

                    )
                    :
                    (
                        <Card.Text  as='h5'>
                        &nbsp;
                </Card.Text>

                    )

                ))}
               
                
            {/*Fiyat Kısmı => indirimli veya Ekstra İndirimli*/} 
              { (product)&&(product.priceShow === true ? ( (product.extraDiscountShow === true && product.discountShow === true && !product.advertisementShow ) || (product.extraDiscountShow === false && product.discountShow === false && !product.advertisementShow )                 // product.advertisementShow === true ? ((userInfo) 
              ?(
              <Card.Text  as='h5'>
                {accounting.formatMoney(product.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })}€
                </Card.Text>
                )
                :
                 (<Card.Text  as='h5'>
                     
                {accounting.formatMoney((product.price -(product.price*product.discount/100)).toFixed(2),{ symbol: "", precision: 2, thousand: ".", decimal: "," })}€
               </Card.Text>)
            )
            
            :(
                product.advertisementShow ?
                (
                    <Card.Text  as='h5'>
                     
                    {accounting.formatMoney((product.price -(product.price*product.discount/100)).toFixed(2),{ symbol: "", precision: 2, thousand: ".", decimal: "," })}€
                   </Card.Text>

                )
                :
                (
                    <Row>
                    <Card.Text  as='h5'>
                    &nbsp;  
                    </Card.Text>
                    </Row>

                )

            ))}
             
                </Col>
              
                <Col>
              {(product)&&( product.discountShow === true && !product.advertisementShow ? ((product.extraDiscountShow=== true )?(<Card.Text style={{textDecoration: 'line-through'}} as='h6'>
                    %{accounting.formatMoney(product.discount,{ symbol: "", precision: 2, thousand: ".", decimal: "," })}
                </Card.Text>)
                :
                (
                    <Card.Text   as='h7'>
                      %{accounting.formatMoney(product.discount,{ symbol: "", precision: 2, thousand: ".", decimal: "," })}
                </Card.Text>
                ))
                :(
                    product.advertisementShow ?
                    (
                        <Card.Text   as='h7'>
                      %{accounting.formatMoney(product.discount,{ symbol: "", precision: 2, thousand: ".", decimal: "," })}
                </Card.Text>

                    )
                    :
                    (
                        <Card.Text  as='h7'>
                        &nbsp;
                  </Card.Text >

                    )

                ))}
                {(product)&&(product.priceShow === true ?(( product.extraDiscountShow=== true )?(
                
                !product.advertisementShow ? 
                (
          
          <Card.Text   style={{color: 'green'}} > size özel <h12>
              %{ accounting.formatMoney(product.extraDiscount,{ symbol: "", precision: 2, thousand: ".", decimal: "," })}
              </h12></Card.Text>
                )
                :
                (
                  <Card.Text  as='h12' style={{color: 'red'}}>
                   &nbsp; Kampanyalı Fiyat
                  </Card.Text>

                )
          )
          :
          (
              product.advertisementShow ? 
              (
                  <Card.Text  as='h12' style={{color: 'red'}}>
                    &nbsp; Kampanyalı Fiyat
                  </Card.Text>

              )
              :
              (
                  <Card.Text  style={{color: 'green'}}><Col> <Row>  <Link to="/register" style={{color: 'green'}}><h12>Üye olun!</h12> </Link></Row> 
                  <Row> <h10>% Özel</h10> </Row></Col>
                  </Card.Text>

              )
 

          ))
          :(
              product.advertisementShow ? 
              (
                  <Card.Text  as='h12' style={{color: 'red'}}>
                    &nbsp; Kampanyalı Fiyat
                  </Card.Text>

              )
              :
              (

          <Card.Text  as='h6'>
              &nbsp;
          </Card.Text>
              )
            
          ))}
                 
                </Col>
                <Row/>

                <Row>
                {(product)&&( product.priceShow === false  && !product.advertisementShow &&(
                <Card.Text  as='h6'>
                    Fiyat Teklifi verilir
                </Card.Text>
                ))}
                </Row>

    </Row>
            
        </Card.Body>
       
        </Card>

    )
}
export default Product