import React from 'react';
import '../assets/styles/documentmodal.css'

const DocumentModal = ({ isOpen, content, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modalOverlay">
      <div className="modalContent">
       
        <div className="documentContent">
          {/* Here, you can customize how you want to display your document content */}
          {content}
        </div>
        <button onClick={onClose}>Onaylıyorum</button>
      </div>
    </div>  
  );
};

export default DocumentModal;