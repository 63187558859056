import React from 'react';
import { Nav } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
export default function CheckoutStatusBar(props) {
 return (
  <div className="checkout-steps">
   <div className={props.userfill ? 'active' : ''} >
   <LinkContainer to='/userfill'>
    <Nav.Link>Müşteri Bilgileri</Nav.Link>
    </LinkContainer>
   </div>
   <div className={props.shipping ? 'active' : ''} >
   <LinkContainer to='/shipping'>
   { props.shipping ? (<Nav.Link>Sevkiyat</Nav.Link>):<>Sevkiyat</>}
    </LinkContainer>
    </div>
   <div className={props.payment ? 'active' : ''} >
   <LinkContainer to='/payment'>
   { props.payment ? (<Nav.Link>Ödeme Şekli</Nav.Link>):<>Ödeme Şekli</>}
                </LinkContainer>
   </div>
   <div className={props.placeOrder ? 'active' :''}>
   <LinkContainer to='/placeorder'>
                  { props.placeOrder ? (<Nav.Link>Sipariş/Teklif</Nav.Link>):<>Sipariş/Teklif</> }
                </LinkContainer>
   </div>
  </div>
 )
}