import {useState,useEffect} from 'react'
import { Link, useNavigate,useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import Message from '../components/Message';
import { addToCart, removeFromCart,saveShippingAddress,clearCartItems } from '../slices/cartSlice';
import { useGetCurrencyQuery } from '../slices/ordersApiSlice';
import accounting from 'accounting-js'


const CartScreen = () => {
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatch1 = useDispatch();
  
 
  const cart = useSelector((state) => state.cart);
  const { cartItems,shippingAddress } = cart;
  const {userInfo}= useSelector((state)=> state.auth);

  const [address] = useState(userInfo?.companyAddress||'');
  const [companyState] = useState(userInfo?.companyState||'');
  const [city] = useState(userInfo?.city||'');
  const [state] = useState(userInfo?.state||'');
  const [companyCity] = useState(userInfo?.companyCity||'');
 // const [companyName, setCompanyName] = useState(userInfo?.companyName||'');
  const [receiver] = useState(userInfo?.companyName||'');

  const [taxNumber] = useState(userInfo?.taxNumber||'');
  const[taxState] = useState(userInfo?.taxState||'');
  const[phoneNumber] = useState(userInfo?.phoneNumber||'');
  const [idNumber] = useState(userInfo?.idNumber||'');
  const [email] = useState(userInfo?.email||'');
  const [name] = useState(userInfo?.name||'');
  const [surname] = useState(userInfo?.surname||'');
  const [companyName] = useState(userInfo?.companyName||'');
  const [companyAddress] = useState(userInfo?.companyAddress||'');
  const [isCompany] = useState(userInfo?.isCompany||'');

const {data:currency} = useGetCurrencyQuery();

const stat1 = useLocation();
 
  useEffect(() =>{
if (userInfo){
 
  dispatch1(saveShippingAddress({address,city,companyState,receiver,state,companyCity,taxNumber,taxState,phoneNumber,idNumber,email,name,surname,companyName,companyAddress,isCompany}));

  }},[cartItems]);
  
  // NOTE: no need for an async function here as we are not awaiting the
  // resolution of a Promise
  const addToCartHandler = (product, qty) => {
    dispatch(addToCart({ ...product, qty }));
  };

  const removeFromCartHandler = (id) => {
    
    dispatch(removeFromCart(id));
    
  };

  const checkoutHandler = () => {
    if(userInfo){
      navigate('/shipping');
    }else{
      navigate('/userfill');
    }
   
  };

  const checkoutHandler1 = () => {
    localStorage.removeItem('cart');
    navigate('/');
  };

  const placeOrderHandler1=() =>{

  
    
 
}

const placeOrderHandler2=() =>{

  navigate('/api/orders/currency');

}
const placeOrderHandler3=() =>{

  navigate('/profile');

}

const addToCartHandler1 = () =>{

  if(cartItems.length === 0 ) {
    navigate('/');
  }else{

    if (state)
    {
  if (stat1.state.post_id1)
  {navigate(`/search/${stat1.state.post_id1}`);}
else{
  navigate('/');
}
}else
{ 
  navigate('/');

}

}


}
const addToCartHandler2 = () =>{
  console.log(stat1.state.post_id);
}


  return (
    

    (currency)&&
    
    <Row>
      <Col md={8}>
      <h1>&nbsp;</h1>  
      <Button
                className= "btn btn-light my-3"
                
                onClick={addToCartHandler1}>
                   Markete Dön
            </Button> 
        <h1 style={{ marginBottom: '20px' }}>Alışveriş Sepeti</h1>
        {cartItems.length === 0 ? ( 
          <Message>
            Sepetiniz Boş 
            &nbsp;&nbsp;
            <Button
                type='button'
                className='btn-block'
                onClick={checkoutHandler1}
              >
                Markete Dön
              </Button>
          </Message>
        ) : (
          <ListGroup variant='flush'>
            {cartItems.map((item) => (
              <ListGroup.Item key={item._id}>
                <Row>
                  <Col md={2}>
                    <Image src={item.image} alt={item.name} fluid rounded />
                  </Col>
                  <Col md={3}>
                    <Link to={`/product/${item._id}`}>{item.name}</Link>
                  </Col>
                  <Col md={2}>{(item.priceShow || item.advertisementShow) ? accounting.formatMoney(item.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) +" €" : 'Teklif İsteği'}</Col>
                  <Col md={2}>
                    <Form.Control
                      as='select'
                      value={item.qty}
                      onChange={(e) =>
                        addToCartHandler(item, Number(e.target.value))
                      }
                    >
                      {[...Array(item.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col md={2}>
                    <Button
                      type='button'
                      variant='light'
                      onClick={() => removeFromCartHandler(item._id)}
                    >
                      <FaTrash />
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>
                Toplam ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                Ürün
              </h2>
              <h5 className='text-center py-0'> {cartItems.find(item=>item.priceShow === false && item.advertisementShow===false)? (<strong>Teklif verilecek</strong>) :(accounting.formatMoney(cartItems.reduce((acc, item) => acc + item.qty * item.discountPrice, 0).toFixed(2),{ symbol: "", precision: 2, thousand: ".", decimal: "," })+" €" )} </h5>
              <h5 className='text-center py-0'><strong> {cartItems.find(item=>item.priceShow === false && item.advertisementShow===false)? (<strong></strong>) :(accounting.formatMoney((cartItems.reduce((acc, item) => acc + item.qty * item.discountPrice, 0)*currency.BanknoteSelling).toFixed(2),{ symbol: "", precision: 2, thousand: ".", decimal: "," })+" ₺" )} </strong></h5>
      
        
              <Row>
              {"Euro Kuru:"+" "+accounting.formatMoney(currency.BanknoteSelling,{ symbol: "", precision: 4, thousand: ".", decimal: "," })+" ₺"}
              </Row>
              </ListGroup.Item>
              <ListGroup.Item>
              { ( userInfo && cart.shippingAddress.name ===' ')  && <Message variant='danger'>{'Profiliniz tamamlanmalı'}</Message>}
                </ListGroup.Item>
            <ListGroup.Item>
              <Button
                type='button'
                className='btn-block'
                disabled={cartItems.length === 0 ||( userInfo && cart.shippingAddress.name ===' ') }
                onClick={checkoutHandler}
                
              > {cartItems.find(item=>item.priceShow === false && item.advertisementShow===false)? ('Teklif İsteği Gönder') : 'Siparişe Devam Et'}
                
              </Button>
            { ( userInfo && cart.shippingAddress.name ===' ') &&
              <Button
                  type='button'
                  className='btn-block'

                  onClick={placeOrderHandler3}
                >
                
                 profil tamamla
                </Button>
              }
              {/*
               <Button
                className='btn-block'
                type='button'
             
                onClick={addToCartHandler2}>
                   bas
            </Button>
* */}
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  );
};

export default CartScreen;
