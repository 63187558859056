import {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate,useParams} from 'react-router-dom';
import {Form,Button,Row,Col} from 'react-bootstrap';
import { useDispatch,useSelector } from 'react-redux';
import FormContainer from "../components/FormContainer";
import { clearCartItems } from '../slices/cartSlice';
import { setDiscount } from '../slices/discountSlice';
import { resetDiscount } from '../slices/discountSlice';
import { useGetProductsQuery } from '../slices/productsApiSlice';  
import {useRegisterMutation,useLoginMutation} from '../slices/usersApiSlice';
import {setCredentials} from '../slices/authSlice';
import Message from '../components/Message';
import countryTR from '../assets/styles/countryTR.js';
import {jwtDecode} from 'jwt-decode';  
 


const RegisterScreen = () => {



  const [name, setName] = useState(' ');
  const [email,setEmail] = useState('');
  const[password,setPassword] = useState('');
  const[confirmPassword,setConfirmPassword] = useState('');
  const [companyCity, setCompanyCity] = useState('1');
  
  const[cities,setCities]=useState(countryTR.find(ctr=>ctr.il==='Seçiniz').ilceleri ||'');
  const [companyState, setCompanyState] = useState('Seçiniz');
  const [companyAddress, setCompanyAddress] = useState(' ');
  const [companyName, setCompanyName] = useState(' ');
  const [surname, setSurname] = useState(' ');
  const [phoneNumber, setPhoneNumber] = useState(' ');
  const [idNumber, setIdNumber] = useState(' ');
  const [taxNumber, setTaxNumber] = useState(' ');
  const [taxState, setTaxState] = useState(' ');
  const [chng,setchng]= useState('');
  
  const [isCompany, setIsCompany] = useState('false');
  const[formErrors,setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [login,{isLoading1,error1}] = useLoginMutation();
const navigate= useNavigate();

const [register,{isLoading,error}] = useRegisterMutation();
const google=window.google;
const [user,setUser]= useState({});
const [trig, setTrig]=useState();
const [trig1, setTrig1]=useState();
const [trig2, setTrig2]=useState();
const[isConfirm,setIsConfirm] = useState(true);

const dispatch = useDispatch();
const dispatch1 = useDispatch();
const {pageNumber,keyword,id:userId} = useParams(); 
const {data} = useGetProductsQuery({keyword,pageNumber});


//const {userInfo} = useSelector((state)=> state.auth );
const {search} = useLocation();
const  sp= new URLSearchParams(search);
const redirect = sp.get('redirect') || '/';

const disItems =  [
    {
      brand: "Siemens",
      extraDiscountShow: true,
      priceShow: true,   
      categories: [
        {
          category: "Driver",
          discount:  66.20,        
        },
        {
          category: "Plc&Module",
          discount:  44.70,
        },
        {
          category: "Hmi",
          discount:  39.60,
        },
        {
          category: "ETM",
          discount:  34.50,
        }
      ]
    },
    {
      brand: "Yaskawa",
      extraDiscountShow: false,
      priceShow: false,
      categories: [
        {
          category: "Driver",
          discount: 20.0,
        },
        {
          category: "Plc&Module",
          discount: 20.0,
        },
        {
          category: "Hmi",
          discount: 20.0,
        }
      ],
    
    },
    {
      brand: "C",
      extraDiscountShow: false,
      priceShow: false,
      categories: [
        {
          category: "Driver",
          discount: 26.0,
        },
        {
          category: "Plc&Module",
          discount: 28.0,
        },
        {
          category: "Hmi",
          discount: 28.0,
        }
      ],
    
    },
    {
      brand: "D",
      extraDiscountShow: false,
      priceShow: false,
      categories: [
        {
          category: "Driver",
          discount: 26.0,
        },
        {
          category: "Plc&Module",
          discount: 28.0,
        },
        {
          category: "Hmi",
          discount: 28.0,
        }
      ],
    
    },
    {
      brand: "E",
      extraDiscountShow: false,
      priceShow: false,
      categories: [
        {
          category: "Driver",
          discount: 26.0,
        },
        {
          category: "Plc&Module",
          discount: 28.0,
        },
        {
          category: "Hmi",
          discount: 28.0,
        }
      ],
    
    },
  ];
     

  let userObject;
const handleCallBackResponse = (response)=>{
    console.log('Encoded JWT ID Token' + response.credential);
     userObject = jwtDecode(response.credential);
    console.log(userObject);
   // setUser(userObject.sub);
    //console.log(user);
    setName(userObject.given_name);
    setSurname(userObject.family_name);
    setEmail(userObject.email);
    setPassword(userObject.sub);
    setIsConfirm(userObject.email_verified);
    setCompanyName(" ");
    setCompanyState(" ");
    setCompanyAddress(" ");
    setCompanyCity(" ");
    setIsCompany("false");
    setTaxNumber(' ');
    setTaxState(' ');
    setPhoneNumber(' ');
    setIdNumber(' ');
    setCompanyState(' ');


    setTrig(true);
    document.getElementById("sign-in-div").hidden=true;
   
}



const submitHandler5 = async () =>{
  
  try{
   
      const res = await register({
          name,
          email,
          password,
          isAdmin:false, 
          isConfirm,
          isDiscountShow:false,
          discountItems:disItems,
          surname,
          companyName,
          companyAddress,
          companyCity,
          companyState,
          phoneNumber,
          idNumber,
          taxNumber,
          taxState,     
          isCompany,
      }).unwrap();
     
      setTrig1(true);
      console.log('selama'); 


  } catch (err){
   //   toast.error(err?.data?.message|| err.error );
  }
};


const submitHandler6 = async () =>{

  try{
        
      dispatch(clearCartItems());
      dispatch(resetDiscount());
      const res = await login({email,password}).unwrap();
   //   if(res)
   //   {
      dispatch(setCredentials({...res}));
      dispatch1(setDiscount({...data}));
      navigate('/');
  //    }
      

  

  } catch (err){
   //   toast.error(err?.data?.message|| err.error );
  }
};



const submitHandler = (e) =>{
  e.preventDefault();
  setFormErrors(validate());
  setIsSubmit(true);
}


const validate = () => {
  const errors ={};
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if(!name)
  {
    errors.name = "name is required";
  }
  if(!surname)
  {
    errors.surname = "surname is required"
  }
  if(!email){
    errors.email = "email is required"
  }
  else if(!regex.test(email)){
    errors.email= "this is not a valid email format";
  }
  if(password !== confirmPassword){
    errors.confirmPassword= "Password tutmuyor";
   }

  if(!phoneNumber){
    errors.phoneNumber = "phoneNumber is required"
  }
  if(!password){
    errors.password = "password is required"
  }
  if(!companyCity){
    errors.companyCity = "companyCity is required"
  }
  if(!companyState){
    errors.companyState = "companyState is required"
  }
  if(!companyAddress){
    errors.companyAddress = "companyAddress is required"
  }
  if(isCompany==="true"){

   // setIdNumber(" ");
    if(!taxState){
      errors.taxState = "taxState is required"
    }
    if(!taxNumber){
      errors.taxNumber = "taxNumber is required"
    }
    if(!companyName){
      errors.companyName = "companyName is required"
    }
  }
  
return errors;
}


useEffect(()=>{
  google.accounts.id.initialize({
      client_id:"531285197919-t8ekl77joa3qahdmfsp6h35l80obccpa.apps.googleusercontent.com",
      callback: handleCallBackResponse
  });
  google.accounts.id.renderButton(document.getElementById('sign-in-div'),{
      theme:'outline',
      size:'large',
  });
  },[]) 
  
  useEffect(() =>{
      if (trig) {
          submitHandler5();
      }
   
    
  },[trig]);//,redirect,navigate]);
  
  
  useEffect(() =>{
      if (trig1) {
        setTrig2(true);
      }
   
    
  },[trig1]);
  
  useEffect(() =>{
      if (trig2) {
        submitHandler6();
        console.log('selam');
      }
   
    
  },[trig2]);


useEffect(() =>{
  if (chng==="1") {
    submitHandler2();
  }
 
},[chng]);


useEffect(() =>{
  
  if(Object.keys(formErrors).length === 0 && isSubmit)
  {
    submitHandler1();
   
  }
  else
  {

  }
},[formErrors]);




const submitHandler1 =  () =>{

  if(isCompany==="true"){

    setIdNumber(" ");
  }else
  {
    if(!idNumber)
    {
      setIdNumber(" ")
    }
    if(!companyName){
      setCompanyName(" ");
    }

    setTaxNumber(" ")
    setTaxState(" ");

  }
  
 setchng("1");
}


const submitHandler2 = async () =>{
 
   try{
       const res = await register({
        name,
        email,
        password,
        isAdmin:false, 
        isConfirm:false,
        isDiscountShow:false,
        discountItems:disItems,
        surname,
        companyName,
        companyAddress,
        companyCity,
        companyState,
        phoneNumber,
        idNumber,
        taxNumber,
        taxState,     
        isCompany,
    }).unwrap();

     //  dispatch(setCredentials({...res}));
   //    navigate(redirect);
       
      
   }catch (err){
      // toast.error(err?.data?.message|| err.error );
   }

//}
};

const changeState=(e)=>{

  setCompanyState(e.target.value);
  setCities(countryTR.find(ctr=>ctr.il===e.target.value).ilceleri);
     
    }


 return (
   <FormContainer>
    <h1>&nbsp;</h1>
        <spa>&nbsp;</spa>
       <h1>Üye Kayıt</h1>
       <Form onSubmit={submitHandler}>
  
<Row>

      <Col>
      <Form.Group controlId='email' className='my-2'>
               <Form.Label> Email Address </Form.Label>
                   <Form.Control
                   type='email'
                   placeholder='Mail Adresinizi giriniz'
                   value={email}
                   onChange={(e)=>setEmail(e.target.value)}
                   >

                   </Form.Control>
               
           </Form.Group>
           <p style={{ color: 'red' }}>{formErrors.email}</p> 
      </Col>
      </Row>

 <Row>
 <Col>

 
           <Form.Group controlId='password' className='my-3'>
               <Form.Label> Şifre </Form.Label>
                   <Form.Control
                   type='password'
                   placeholder='Şifreyi giriniz'
                   value={password}
                   onChange={(e)=>setPassword(e.target.value)}
                   >
                   </Form.Control>
                   <p style={{ color: 'red' }}>{formErrors.password}</p> 
           </Form.Group>
</Col>
<Col>
           <Form.Group controlId='confirmPassword' className='my-3'>
               <Form.Label> Şifre (Tekrar) </Form.Label>
                   <Form.Control
                   type='password'
                   placeholder='Tekrar şifreyi giriniz'
                   value={confirmPassword}
                   onChange={(e)=>setConfirmPassword(e.target.value)}
                   >
                   </Form.Control>
                   <p style={{ color: 'red' }}>{formErrors.confirmPassword}</p> 
           </Form.Group>

           </Col>
  </Row>
 
 


           <Button type='submit' variant='primary' className='mt-2' disabled={isLoading}

           style={{ height: '60px' }}
           >
                       Üye Olun

                   </Button>
                   <span> &nbsp; </span>
                      
                <Button  type='button'
                
                className='mt-2'  id='sign-in-div'>
              
              </Button>
         
                {isLoading && (<Message variant='success'>Lütfen üyeliğinizi aktifleştirmek için e-mailinize gönderilen onay linkini tıklayın. Eğer e-mailinizi alamıyorsanız,spam kutusunu kontrol edin.</Message>)}    
                 {error &&
                  (<Message variant='danger'>{error.data.message}</Message>)}
       </Form>
       <Row className='py-3'>
           <Col>
           Üye misiniz? {''}<Link to={redirect ? `/login?redirect=${redirect}`:'/login'}>Giriş Yapın</Link>
           </Col>

       </Row>

   </FormContainer>
   

 );
};

export default RegisterScreen;