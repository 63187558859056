import React, { useEffect,useState  } from 'react'
import { useNotVerifiedMutation } from "../slices/ordersApiSlice";
import {Row,Col,ListGroup, Button, Card} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Message from '../components/Message';
import { useParams } from "react-router-dom";

const CardVerification1 = () => {
  const { id, resNumber } = useParams();
  const [notVerified] = useNotVerifiedMutation();
  const navigate = useNavigate();

  const [done, setDone] = useState();


  useEffect(() => {
    if(!done)
    {
    write1();
    
  }
   }, [done]);
 
     const write1 = async ()=> {
 
       const updatedOrder = {
         id,
         resNumber,   
     };
    
   try{
    
      const done1= await notVerified(updatedOrder).unwrap();
      setDone(done1);
     
  
       
       
     }catch (error) {
     }
 
   }

   const handleGoBack = () =>{

    if(!done || done.isOrdered===false)
       {
        navigate('/payment');
          
       }
       else{
         navigate(`/order/${id}`);
        
       }
   }
  
   

  const gotoHomeScreen = () => {
    navigate('/'); // This will navigate back by one step in the history stack
  };
  return (
    <Card>
    <ListGroup.Item>
   
   {resNumber==="888"&&
   <Message variant='danger'>Kart Onaylanmadı </Message>
   }

    </ListGroup.Item>
   
     <Row>      <Col xs={12} md={8} lg={4}>
       {resNumber!=="00" &&
 <ListGroup.Item>

  {/* 
 <Button type='button' className = 'btn btn-block'
                   onClick={handleGoBack}>
                     Tekrar Dene  
 </Button>
 </ListGroup.Item>
 }
 </Col>
 <Col xs={8} md={8} lg={8}>
 {resNumber!=="00" &&
 <ListGroup.Item>
 <Button type='button' className = 'btn btn-block'
                   onClick={gotoHomeScreen}>
                     Markete Dön  
 </Button>

*/}
 </ListGroup.Item>
 }    
 </Col>
 </Row>

   
 


 </Card>
  )
}

export default CardVerification1