import {apiSlice} from './apiSlice';
import { ORDERS_URL } from '../constants';
export const ordersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) =>({
        createOrder: builder.mutation({
            query: (order) => ({
                url: ORDERS_URL,
                method: 'POST',
                body: {...order},
            })
        }),
        getOrderDetails: builder.query({
            query: (orderId) => ({
            url: `${ORDERS_URL}/${orderId}`

            }),
            keepUnusedDataFor: 5,
        }),

        getMyOrders: builder.query({
            query: ({pageNumber})=>({
             url: `${ORDERS_URL}/mine`,
                params: {
                    pageNumber,  
                  }   

            }),
            keepUnusedDataFor: 5,
        }),

        
        getCurrency: builder.query({
            query: ()=>({
             url: '/cart' || '/placeholder',

            }),
           
        }),

        veriCard: builder.mutation({
           query: (data)=>({
                url: `${ORDERS_URL}/${data.id}/res/${data.resNumber}`,
                method: 'PUT',
                body: data,
   
               }),
        }),
        verified: builder.mutation({
            query: (data)=>({
                 url: `${ORDERS_URL}/${data.orderId}/verified`,
                 method: 'PUT',
                 body: data,
    
                }),
         }),
         notVerified: builder.mutation({
            query: (data)=>({
                 url: `${ORDERS_URL}/${data.id}/err/${data.resNumber}`,
                 method: 'PUT',
                 body: data,
    
                }),
         }),

        deleteOrder: builder.mutation({
          query: (orderId) => ({
            url: `${ORDERS_URL}/${orderId}`,
            method: 'DELETE',
          })
      }),


        getOrders: builder.query({
            query:({pageNumber})=>({
                url: ORDERS_URL,
                params: {
                    pageNumber,
                    
                  } 

            }),
            keepUnusedDataFor:5,
        }),
        payOrder: builder.mutation({
            query: ({ orderId, details }) => ({
              url: `${ORDERS_URL}/${orderId}/pay`,
              method: 'PUT',
              body: details,
            }),
          }),
        deliverOrder: builder.mutation({
           query: (data)=>({
            url: `${ORDERS_URL}/${data.orderId}/deliver`,
            method: 'PUT',
            body: data,
           }) 
        }),

        orderRequest: builder.mutation({
            query: (orderId)=>({
             url: `${ORDERS_URL}/${orderId}/ordered`,
             method: 'PUT',
            }) 
         }),

         offerSend: builder.mutation({
            query: (data)=>({
             url: `${ORDERS_URL}/${data.orderId}/offered`, 
             method: 'PUT',
             body: data,
            }) 
         }),

         activateSend: builder.mutation({
            query: (orderId)=>({
             url: `${ORDERS_URL}/${orderId}/activate`, 
             method: 'PUT',
            }) 
         }),


    })
}); 

export const{useCreateOrderMutation,
    useGetOrderDetailsQuery,
    useGetMyOrdersQuery,
    useGetOrdersQuery,
    useDeliverOrderMutation,
    usePayOrderMutation,
    useOrderRequestMutation,
    useActivateSendMutation,
    useGetCurrencyQuery,
    useDeleteOrderMutation,
useOfferSendMutation,
useVeriCardMutation,
useVerifiedMutation,
useNotVerifiedMutation,

} = ordersApiSlice;