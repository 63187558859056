import { useEffect,useState } from 'react';
import { useParams,useNavigate,useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Form, Row, Col, Image, ListGroup, Card ,Button} from 'react-bootstrap';
import { useDispatch,useSelector } from "react-redux";

import Loader from '../components/Loader';
import Message from '../components/Message';
import Meta from '../components/Meta';
import { useGetProductDetailsQuery,useCreateReviewMutation } from '../slices/productsApiSlice';
import { addToCart } from '../slices/cartSlice';
import accounting from 'accounting-js'


export const ProductScreen = () => {
  
    const {state} = useLocation();
  //  const value = location.state.value;

    const { id: productId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate(); 

    const [qty,setQty] = useState(1);
    const [rating,setRating] = useState(0);
    const[comment,setComment] = useState('');

    

    const{ data: product, isLoading, refetch, error} = useGetProductDetailsQuery(productId);
    
    const [createReview,{isLoading:loadingProductReview}] = useCreateReviewMutation();

    const {userInfo}= useSelector((state)=> state.auth);
    const [tempData,setData]=useState(product);
    

    useEffect(() =>{

    if (tempData) {
      
      if(userInfo){

        const v = userInfo.discountItems.find(x=> x.brand===product.brand);
        const u = v.categories.find(x=>x.category===product.category);     
        
      if(v&&!product.advertisementShow)
      {
    
        return setData({...tempData, priceShow: v.priceShow,  discount: u.discount, price:(product.price -(product.price*u.discount/100)), discountPrice: (product.price -(product.price*u.discount/100))});
      }
      
      
        }
       
  //  }else{

      if(product&&userInfo&&!product.advertisementShow)
        {

            const v = userInfo.discountItems.find(x=> x.brand===product.brand);
            const u = v.categories.find(x=>x.category===product.category);
            
            
       setData({...product, priceShow: v.priceShow,  extraDiscount: u.discount, price:(product.price -(product.price*u.discount/100)),  discountPrice:(product.price -(product.price*u.discount/100))});
    }else{
        if(product){
       // console.log(product);
       setData({...product, price:(product.price -(product.price*product.discount/100)), discountPrice: (product.price -(product.price*product.discount/100)) });
      // setData({...product, discountPrice:5});
     //  console.log(product);
    }
    }
    }

    else
    {

        if(product&&userInfo&&!product.advertisementShow)
        {

            const v = userInfo.discountItems.find(x=> x.brand===product.brand);
            const u = v.categories.find(x=>x.category===product.category);
            
            
       setData({...product, priceShow: v.priceShow,  extraDiscount: u.discount, price:(product.price -(product.price*u.discount/100)),  discountPrice:(product.price -(product.price*u.discount/100))});
    }else{
        if(product){
       // console.log(product);
       setData({...product, price:(product.price -(product.price*product.discount/100)), discountPrice: (product.price -(product.price*product.discount/100)) });
      // setData({...product, discountPrice:5});
     //  console.log(product);
    }
    }

    }

      },[product]);


    const addToCartHandler = () =>{
        if (userInfo){
            dispatch(addToCart({...tempData,qty}));
        }else{
            dispatch(addToCart({...tempData,qty}));
        }
        
        navigate('/cart',{
            state: {
                post_id: state.post_id,
                post_id1: state.post_id1, 
            }
        });

        console.log(state);

    };

    const submitHandler = async(e) =>{
        e.preventDefault();
       
        try {
            await createReview({
                productId,
                rating,
                comment
            }).unwrap();
            refetch();
            setRating(0);
            setComment('');
         

        } catch (err) {
          
            
        } 
    }
    
    const addToCartHandler1 = () =>{
        if(state){
        if (state.post_id1)
        {navigate(`/search/${state.post_id1}`);}
    else{
        navigate('/');
    }
}else
{   
    navigate('/');

}
   
   // navigate(`/${value}`);

    }

    const addToCartHandler2 = () =>{
    
        console.log(state);
      //  console.log(state);
    
        }
    return(
    <>

   {/*
     <Link className="btn btn-light my-3" to= {state.search}>
        Markete Dön
        </Link>
     */}
     <h1>&nbsp;</h1>  
        <Button
                className= "btn btn-light my-3"
                
                onClick={addToCartHandler1}>
                   Markete Dön
            </Button>  

        {isLoading ? (<Loader/>) : error ? (<Message variant='danger'>{ error?.data?.error || error.error }</Message>) : (
            tempData && 
<>
<Meta title={product.name}/>
<Row>
<Col md={5}>
< Image src={product.image} alt={product.name} fluid />
</Col>
<Col md={4}>
<ListGroup variant="flush">
        <ListGroup.Item>
            <h3>{product.name}</h3>

        </ListGroup.Item>
     
        { tempData.priceShow ? ((userInfo && tempData&&!product.advertisementShow) ? 
            (<ListGroup.Item>
                 Fiyat: {accounting.formatMoney(tempData.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
            </ListGroup.Item>
            )
            :
            (<ListGroup.Item>
                Fiyat: {accounting.formatMoney(product.price-(product.price*product.discount/100).toFixed(2),{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
           </ListGroup.Item>
            ))
            :(
                product.advertisementShow?
                (
                    <ListGroup.Item>
                Fiyat: {accounting.formatMoney(product.price-(product.price*product.discount/100).toFixed(2),{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
           </ListGroup.Item>

                )
                :
                (
                    <ListGroup.Item>
                 Teklif verilecek       
                 </ListGroup.Item>

                )

            )
        }
        <ListGroup.Item> 
        <strong>Açıklama:</strong>  &nbsp;
              {product.description}
    </ListGroup.Item>
    
</ListGroup> 


</Col>
<Col md={3}> 
<Card>
    <ListGroup  variant='Flush'>
        <ListGroup.Item>
            <Row>
                <Col>Fiyat:</Col>
                { tempData.priceShow ?  (userInfo && tempData&&!product.advertisementShow ? 
                (<Col>
                <strong>{accounting.formatMoney(tempData.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €</strong>
                </Col>) 
                :
                (<Col>
                    <strong>{accounting.formatMoney(product.price-(product.price*product.discount/100).toFixed(2),{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €</strong>
                </Col>)
                )
                :
                (
                    product.advertisementShow?
                    (
                        <Col>
                        Fiyat: {accounting.formatMoney(product.price-(product.price*product.discount/100).toFixed(2),{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
                        </Col>
    
                    )
                    :
                    (
                        <Col>
                       Teklif verilecek 
                        </Col>
                    )
                )
            }
            </Row>
        </ListGroup.Item>
        <ListGroup.Item>
            <Row>
                <Col>Durum:</Col>
                <Col>
                {product.countInStock > 0 ?(
                <strong style={{color: 'green'}}>Stok</strong>)
                :(<strong>Stokta Yok</strong>)
                }
                </Col>
            </Row>
        </ListGroup.Item>

        {product.countInStock > 0 && 
        (
      
            <ListGroup.Item>
                <Row>
                    <Col>Miktar</Col>
                    <Col>
                    <Form.Control 
                    as='select'
                    value={qty}
                    onChange={(e)=>setQty(Number(e.target.value))}
                    >
                       {[...Array(product.countInStock).keys()].map((x) =>(
                        <option key={x+1} value={x+1}>
                            {x+1}

                        </option>
                       ))}

                    </Form.Control>
                    </Col>
                </Row>


            </ListGroup.Item>
        )}

        <ListGroup.Item>
            
                
            <Button
                className='btn-block'
                type='button'
                disabled={product.countInStock === 0}
                onClick={addToCartHandler}>
                    Sepete Ekle
            </Button>
                
      {/*     <Button
                className='btn-block'
                type='button'
             
                onClick={addToCartHandler2}>
                   bas
            </Button>
       * */} 
            </ListGroup.Item>
        </ListGroup>


</Card>

</Col>

</Row>

</>
        
) }
  
    </>
    ) ;
};
export default ProductScreen;
