import { Helmet } from "react-helmet-async"
const Meta = ({title,description,keywords}) => {
  return (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />


    </Helmet>
  )
}

Meta.defaultProps = {
    title: 'ADP Markete Hoşgeldiniz',
    description: 'Endüstriyel Otomasyon Ürünleri Satış,Endüstriyel Otomasyon Firma',
    keywords: 'Yaskawa,Vipa,Siemens,Endüstriyel Otomasyon,PLC,HMI,Scada,Sürücü,Servo,Modül,Proses Otomasyon,Yazılım,Donanım,Fabrika Otomasyonu, Makine Otomasyonu,ADP Otomasyon, ADP Endüstriyel Otomasyon, Endüstriyel Otomasyon, Proses Otomasyon, Makina Otomasyon, Fabrika Otomasyon, Mühendislik, Yazılım, PLC, SCADA, HMI,  Servo Motor, Ac Motor, Sürücü, Hareket Kontrol, IOT, Endüstri 4.0, Ar-ge, Satış, Siemens, Yaskawa, Delta, Gıda, Enerji, Kimya, Metal, Geri Dönüşüm, Arıtma, Danışmanlık, Eğitim, Dijital Dönüşüm, Teknoloji, Sanayi, Endüstri, Otomasyon, Üretim, Tesis, Raporlama, Veri, Uygulama, Makina, Makine, Fabrika, Bilgisayar, Mobil, Tübitak, Asenkron Motor'
}

export default Meta