import React, { useEffect,useState  } from 'react'
import { useVeriCardMutation } from "../slices/ordersApiSlice";
import {Row,Col,ListGroup, Button, Card} from 'react-bootstrap';
import { unstable_useViewTransitionState, useNavigate } from 'react-router-dom';
import Message from '../components/Message';
import { useParams } from "react-router-dom";


const CardVerification = () => {

  const { id, resNumber } = useParams();
  const [veriCard] = useVeriCardMutation();
  const navigate =useNavigate();
  const [done, setDone] = useState();
  



  useEffect(() => {
    if(!done)
    {
   write1();
    }
   
  }, [done]);

    const write1 = async ()=> {

      const updatedOrder = {
        id,
        resNumber,   
    };
   
  try{
    
     const done1= await veriCard(updatedOrder).unwrap();
     setDone(done1);
 
      if(resNumber==="00")
      {
        navigate(`/placeorder/${id}`);
       
      }
      
    }catch (error) {
    }

  }

    const gotoPlaceOrder = ()=> {
      if(!done)
      {
       navigate('/payment');
         
      }
      else{
        navigate(`/order/${id}`);
       
      }
    }

    const gotoHomeScreen = ()=> {
      navigate('/');
  }


  return (
   
      <Card>
         <ListGroup.Item>
        
        {resNumber==="05"&&
        <Message variant='danger'>Ödeme Gerçekleşmedi </Message>
        }
        {resNumber==="93"&&
        <Message variant='danger'>Provizyon Alınamadı </Message>
        }
         {resNumber==="54"&&
        <Message variant='danger'>Kullanım tarihi geçmiş </Message>
        }
         {resNumber==="HashDataError"&&
        <Message variant='danger'>Hash Data Eşleşmiyor </Message>
        }
        {resNumber==="14"&&
        <Message variant='danger'>Geçersiz Kart Numarası </Message>
        }
        {resNumber==="00"&&
        <Message variant='success'>Ödeme Alındı </Message>}
        
         

         </ListGroup.Item>
        
          <Row>      <Col xs={12} md={8} lg={4}>
            {resNumber!=="00" &&
      <ListGroup.Item>
    {/*   <Button type='button' className = 'btn btn-block'
                        onClick={gotoPlaceOrder}>
                          Tekrar Dene  
      </Button>
      </ListGroup.Item>
      }
      </Col>
      <Col xs={8} md={8} lg={8}>
      {resNumber!=="00" &&
      <ListGroup.Item>
      <Button type='button' className = 'btn btn-block'
                        onClick={gotoHomeScreen}>
                          Markete Dön  
      </Button>
      */}
      </ListGroup.Item>
      }    
      </Col>
      </Row>

        
      

     
      </Card>
    
    
  )
}

export default CardVerification