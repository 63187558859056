import {Outlet} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import Header from './components/Header';
import Footer from './components/Footer';
import '../src/assets/styles/headercustom.css'




const App = () => {
  return (
    <>
    <Header className="navbar"/>
    <main className='py-3'>
    <Container>
    <Outlet/>
    </Container>  
    </main>
    <Footer/>
    <ToastContainer/>
    
    </>
  );
};

export default App;