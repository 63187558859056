import React, { useState,useEffect,useRef } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
const Calc = () => {
  const xml = useSelector(state=>state.xml)
  const cart =useSelector(state=>state.cart)
  const [data1, setData] = useState('');
  const [isIframeVisible, setIframeVisible] = useState(false);

  useEffect(() =>{
    test();
  },[]);


  
  const test = async() => {
   // console.log(xml.xmlData.xmlData);
   try {
    const response = await fetch('/api/data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/xml'
      },
      body: xml.xmlData.xmlData
    }); 
    const responseData = await response.json();
    setData(responseData);

    
  } catch (error) {
  
  }
  }

 // const test1 = () => {
 //   console.log(cart);
 // }

  return (

    <div>
      <h1>&nbsp;</h1>
       <Row>
    <Col md={8}>
      <Loader />
      <h1>Kart doğrulama bekleniyor!. Lütfen çıkacak butona tıklayınız.</h1>
      
      </Col>
      </Row>
  { data1 && 
     <div dangerouslySetInnerHTML={{ __html: data1}} />   
    }
  </div>
  )
}

export default Calc