import React from 'react'

const ReturnPolicy = () => {
  return (
    <div style={{ marginTop: '100px' }} >
        <h4><strong>İADE ŞARTLARI </strong> </h4>
        <p> <strong> Vazgeçme </strong> </p>
        Almış olduğunuz ürünü ambalajını açmadan, kullanmadan, bozulmasına imkân vermeden teslim tarihinden itibaren (7) gün içinde iade edebilirsiniz. Ürünü iade etmeden önce iade nedeninizi belirterek fatura numaranız ve sipariş numaranızı içeren bir e-posta ile <strong>info@adpotomasyon.com.tr</strong> adresine gönderiniz.
        <p>
        Gönderdiğiniz e-posta alındığında sizinle temasa geçilecek, ürün iade işleminin nasıl yapılacağı bildirilecektir. Ürünle beraber adınıza kesilen fatura da asıl ve kopya sureti dahil geri gönderilmelidir. İade edilen ürün yerine bir başka ürün veya ürünün bedeli kredi kartınıza iade edilecektir.
        </p>
        <p>
        Firmamızdan kaynaklanan nedenlerle yapılacak iadelerin (yanlış ürün gönderimi vb) dışındaki iadelerde taşıma masrafları ALICI tarafından karşılanacaktır.
        </p>

        <p> <strong> Taşınma Sırasında Hasar Görmüş Ürünler </strong> </p>
        Taşıma sırasında zarar görmüş ürünler teslim alınmamalı ve taşıma şirketine hasar ile ilgili bir tutanak tutturulmalıdır. Ürüne zarar vermeyecek şekilde kutuda, ambalajda olabilecek küçük eziklerden dolayı ürün iade edilmemelidir. Fakat tarafınızdan teslim alındıktan sonra taşıma firması görevini yerine getirdiği kabul edilmiş sayılacaktır.
    </div>
  )
}

export default ReturnPolicy