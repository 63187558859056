 import {useEffect, useState} from 'react';
 import {Link, useLocation, useNavigate,useParams} from 'react-router-dom';
 import {Form,Button,Row,Col} from 'react-bootstrap';
 import { useDispatch,useSelector } from 'react-redux';
 import FormContainer from "../components/FormContainer";
 import Loader from '../components/Loader';
 import {useLoginMutation,useConfirmMutation,useUpdateUserMutation,useRegisterMutation} from '../slices/usersApiSlice';
 import {setCredentials} from '../slices/authSlice';
 import { clearCartItems } from '../slices/cartSlice';
 import { resetDiscount } from '../slices/discountSlice';
 import Message from '../components/Message';
 import { useGetProductsQuery } from '../slices/productsApiSlice';   
import { setDiscount } from '../slices/discountSlice';
import {jwtDecode} from 'jwt-decode';



const LoginScreen = () => {
const [email,setEmail] = useState('')
const[password,setPassword] = useState('');
const[isConfirm,setIsConfirm] = useState(true);



const {pageNumber,keyword,id:userId} = useParams(); 
const {data} = useGetProductsQuery({keyword,pageNumber});
const dispatch = useDispatch();
const dispatch1 = useDispatch();
const navigate= useNavigate();

const [login,{isLoading,error}] = useLoginMutation();
const [confirmUser, {isLoading: loadingConfirm}] = useConfirmMutation();
const [updateUser, {isLoading: loadingUpdate}] = useUpdateUserMutation();
const [register,{isLoading1,error1}] = useRegisterMutation();

const {userInfo} = useSelector((state)=> state.auth );
const {search,state} = useLocation();
const  sp= new URLSearchParams(search);
const redirect = sp.get('redirect') || '/';

const google=window.google;
const [user,setUser]= useState({});
const [trig, setTrig]=useState();
const [trig1, setTrig1]=useState();
const [trig2, setTrig2]=useState();


const [companyAddress, setCompanyAddress] = useState(' ');
  const [companyName, setCompanyName] = useState(' ');
  const [surname, setSurname] = useState(' ');
  const [phoneNumber, setPhoneNumber] = useState(' ');
  const [idNumber, setIdNumber] = useState(' ');
  const [taxNumber, setTaxNumber] = useState(' ');
  const [taxState, setTaxState] = useState(' ');

  const [name, setName] = useState(' ');
  const [companyCity, setCompanyCity] = useState('1');
  const [companyState, setCompanyState] = useState('');
  
  const [isCompany, setIsCompany] = useState('false');


  const disItems =  [
    {
      brand: "Siemens",
      extraDiscountShow: true,
      priceShow: true,   
      categories: [
        {
          category: "Driver",
          discount:  66.20,        
        },
        {
          category: "Plc&Module",
          discount:  44.70,
        },
        {
          category: "Hmi",
          discount:  39.60,
        },
        {
          category: "ETM",
          discount:  34.50,
        }
      ]
    },
    {
      brand: "Yaskawa",
      extraDiscountShow: false,
      priceShow: false,
      categories: [
        {
          category: "Driver",
          discount: 20.0,
        },
        {
          category: "Plc&Module",
          discount: 20.0,
        },
        {
          category: "Hmi",
          discount: 20.0,
        }
      ],
    
    },
    {
      brand: "C",
      extraDiscountShow: false,
      priceShow: false,
      categories: [
        {
          category: "Driver",
          discount: 26.0,
        },
        {
          category: "Plc&Module",
          discount: 28.0,
        },
        {
          category: "Hmi",
          discount: 28.0,
        }
      ],
    
    },
    {
      brand: "D",
      extraDiscountShow: false,
      priceShow: false,
      categories: [
        {
          category: "Driver",
          discount: 26.0,
        },
        {
          category: "Plc&Module",
          discount: 28.0,
        },
        {
          category: "Hmi",
          discount: 28.0,
        }
      ],
    
    },
    {
      brand: "E",
      extraDiscountShow: false,
      priceShow: false,
      categories: [
        {
          category: "Driver",
          discount: 26.0,
        },
        {
          category: "Plc&Module",
          discount: 28.0,
        },
        {
          category: "Hmi",
          discount: 28.0,
        }
      ],
    
    },
  ];
     





let userObject;
const handleCallBackResponse = (response)=>{
    console.log('Encoded JWT ID Token' + response.credential);
     userObject = jwtDecode(response.credential);
    console.log(userObject);
   // setUser(userObject.sub);
    //console.log(user);
    setName(userObject.given_name);
    setSurname(userObject.family_name);
    setEmail(userObject.email);
    setPassword(userObject.sub);
    setIsConfirm(userObject.email_verified);
    setCompanyName(" ");
    setCompanyState(" ");
    setCompanyAddress(" ");
    setCompanyCity(" ");
    setIsCompany("false");
    setTaxNumber(' ');
    setTaxState(' ');
    setPhoneNumber(' ');
    setIdNumber(' ');
    setCompanyState(' ');


    setTrig(true);
    document.getElementById("sign-in-div").hidden=true;
   
}


const submitHandler = async (e) =>{
    e.preventDefault();
    try{
      
        dispatch(clearCartItems());
        dispatch(resetDiscount());
        const res = await login({email,password}).unwrap();
        dispatch(setCredentials({...res}));
        dispatch1(setDiscount({...data}));
/*
        if (state){
            navigate(`/order/${state.post_id}`);   
        }
  */  
      //  navigate('/market');
       
    } catch (err){
     //   toast.error(err?.data?.message|| err.error );
    }
};


const submitHandler5 = async () =>{
  
    try{
     
        const res = await register({
            name,
            email,
            password,
            isAdmin:false, 
            isConfirm,
            isDiscountShow:false,
            discountItems:disItems,
            surname,
            companyName,
            companyAddress,
            companyCity,
            companyState,
            phoneNumber,
            idNumber,
            taxNumber,
            taxState,     
            isCompany,
        }).unwrap();
       
        setTrig1(true);
        console.log('selama'); 


    } catch (err){
     //   toast.error(err?.data?.message|| err.error );
    }
};


const submitHandler6 = async () =>{
  
    try{
          
        dispatch(clearCartItems());
        dispatch(resetDiscount());
        const res = await login({email,password}).unwrap();
     //   if(res)
     //   {
        dispatch(setCredentials({...res}));
        dispatch1(setDiscount({...data}));
        
    //    }
        

    

    } catch (err){
     //   toast.error(err?.data?.message|| err.error );
    }
};


const confirmUserHandler = async () => {

   
        try {
          
            await updateUser({userId,isConfirm});
            console.log(userId);
            
        
           } catch (err) {
            
           }

    }


    useEffect(()=>{
        google.accounts.id.initialize({
            client_id:"531285197919-t8ekl77joa3qahdmfsp6h35l80obccpa.apps.googleusercontent.com",
            callback: handleCallBackResponse
        });
        google.accounts.id.renderButton(document.getElementById('sign-in-div'),{
            theme:'outline',
            size:'large',
        });
        },[]) 
        
        useEffect(() =>{
            if (trig) {
                submitHandler5();
            }
         
          
        },[trig]);//,redirect,navigate]);
        
        
        useEffect(() =>{
            if (trig1) {
              setTrig2(true);
            }
         
          
        },[trig1]);
        
        useEffect(() =>{
            if (trig2) {
              submitHandler6();
              console.log('selam');
            }
         
          
        },[trig2]);    


useEffect(() =>{
    if (userInfo && !state  && userId ) {
       // navigate(redirect);
       navigate('/profile');
    }
    else if(userInfo && !state  && !userId){
      navigate('/');
    }
 
  
},[userInfo]);//,redirect,navigate]);


useEffect(() =>{
    if (userId) {
       // navigate(redirect);
       confirmUserHandler();     
      // navigate('/login');
    }
},[userId]);//,redirect,navigate]);


const placeOrderHandler1=() =>{
    
    if(state){
        navigate(`/product/${state.post_id}`,
        {
            state: {
                post_id: state.post_id,
                post_id1: state.post_id1, 
            }
        }
        );

    }else{
        navigate('/');
    }
}  

const placeOrderHandler2=() =>{
    
    console.log(state);
    
}  

 

  return (
    <FormContainer>
         <h1>&nbsp;</h1>
        <spa>&nbsp;</spa>
        <h1>Giriş Yapın</h1>
        <Form onSubmit={submitHandler}>
            <Form.Group controlId='email' className='my-3'>
                <Form.Label> Email Adresi </Form.Label>
                    <Form.Control
                    type='email'
                    placeholder='Email Adresi giriniz'
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    >

                    </Form.Control>
                
            </Form.Group>

            <Form.Group controlId='password' className='my-3'>
                <Form.Label> Şifre </Form.Label>
                    <Form.Control
                    type='password'
                    placeholder='Şifre giriniz'
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    >
                    </Form.Control>
            </Form.Group>
            <Button type='submit' variant='primary' className='mt-2'
            style={{ height: '60px' }}
            disabled={isLoading}>
                 Üye Girişi
                    </Button>
                    <span> &nbsp; </span>
                    
                  
                <Button  type='button'
                
                  className='mt-2'  id='sign-in-div'>
                
                </Button>
                <span> &nbsp; </span>
                <Button
                  type='button'
                  
                  className='mt-2'
                  style={{ height: '60px' }}
                  onClick={placeOrderHandler1}
                >
                 Üye olmadan devam
                </Button>
       {/*        <Button
                  type='button'
                  className='mt-2'
                  onClick={placeOrderHandler2}
                >
                 bas
                </Button>
* */}

                    {isLoading && <Loader/>}
                    {error && 
                  <Message variant='danger'>{error.data.message}</Message>}
        </Form>
        <Row className='py-3'>
            <Col>
            Yeni Üyelik? <Link to={redirect ? `/register?redirect=${redirect}`:'/register'} >Üye olun</Link>
            </Col>

        </Row>

    </FormContainer>
    

  )
}

export default LoginScreen