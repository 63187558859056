import React, { useState,useEffect} from 'react';
import {Row,Col,ListGroup, Image, Button, Card,Form} from 'react-bootstrap';
import { useNavigate,Link,useParams } from 'react-router-dom';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useGetOrderDetailsQuery} from '../slices/ordersApiSlice';
import { saveCreditCard } from '../slices/cartSlice'
import { clearCartItems} from '../slices/cartSlice';
import { useSelector, useDispatch  } from 'react-redux';
import accounting from 'accounting-js'
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css'


const Completed = () => {
    const {id: orderId} =useParams();
    const {data: order,refetch, isLoading, error} = useGetOrderDetailsQuery(orderId);
    


    const {userInfo} = useSelector((state)=>state.auth);
    const [tempOrder,setTempOrder] = useState(order);
    const [orderItems,setOrderItems] = useState();
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const[trig,setTrig] = useState();
  

    




useEffect(() => {
    if (order) {
        setTempOrder(order);
        setOrderItems(order.orderItems);
        dispatch(clearCartItems());
        dispatch(saveCreditCard({}));
        localStorage.removeItem('cart');
    
   
        
    }
    
  }, [order,isLoading]);


  const homeScreen = () => {
    navigate('/');

  }

  return (isLoading ) ? (
  <Loader />
  ) : error ? (
  <Message variant="danger" />
  ) : ((tempOrder) && 
    <>
    <h1>Sipariş No: {tempOrder._id}</h1>
    <Row>
        <Col md={8}>
            <ListGroup variant='flush'>
                <ListGroup.Item>
           
            <h2>Sevkiyat</h2>
            <Row>
                <Col>
                <p>
                <strong>İsim:</strong> {tempOrder.shippingAddress.receiver}
            </p>
            <p>
            <strong>Sevk Adresi:</strong> 
                {tempOrder.shippingAddress.address}, {tempOrder.shippingAddress.city}{''}
                ,{tempOrder.shippingAddress.state}
               
            </p>
            </Col>
            <Col>
            <p>
                <strong>Email:</strong> {tempOrder.shippingAddress.email}
            </p>
            <strong>Kargo:</strong>
                {' '}{(tempOrder.shippingAddress.cargoSelection==='Yurtiçi Kargo')?(<img src='/images/yurtici.jpg' />):(tempOrder.shippingAddress.cargoSelection==='Aras Kargo' ?(<img src='/images/aras.jpg' />):(<img src='/images/mng.jpg' />))}{' '}{'Alıcı Ödemeli'}
    
            </Col>
            </Row>
                
           
            </ListGroup.Item>

            <ListGroup.Item>
                <h2>Ödeme Şekli</h2>
                <Row>
                    <Col>
                    <p>
                    <strong>Tercih:</strong>
                    {tempOrder.paymentMethod==="Banka Transferi" ? ('Banka Transferi'):('Kredi Kartı')}
                    </p>
                    </Col>
                    {tempOrder.paymentMethod==="Banka Transferi" ? (
                    <Col className='text-center py-0'>
                    <p className='text-start m-0'><strong>ADP ENDÜSTRİYEL OTOMASYON SİSTEMLERİ SAN. VE TİC. LTD. ŞTİ.</strong></p>
                    <p className='text-start m-0'>Kuveyt Türk Katılım Bankası-Fikirtepe Şubesi</p>
                    <p className='text-start m-0'>TRY IBAN: TR82 0020 5000 0977 1989 0000 02</p>
                    </Col>)
                     :( tempOrder.isPaid &&
                    <Col className='text-center py-0'> 
                     <Cards
                      cvc={''}
                      expiry={''}
                      //focused={cvv}
                      name= {''}
                      number ={tempOrder.paymentResult.id}
                    />
                    </Col>
                     )}

                </Row>
               
            </ListGroup.Item>
            <ListGroup.Item>
                <h2>Sipariş Listesi</h2>
                { tempOrder.orderItems.map((item,index) =>(
                    <ListGroup.Item key={index}>
                        <Row>
                            <Col md={1}>
                                <Image src={item.image} alt={item.name} fluid rounded />
                            </Col>
                            <Col>
                            <Link to={`/product/${item.product}`}>
                                {item.name}
                            </Link>
                            </Col>
                            {((tempOrder.isOffered=== true && item.priceShow === false) || trig === true || item.priceShow === true )?
                            <Col md={4}>
                     

                            { (trig=== true & !tempOrder.isOffered) && (<input disabled={item.priceShow  === true} name={item._id} type="number" onChange={(e)=> { setTempOrder({...tempOrder,  orderItems:[...tempOrder.orderItems.map(product=>{
        if(product===item && product.priceShow===false) return  {...product,  price : parseFloat(e.target.value,2) } 
        else{
         return  (product);
       }
 })]});
 
 } }/>) }
                    
                            {item.qty} x {accounting.formatMoney(item.price,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} € = {accounting.formatMoney(item.qty * item.price,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €

                           
                            </Col>
                             :
                             <Col md={8}>
                                Teklif Bekleniyor
                            </Col>
                             }
                             
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup.Item>
            </ListGroup>
        </Col>
        <Col md={4}>
        <Card>
            <ListGroup variant='flush'>
                <ListGroup.Item>
                    <h2>Sipariş Özeti</h2>
                </ListGroup.Item>
                <ListGroup>
                <Row>
                    <Col>Ürünler:</Col>
                    {(order.isOffered=== true || trig === true)?
                    <Col>{accounting.formatMoney(tempOrder.itemsPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €    
                    </Col>              
                    :
                    <Col></Col>
                    }
                </Row>     
             <Row>
                <Col>K.D.V(%20):</Col>
                {(order.isOffered=== true || trig === true)?
                <Col>{accounting.formatMoney(tempOrder.taxPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €</Col>
                :
                <Col></Col>
                }
                </Row> 

                <Row>
                <Col>Toplam Tutar:</Col>
                {(order.isOffered=== true || trig === true)?
                <Col>
                
                {accounting.formatMoney(tempOrder.totalPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
               
                <h5> <p className='text-start py-2'>
                {accounting.formatMoney(tempOrder.totalPriceTl,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} ₺
                </p></h5>    
                </Col>
                :
                    <Col> Teklif Bekleniyor</Col>
                }
                </Row>  
                 {"Euro Kuru:"+" "+accounting.formatMoney(tempOrder.currencyEur,{ symbol: "", precision: 4, thousand: ".", decimal: "," })+" ₺"}
            </ListGroup>
            <ListGroup.Item>
                {error && (
                  <Message variant='danger'>{error.data.message}</Message>
                )}

                {!error && (
                  <Message variant='success'>{ tempOrder.isPaid ?('Ödemeniz Alındı. Siparişinizin durumunu gelen emailinizden takip edebilirsiniz.'):( !tempOrder.isOffered ? ('Teklif İsteği Gönderildi. email adresine en kısa zamanda fiyat teklifi iletilecektir.'):('Sipariş isteği başarı ile alındı. Emaile gelen siparişinizi başlatın'))}</Message>
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                {/*               <Button
                  type='button'
                  className='btn-block'
                  onClick={homeScreen}
                >
                
                  Markete Dön
                </Button>
                */}

                </ListGroup.Item>
            </ListGroup>
        </Card>
        
        </Col>

    </Row>
   
    </>
  ) ;
}

export default Completed