import {useState,useEffect} from 'react'
import{Table,Form,Button,Row,Col} from 'react-bootstrap'
import { useParams,useNavigate } from "react-router-dom";
import {useDispatch,useSelector} from 'react-redux'

import Message from '../components/Message'
import Loader from '../components/Loader'
import {useProfileMutation} from '../slices/usersApiSlice'
import {setCredentials} from '../slices/authSlice'
import { saveShippingAddress } from '../slices/cartSlice'
import { useGetMyOrdersQuery } from '../slices/ordersApiSlice'
import countryTR from '../assets/styles/countryTR.js'

const ProfileScreen = () => {

  const cart = useSelector((state) => state.cart);
  const {shippingAddress } = cart;

  const dispatch = useDispatch();
  const {userInfo} = useSelector((state)=> state.auth);
  const [updateProfile, {isLoading: loadingUpdateProfile}] = useProfileMutation();
  const {pageNumber} = useParams();
  const {data, isLoading, error } = useGetMyOrdersQuery({pageNumber});
  const navigate= useNavigate();
 
  const [name,setName] = useState("");
  const [email,setEmail] = useState(userInfo?.email ||'');
  const [password,setPassword] = useState(userInfo?.password ||'');
  const [confirmPassword,setConfirmPassword] = useState(userInfo.password);
  const [surname,setSurname] = useState("");
  const [ phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyCity, setCompanyCity] = useState(userInfo?.companyCity ||'');
  const [companyState, setCompanyState] = useState(userInfo?.companyState ||'');
  const [isCompany, setIsCompany] = useState('');
  const [idNumber, setIdNumber] = useState('' );
  const [taxNumber, setTaxNumber] = useState('' );
  const [taxState, setTaxState] = useState('');
  const [receiver, setReceiver] = useState(userInfo?.companyName ||'');
  const [city, setCity] = useState(userInfo?.companyCity ||'');
  const [showPass, setShowPass] = useState(false);
  
  //const[cities,setCities]=useState(countryTR.find(ctr=>ctr.il==='Seçiniz').ilceleri ||'');
  const[cities,setCities]=useState(countryTR.find(ctr=>ctr.il===`${shippingAddress.companyState? shippingAddress.companyState :'Seçiniz'}`).ilceleri);
  const [state, setState] = useState('' );
 // const [address,setAddress] = useState('');

  const [companyAddress, setCompanyAddress] = useState('' );
  //const [receiver, setReceiver] = useState('' );
 
  const[formErrors,setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [chng,setchng]= useState('');


  
  useEffect(()=>{
    if(userInfo){
      setName(userInfo.name);
      setEmail(userInfo.email);
      setSurname(userInfo.surname);
      setCompanyName(userInfo.companyName);
      setCompanyCity(userInfo.companyCity);
      setCompanyState(userInfo.companyState);
      setIsCompany(userInfo.isCompany);
      setTaxNumber(userInfo.taxNumber);
      setTaxState(userInfo.taxState);
      setPhoneNumber(userInfo.phoneNumber);
      setIdNumber(userInfo.idNumber);
      setCompanyAddress(userInfo.companyAddress);
      setState(userInfo.state);
      setCity(userInfo.companyCity);
      setReceiver(userInfo.companyName);
      setPassword(userInfo.password);
   
    }
  },[userInfo,userInfo.name,userInfo.email,userInfo.surname,userInfo.companyName,userInfo.password,
  userInfo.companyCity,userInfo.companyState,userInfo.isCompany,userInfo.taxNumber,userInfo.taxState,userInfo.phoneNumber,userInfo.idNumber, userInfo.companyAddress,userInfo.receiver,
  ]);


  const submitHandler = (e) =>{
    e.preventDefault();
    setFormErrors(validate());
    setIsSubmit(true);
  }
  

  const validate = () => {
    const errors ={};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if(!name || name===" ")
    {
      errors.name = "Adınızı giriniz";
    }
    if(!surname || surname===" ")
    {
      errors.surname = "Soyadını giriniz"
    }
    if(!email){
      errors.email = "Email Adresini giriniz"
    }
    else if(!regex.test(email)){
      errors.email= "Geçersiz Format";
    }
    if(password && password !== confirmPassword){
      errors.confirmPassword= "Şifre tutmuyor";
     }
  
    if(!phoneNumber || phoneNumber===" "){
      errors.phoneNumber = "telefon numarası giriniz"
    }

    if(!companyCity || companyCity===" "){
      errors.companyCity = "ilçeyi giriniz"
    }
    if(companyState==="Seçiniz" ){
      errors.companyState = "Şehri giriniz"
    }
    if(!companyAddress || companyAddress===" "){
      errors.companyAddress = "Adresi giriniz"
    }
    if(isCompany==="true"){
  
      if(!taxState || taxState===" "){
        errors.taxState = "Vergi Dairesini giriniz"
      }
      if(!taxNumber || taxNumber===" "){
        errors.taxNumber = "Vergi Numarasını giriniz"
      }
      if(!companyName || companyName===" "){
        errors.companyName = "Firma Adını giriniz"
      }
    }
    
  return errors;
  }
  
  
  useEffect(() =>{
    if (chng==="1") {
      submitHandler2();
    }
   
  },[chng]);
  
  
  useEffect(() =>{
    
    if(Object.keys(formErrors).length === 0 && isSubmit)
    {
      submitHandler1();
    }
    else
    {
    }
  },[formErrors]);
  
  
  
  
  const submitHandler1 =  () =>{
  
    if(isCompany==="true"){
  
      setIdNumber(" ");
    }else
    {
      if(!idNumber)
      {
        setIdNumber(" ")
      }
      if(!companyName){
        setCompanyName(" ");
      }
  
      setTaxNumber(" ")
      setTaxState(" ");
  
    }
    
   setchng("1");
  }



  const submitHandler2 =  async () => {
    //e.preventDefault();
    if(password!== confirmPassword){
     
    }else{
        try {
          setchng("");
          const res = await updateProfile({_id: userInfo._id, name, email, password,surname,companyName,companyAddress,companyState,companyCity,idNumber,phoneNumber,isCompany,taxNumber,taxState,state,city}).unwrap();
          dispatch(setCredentials(res));
          setReceiver(userInfo.companyName);
          dispatch(saveShippingAddress({state,city,receiver,companyState,companyCity}));
          navigate('/');
        } catch (err) {
          
        }

      }
  }
  const changeState=(e)=>{
    e.preventDefault();
    
    setCompanyState(e.target.value);
    setState(e.target.value);
    setCities(countryTR.find(ctr=>ctr.il===e.target.value).ilceleri);
    setCity(countryTR.find(ctr=>ctr.il===e.target.value).ilceleri[0]);
    setCompanyCity(userInfo?.companyCity || countryTR.find(ctr=>ctr.il===e.target.value).ilceleri[0]);

      }
      const placeOrderHandler = ()=> {
        console.log(data);
      }
  return (
    <Row>
      <Col>
    <Col md={8}>
    <h1>&nbsp;</h1>
    <h1>&nbsp;</h1>
    <h4>Kullanıcı Bilgileri</h4>
    
    <Form onSubmit={submitHandler}>
    
      
        <Col>
          <Form.Group controlId='name' className='my-10'>
          <Form.Label>Adı</Form.Label>
          <Form.Control
            type='name'
            placeholder='Adınızı giriniz'
            value={name}
            onChange={(e)=>  {
            setName(e.target.value)
              if(isCompany==='false')
              {
                setCompanyName(`${e.target.value} ${surname}`);
              }
         
              }}
            ></Form.Control>
            <p style={{ color: 'red' }}>{formErrors.name}</p> 
            </Form.Group>
      
        </Col>
      <Col>

      <Form.Group controlId='surname' className='my-1'>
        <Form.Label>Soyadı</Form.Label>
        <Form.Control
        type='surname'
        placeholder='Soyadınızı giriniz'
        value={surname}
        onChange={(e)=>{
          setSurname(e.target.value)
          if(isCompany==='false')
          {
            setCompanyName(`${name} ${e.target.value}`)
         //   setReceiver(`${name} ${e.target.value}`)
          }
          
          }}></Form.Control>
           <p style={{ color: 'red' }}>{formErrors.surname}</p> 
      </Form.Group>
      </Col>
      
       <Row>
       <Col>
       <Form.Group controlId='email' className='my-1'>
        <Form.Label>Email</Form.Label>
        <Form.Control
        type='email'
        placeholder='Enter email'
        value={email}
        onChange={(e)=> setEmail(e.target.value)}
        ></Form.Control>
      </Form.Group>
      <p style={{ color: 'red' }}>{formErrors.email}</p> 
      </Col>
      <Col>
      <Form.Group controlId='phonenumber' className='my-1'>
        <Form.Label>Cep Telefonu </Form.Label>
        <Form.Control
        type='text'
        placeholder='Telefon Numarası giriniz'
        value= {phoneNumber}
        onChange={(e)=> setPhoneNumber(e.target.value)}>

        </Form.Control>
        </Form.Group>
        <p style={{ color: 'red' }}>{formErrors.phoneNumber}</p> 
      </Col>
      </Row>
   {(showPass) &&
  <Row>
    <Col>
    
      <Form.Group controlId='password' className='my-2'>
        <Form.Label>Şifre</Form.Label>
        <Form.Control
        type='password'
        placeholder='Şifre değiştir'
        value={password}
        onChange={(e)=> setPassword(e.target.value)}
        ></Form.Control>
      </Form.Group>
      <p style={{ color: 'red' }}>{formErrors.password}</p> 
      </Col>
      <Col>    
      <Form.Group controlId='confirmPassword' className='my-2'>
        <Form.Label>Şifre Onay</Form.Label>
        <Form.Control
        type='password'
        placeholder='Tekrar şifreyi giriniz'
        value={confirmPassword}
        onChange={(e)=> setConfirmPassword(e.target.value)}
        ></Form.Control>
      </Form.Group> 
      <p style={{ color: 'red' }}>{formErrors.confirmPassword}</p>
      </Col>
  </Row>
  
        }
      <Row>
                {Object.keys(formErrors).length !== 0 || name === " " ?(
                  <Message variant='danger'>{'Profiliniz eksik Lütfen tamamlayınız!'}</Message>
                )

                : ((name !==" " && surname !== " "&& phoneNumber !== " " && companyAddress !== " " ) && 
                  <Message variant='success'>{'Profiliniz tamamlandı'}</Message>
                )}
    </Row>

<Row>
  <Col>
    <Button type='submit' variant='primary' className='my-2'>
      Güncelle
    </Button > 
    </Col>
    <Col>
    <Form.Group controlId='isConfirm' className="my-1">
               <Form.Check
               type="checkbox"
               label ="Şifre Değiştir"
               checked = {showPass}
               onChange={(e)=> setShowPass(e.target.checked)}
               ></Form.Check> 
      </Form.Group>
      </Col>
      </Row>
    {loadingUpdateProfile && <Loader/>}
    </Form>
    </Col>
    
    </Col>
    <Col>
    
    <Col md={8}>  
    <h1>&nbsp;</h1>
    <h1>&nbsp;</h1>   
     <h4>Fatura Bilgileri</h4>
     <Row>
      <Col>  
      <input
                    type='radio'
                    className='my-1'
                    label='Kurumsal'
                    id='isCompany'
                    name='isCompany'
                    value= 'true'
                    checked = {isCompany === 'true'}
                    onChange={(e)=> setIsCompany(e.target.value)}
                    ></input> 
      
                     <label htmlFor="Kurumsal">&nbsp;Kurumsal</label>
                      
      </Col>
      <Col>
      <input
                    type='radio'
                    className='my-1'
                    label='Bireysel'
                    id='isCompany'
                    name='isCompany'
                    value= 'false'
                    checked = {isCompany === 'false'}
                    onChange={(e)=> {
                      setIsCompany(e.target.value)
                      setCompanyName(`${name} ${surname}`)
                    }}
                    ></input>   
                    <label htmlFor="Bireysel">&nbsp;Bireysel</label>
      </Col>
     </Row>

     <Form.Group controlId='companyName' className='my-2'>
        <Form.Label>Ünvan /Adı Soyadı </Form.Label>
        <Form.Control
        type='text'
        placeholder='Ünvan/Ad Soyad giriniz'
        value= {companyName}
        onChange={(e)=>  {
          setCompanyName(e.target.value)
          setCity(e.target.value)
          setReceiver(e.target.value)
          }}>

        </Form.Control>
        
        </Form.Group>
        <p style={{ color: 'red' }}>{formErrors.companyName}</p>

        {(isCompany === 'true') ?
        (
    <Row>
     <Col>
     <Form.Group controlId='taxNumber' className='my-2'>
        <Form.Label>Vergi Numarası </Form.Label>
        <Form.Control
        type='text'
        placeholder='Vergi Numarası giriniz'
        value= {taxNumber}
        onChange={(e)=> setTaxNumber(e.target.value)}>

        </Form.Control>
        </Form.Group> 
        <p style={{ color: 'red' }}>{formErrors.taxNumber}</p> 
      </Col>
      <Col>
      <Form.Group controlId='taxState' className='my-2'>
        <Form.Label>Vergi Dairesi </Form.Label>
        <Form.Control
        type='text'
        placeholder='Vergi Dairesi giriniz'
        value= {taxState}
        onChange={(e)=> setTaxState(e.target.value)}>
        </Form.Control> 
        </Form.Group>  
        <p style={{ color: 'red' }}>{formErrors.taxNumber}</p>
      </Col>

     </Row>
        )
        :
        (
          <Row>
          <Form.Group controlId='idNumber' className='my-2'>
             <Form.Label>T.C. Kimlik Numarası </Form.Label>
             <Form.Control
             type='text'
             placeholder='T.C. Kimlik Numaranızı giriniz'
             value= {idNumber}
             onChange={(e)=> setIdNumber(e.target.value)}>
             </Form.Control>
             </Form.Group>  
     
          </Row>

        )}
     
     <Row>

      <Col>
      <Form.Group controlId='companyState' className='my-2'>
        <Form.Label> İl </Form.Label>
 
       <select className="form-control" value= {companyState}  onChange={changeState} >
       
       
       {countryTR.map(ctr=>(
        <option value={ctr.il}>{ctr.il}</option>
       ))}
       </select>
      </Form.Group> 
      <p style={{ color: 'red' }}>{formErrors.companyState}</p>
      </Col>

      <Col>
      <Form.Group controlId='companyCity' className='my-2'>
        <Form.Label> İlçe </Form.Label>
      
     
        <select className="form-control" value= {companyCity}  onChange={(e)=> {
          setCompanyCity(e.target.value)
          setCity(e.target.value)
          }} >
        
       {cities.map(ctr=>(
        <option value={ctr}>{ctr}</option>
       ))}
       </select>

      </Form.Group>
      <p style={{ color: 'red' }}>{formErrors.companyCity}</p>
      </Col>
     </Row>

     <Row>
     <Form.Group controlId='companyAddress' className='my-2'>
        <Form.Label>Fatura Adresi </Form.Label>
        <Form.Control
       as="textarea" rows={3}
        placeholder='Fatura Adresini giriniz'
        value= {companyAddress}
        onChange={(e)=> {
          setCompanyAddress(e.target.value)
        }}>
        </Form.Control>
        <p style={{ color: 'red' }}>{formErrors.companyAddress}</p>
        </Form.Group> 
         
     </Row>
     </Col>

    </Col>
  </Row>
  )
}

export default ProfileScreen