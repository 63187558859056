import { LinkContainer } from "react-router-bootstrap";
import {Table,Button} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import {FaTimes} from 'react-icons/fa';
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useGetOrdersQuery,useDeleteOrderMutation } from "../../slices/ordersApiSlice";
import Paginate from "../../components/Paginate";   


const OrderListScreen = ()=>{
    const {pageNumber} = useParams();
    const {data, isLoading, error, refetch} = useGetOrdersQuery({pageNumber});
    const [deleteOrder, {isLoading:loadingDelete}] = useDeleteOrderMutation();
    //console.log(data.orders);

    const placeOrderHandler = ()=> {
        console.log(data);
      }

      const placeOrderHandler3 = ()=> {
        console.log(data);
      } 

      const deleteHandler = async (id) => {
        if(window.confirm('Are you sure?')){
          try {
            await deleteOrder(id);
           
            refetch();
          } catch (err) {
           
          }
    
        }
    
      };

    return (
    <>
    <h1>Orders</h1>
    {isLoading ? (
        <Loader/>
    ): error ? (
        <Message variant='danger'>{error}</Message>
    ) :(
<>
    <Table striped bordered hover responsive className="table-sm">
        <thead>
            <tr>
                <th>ID</th>
                <th>USER</th>
                <th>DATE</th>
                <th>TOTAL</th>
                <th>TEKLİF</th>
                <th>SİPARİŞ</th>
                <th>PAID</th>
                <th>DELIVERED</th>
                <th>TAKİP NO</th>
            </tr>
        </thead>
        <tbody>
            {data.orders.map((order)=> ( 
                <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>{order.user && order.user.name}</td>
                    <td>{order.createdAt.substring(0,10)}</td>
                    <td>{order.totalPrice}</td>
                    <td>{order.isOffered?(
                        order.offeredAt.substring(0,10)
                        
                    ):(
                       <FaTimes style={{color: 'red'}}/> 
                    )}</td>
                    <td>{order.isOrdered?(
                        order.orderedAt.substring(0,10)
                        
                    ):(
                       <FaTimes style={{color: 'red'}}/> 
                    )}</td>
                    <td>{order.isPaid?(
                        order.paidAt.substring(0,10)
                        
                    ):(
                       <FaTimes style={{color: 'red'}}/> 
                    )}</td>
                    <td>{order.isDelivered?(
                        order.deliveredAt.substring(0,10)   
                        
                    ):(
                       <FaTimes style={{color: 'red'}}/> 
                    )}
                    </td>
                    <td>
                  {order.isDelivered &&
                    order.deliverNumber
                  }
                </td>
                    
                    <td>
                        <LinkContainer to={`/order/${order._id}`}>
                            <Button variant='light' className = 'btn-sm'>
                                İncele
                            </Button>
                        </LinkContainer>
                        
                            <Button variant='light' className = 'btn-sm' onClick={()=> deleteHandler(order._id)}>
                                İptal
                            </Button>
                       
                    </td>
                </tr>
            ))}
        </tbody>
        <Button
                  type='button'
                  className='btn-block'

                  onClick={placeOrderHandler}
                >
                
                 bas
                </Button>
    </Table>

    <Paginate pages={data.pages} page={data.page} isAdmin={true} isProduuctList={false} isUserList={false} isOrderList={true}/>
    </>
    
    )}
  

    </>
    
    );
};
export default OrderListScreen;