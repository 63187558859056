import{Badge,Navbar,Nav,Container, NavDropdown} from 'react-bootstrap';
import{FaShoppingCart,FaUser} from 'react-icons/fa';
import{ LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch} from  'react-redux';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';
import { clearCartItems } from '../slices/cartSlice';
import { resetDiscount } from '../slices/discountSlice';
import { useNavigate,useParams } from 'react-router-dom';
import SearchBox from './SearchBox';
import { useState } from 'react';



const Header = () => {
 const {cartItems} = useSelector((state)=> state.cart);
 const {userInfo} = useSelector((state)=>state.auth);
 
 const[sorting,setSorting]=useState();
 const[navSearch,setNavSearch] = useState();

 const dispatch = useDispatch();
 const navigate = useNavigate();
const [logoutApiCall] = useLogoutMutation();


const {keyword} = useParams();

 const logoutHandler = async() =>{
 try {
  await logoutApiCall().unwrap();
  dispatch(logout());
  dispatch(clearCartItems());
  dispatch(resetDiscount());
  navigate('/login');
  
 } catch (err) {
  console.log(err);
  
 }
 };


 const submitHandler00= () => { 
  navigate("/search/Siemens AC Sürücü ve Aksesuarları");

}

const submitHandler01= () => { 
  navigate("/search/Siemens Servo Sürücü ve Aksesuarları");
}
const submitHandler02= () => { 
  navigate("/search/Siemens S7-1200 PLC ve Aksesuarları");

}

const submitHandler03= () => { 
  navigate("/search/Siemens S7-1500 PLC ve Aksesuarları");

}

const submitHandler04= () => {
  navigate("/search/Siemens HMI");

}
const submitHandler05= () => {
  navigate("/search/ET200 Modül ve Aksesuarları");

}
const submitHandler06= () => {
  navigate("/search/Siemens Güç Kaynağı");

}


const submitHandler10= () => {
  navigate("/search/Yaskawa AC Sürücü ve Aksesuarları");

}
const submitHandler20= () => { 
  navigate("/search/Yaskawa Servo Sürücü ve Aksesuarları");

}

const submitHandler30= () => { 
  navigate("/search/Yaskawa Micro PLC ve Aksesuarları");

}

const submitHandler40= () => { 
  navigate("/search/Yaskawa Slio PLC ve Aksesuarları");

}

const submitHandler50= () => { 
  navigate("/search/Yaskawa 300S PLC ve Aksesuarları");

}

const submitHandler60= () => { 
  navigate("/search/Yaskawa HMI");

}
const submitHandler70= () => { 
  navigate("/search/Fırsat Ürünler");

}

const OnClick= () => {
  navigate('/cart',
        {
            state: {
                post_id: keyword,
                post_id1: keyword, 
            }
       });
}


  return (
    <header>
    <Navbar bg="black" variant="dark" expand="lg"  collapseOnSelect  >
        <Container >
          <LinkContainer to={"/"}>
            <Navbar.Brand >
            <img src='/images/Un1.jpg' width={147} height={68} />
            </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='me-auto'>
          
                <SearchBox/>

                <NavDropdown title="Ürünler"  menuVariant="dark" className="dropdown">
                <NavDropdown  title="Siemens"   menuVariant="dark" className="dropend">
                <NavDropdown.Item >
                  Sürücü ve Aksesuarları
                 <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler00}>
                 AC Sürücü ve Aksesuarları
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler01}>
                 Servo Sürücü ve Aksesuarları
                </NavDropdown.Item>
                </NavDropdown.Item>
                <NavDropdown.Item >
                  PLC Modül ve Aksesuarları
                 <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler02}>
                  S7-1200 PLC ve Aksesuarları
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler03}>
                  S7-1500 PLC ve Aksesuarları
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler05}>
                  ET200 Modül ve Aksesuarları
                </NavDropdown.Item>
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler04}>
                  HMI
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler06}>
                  Güç Kaynağı
                </NavDropdown.Item> 

              </NavDropdown>    
              <NavDropdown title="Yaskawa"  menuVariant="dark" className="dropend">
              <NavDropdown.Item >
                Sürücü ve Aksesuarları
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler10}>
                AC Sürücü ve Aksesuarları
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler20}>
                Servo Sürücü ve Aksesuarları
                </NavDropdown.Item>
                </NavDropdown.Item>
                <NavDropdown.Item >
                PLC Modül ve Aksesuarları
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler30}>
                Micro PLC ve Aksesuarları
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler40}>
                Slio PLC ve Aksesuarları
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler50}>
                300S PLC ve Aksesuarları
                </NavDropdown.Item>
                </NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" href="#" onClick={submitHandler60}>
                HMI
                </NavDropdown.Item> 
              </NavDropdown> 
              <NavDropdown.Item className="dropdown-item"   href="#" onClick={submitHandler70}>
                Fırsat Ürünler
              </NavDropdown.Item>      
              </NavDropdown>
            
                
                <Nav.Link onClick={OnClick}>                
               <FaShoppingCart/> Sepet                
                { 
                  cartItems.length > 0 && (
                    <Badge pill bg='success' style={{marginLeft:'5px'}} >
                        {cartItems.reduce((a,c)=> a+c.qty,0)}
                    </Badge>
                  )
                }
                </Nav.Link>
               
                { userInfo ?(
                 <NavDropdown title={userInfo.name} id='username'>
                    <LinkContainer to='/profile'>
                      <NavDropdown.Item>Profilim</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/myorderlist'>
                      <NavDropdown.Item>Siparişlerim</NavDropdown.Item>
                  </LinkContainer>    
                  <NavDropdown.Item onClick={logoutHandler}>
                    Çıkış
                  </NavDropdown.Item>

                 </NavDropdown>
                 
                  
                ):(<LinkContainer to={"/login"}>
                <Nav.Link href='/login'>
                <FaUser /> Giriş Yap    
                </Nav.Link> 
                </LinkContainer>
    
                ) } 

                {userInfo &&userInfo.isAdmin && (
                  <NavDropdown title='Admin' id='adminmenu'>
                    <LinkContainer to='/admin/productlist'>
                      <NavDropdown.Item>Products</NavDropdown.Item>

                    </LinkContainer>
                    <LinkContainer to='/admin/userlist'>
                      <NavDropdown.Item>Users</NavDropdown.Item>

                    </LinkContainer>
                    <LinkContainer to='/admin/orderlist'>
                      <NavDropdown.Item>Orders</NavDropdown.Item>

                    </LinkContainer>
                  </NavDropdown>
                )}
                
                </Nav>
            </Navbar.Collapse>
            
        </Container>
        
        
    </Navbar> 
   
    </header>
  )
}

export default Header

