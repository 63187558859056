import { Link } from "react-router-dom";
import {Carousel, Image} from 'react-bootstrap';
import Loader from './Loader';
import Message from './Message';
import { useGetTopProductsQuery } from "../slices/productsApiSlice";
import accounting from 'accounting-js'    



const ProductCarousel = () => {

    const {data:products, isLoading, error} = useGetTopProductsQuery();

  return  isLoading ? <Loader/>: error ? <Message variant='danger'> {error}</Message>
 : (

<Carousel pause='hover' className='bg-primary mb-4'>
{/* 
{products.map(product =>(
<Carousel.Item key={product._id}>
<Link to={`/product/${product._id}`}>
    <Image src={product.image} alt={product.name} fluid />
    <Carousel.Caption className='carousel-captions'>
        <h2>{product.name} {(product) && ( product.discountShow === true) ?((product.extraDiscountShow === true && product.discountShow === true ) || (product.extraDiscountShow === false && product.discountShow === false )                 // product.advertisementShow === true ? ((userInfo) 
              ?(accounting.formatMoney(product.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) +" €"):(accounting.formatMoney(((product.price -(product.price*product.discount/100)).toFixed(2)),{ symbol: "", precision: 2, thousand: ".", decimal: "," }))+" €"):("")}</h2>
    </Carousel.Caption>
</Link>

</Carousel.Item>

))}
*/}

<Carousel.Item>
<Image src="/uploads\1280x480_3.jpg" alt="Siemens" fluid/>

</Carousel.Item>

<Carousel.Item>
<Image src="/uploads\1280x480_2.jpg" alt="Yaskawa" fluid/>

</Carousel.Item>

<Carousel.Item>
<Image src="/uploads\1280x480_1.jpg" alt="Vipa" fluid/>

</Carousel.Item>

</Carousel>

 )   
}

export default ProductCarousel
{/*
<Carousel.Item>

<Link to={'/product/659b057eb4a2d40fbbc2e4d9'}>
<Image src="/uploads\image-1706025580355.jpg" alt="KTP700 Basic PN, 7-6AV2123-2GB03-0AX0" fluid/>

{
<Carousel.Caption className='carousel-captions'>
        <h2>{(products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'})).name} {((products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'}))) && ( (products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'})).discountShow === true) ?(((products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'})).extraDiscountShow === true && (products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'})).discountShow === true ) || ((products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'})).extraDiscountShow === false && (products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'})).discountShow === false )                 // product.advertisementShow === true ? ((userInfo) 
              ?(accounting.formatMoney((products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'})).discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) +" €"):(accounting.formatMoney((((products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'})).price -((products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'})).price*(products.find(({_id})=>{return _id === '659b057eb4a2d40fbbc2e4d9'})).discount/100)).toFixed(2)),{ symbol: "", precision: 2, thousand: ".", decimal: "," }))+" €"):("")}</h2>
    </Carousel.Caption>
}
    </Link>
</Carousel.Item>

*/}