import { USERS_URL } from "../constants";
import { apiSlice } from "./apiSlice";


export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints : (builder) =>({
        login: builder.mutation({
            query: (data) => ({
                url: `${USERS_URL}/auth`, 
                method: 'POST',
                body:data,
            }),
        }),
        register: builder.mutation({
            query: (data)=> ({
                url: `${USERS_URL}`, 
                method: 'POST',
                body:data,

            }),
            
            
        }),
        confirm: builder.mutation({
            query: (userId) => ({
                url: `${USERS_URL}/${userId}`,
                method: 'PUT',
            }),
        }),
        addCategory: builder.mutation({
            query: (userId) => ({
                url: `${USERS_URL}/${userId}/done`,
                method: 'PUT',
            }),
        }),        
        logout: builder.mutation({
            query: () => ({
                url: `${USERS_URL}/logout`,
                method: 'POST',
            }),
        }),

        profile: builder.mutation({
            query: (data)=>({
                url: `${USERS_URL}/profile`,
                method: 'PUT',
                body: data,

            }),
        }),

        getUsers: builder.query({
            query: ({pageNumber}) => ({
              url: USERS_URL, 
              params: {
                pageNumber,
                
              } 
            }),
            providesTags: ['Users'],
            keepUnusedDataFor: 5
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `${USERS_URL}/${userId}`,
                method: 'DELETE',
            })
        }),
        getUserDetails: builder.query({
            query: (userId) =>({
                url:`${USERS_URL}/${userId}`
            }),
            keepUnusedDataFor: 5,
        }),
        updateUser: builder.mutation({
            query: (data)=> ({
                url:`${USERS_URL}/${data.userId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Users'],
        })

        
    }),
    
});

export const{useLoginMutation, useLogoutMutation, useRegisterMutation,useProfileMutation,useGetUsersQuery,useDeleteUserMutation,useGetUserDetailsQuery,useUpdateUserMutation,useConfirmMutation,useAddCategoryMutation} = usersApiSlice;