import React, { useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form,Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Message from '../components/Message';
import CheckoutStatusBar from '../components/CheckoutStatusBar.jsx'
import CheckoutStatusBar1 from '../components/CheckoutStatusBar1.jsx'
import { useCreateOrderMutation } from '../slices/ordersApiSlice';
import { useGetCurrencyQuery } from '../slices/ordersApiSlice';
import accounting from 'accounting-js'
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css'
import CryptoJS from 'crypto-js';
import DocumentModal from '../components/DocumentModal.jsx';



const PlaceOrderScreen = () => {

  const [isIframeVisible, setIframeVisible] = useState(false);
  const navigate = useNavigate();
  const {userInfo} = useSelector((state)=> state.auth );
  const cart = useSelector((state) => state.cart);
  const [trig,setTrig] = useState('');
  const {data:currency} = useGetCurrencyQuery();
  
  const [data1, setData] = useState('');

  const [createOrder, { isLoading, error }] = useCreateOrderMutation();

  
  //--------------

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isEnable,setIsEnable] = useState(false);
  var currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
  const year = currentDate.getFullYear();
  const documentContent = (
    <div>

        <p>
        <h4><strong>MESAFELİ SATIŞ SÖZLEŞMESİ </strong> </h4>
        <p> <strong> MADDE 1 - KONU </strong> </p>
        <Row>
        İşbu sözleşmenin konusu, SATICI'nın, ALICI'ya satışını yaptığı, aşağıda nitelikleri ve satış fiyatı belirtilen ürünün
        </Row>
        <Row>
        satışı ve teslimi ile ilgili olarak Borçlar Kanunu ve/veya Türk Ticaret Kanunu hükümleri gereğince, tarafların hak ve
        </Row>
        <Row>
        yükümlülüklerini kapsamaktadır.
        </Row>
        </p>
         
        
        <p> <strong> MADDE 2.1 - SATICI BİLGİLERİ </strong> </p>
        <p><strong>Ünvan:</strong> ADP ENDÜSTRİYEL OTOMASYON SİSTEMLERİ SAN. ve TİC. LTD. ŞTİ.</p>
        <p><strong>Ticaret Sicil No: 0008-0809-1670-0010 </strong></p>
        <p><strong>Adres: Mandıra Cad. Teker Sok. No:2/D Kadıköy/İstanbul </strong> </p>
        <p><strong>Telefon: 0532 494 84 27</strong></p>
        <p><strong>E-Posta: satis@adpotomasyon.com.tr </strong></p>
        <p> <strong> MADDE 2.2 - ALICI BİLGİLERİ </strong> </p>
        <p><strong>Alıcı: </strong>{cart.shippingAddress.companyName} {cart.shippingAddress.name} {cart.shippingAddress.surname}</p>
        <p><strong>Alıcı Fatura Adresi: </strong>{cart.shippingAddress.companyAddress},{cart.shippingAddress.companyCity},{cart.shippingAddress.companyState}</p>
        {cart.shippingAddress.isCompany ==="true" &&  <p><strong>VKN: </strong>{cart.shippingAddress.taxNumber}</p>  }
        <p><strong>Alıcı Telefonu: </strong>{cart.shippingAddress.phoneNumber}</p>
        <p><strong>Alıcı E-Posta: </strong>{cart.shippingAddress.email}</p>

        <p> <strong> MADDE 3 - SÖZLEŞME KONUSU ÜRÜN BİLGİLERİ </strong> </p>
        <Row>
          <Col> <strong>Ürün</strong> </Col>
          <Col md={4} ><strong>Toplam Fiyat </strong> </Col>
        </Row>
        <ListGroup variant='flush'>
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col>
                            {item.name}
                        </Col>
                        {item.priceShow && item.advertisementShow===false ?( <Col md={4}>
                          {item.qty} x {accounting.formatMoney(item.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} € = { accounting.formatMoney(item.qty *item.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
                        </Col>):( 

                          item.advertisementShow===false ?
                          (<Col md={4}>
                            {item.qty} x Teklif verilecek
                            </Col>
                            ):
                            (<Col md={4}>
                              {item.qty} x {accounting.formatMoney(item.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} € = { accounting.formatMoney(item.qty *item.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
                              </Col>
                              )    
                        )}
                       
                      </Row>
                    </ListGroup.Item>
                  ))}
      </ListGroup>
        <Card>
       
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <strong>Sipariş Özeti</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Ürünler</Col>
                  <Col>{  accounting.formatMoney(cart.itemsPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) +" €"}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>K.D.V(%20)</Col>
                 
                    <Col>{ accounting.formatMoney(cart.taxPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) +" €" }</Col>
                 
                  
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Genel Toplam Tutar</Col>

                  <Col>
                  <Row>
                  &nbsp;&nbsp;&nbsp;{ (currency) && cart.cartItems.find(item=>item.priceShow === false && item.advertisementShow===false)? ('Teklif verilecek'): (accounting.formatMoney(cart.totalPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) + ' €') }
                  </Row>
                  <Row> <strong>
                  { (currency) && !cart.cartItems.find(item=>item.priceShow === false && item.advertisementShow===false) && (accounting.formatMoney(cart.totalPrice * currency.BanknoteSelling,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) + ' ₺') }
                                  </strong>
                  </Row>
                  </Col>
                </Row>
                <Row>
                { (currency) && "Euro Kuru:"+" "+accounting.formatMoney(currency.BanknoteSelling,{ symbol: "", precision: 4, thousand: ".", decimal: "," })+" ₺"}
              </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <p>

          </p>
          <p><strong>Sözleşme Tarihi: </strong>{day}.{month}.{year}</p> 
          <p><strong>Alıcı: </strong>{cart.shippingAddress.receiver}</p> 
          <p><strong>Alıcı Sevk Adresi: </strong>{cart.shippingAddress.address},{cart.shippingAddress.city},{cart.shippingAddress.state}</p>
          <p><strong>Kargo Tercihi: </strong>{cart.shippingAddress.cargoSelection} Alıcı Ödemeli </p>
    <p>      
    <p> <strong>  MADDE 4 - GENEL HÜKÜMLER </strong> </p>
    <row><strong> 4.1. </strong> ALICI, Madde 3'te belirtilen sözleşme konusu ürün veya ürünlerin temel nitelikleri, satış fiyatı ve ödeme şekli ile</row>
    <row>teslimata ilişkin tüm ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan eder.</row>
    </p>
    <p>
    <row><strong> 4.2.</strong>Sözleşme konusu ürün veya ürünler, yasal 30 günlük süreyi aşmamak koşulu ile her bir ürün için ALICI'nın yerleşim yerinin </row>
    <row>uzaklığına bağlı olarak ön bilgiler içinde açıklanan süre dâhilinde ALICI veya gösterdiği adresteki kişi/kuruluşa teslim edilir. Bu süre</row>
    <row>ALICI’ya daha önce bildirilmek kaydıyla en fazla 10 gün daha uzatılabilir.</row>  
    </p>
    <p>
    <row><strong> 4.3.</strong>Sözleşme konusu ürün, ALICI'dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul</row>
    <row>etmemesinden ADP Endüstriyel Otomasyon sorumlu tutulamaz.</row>       
    </p>
    <p>
    <row><strong> 4.4.</strong></row>
    ADP Endüstriyel Otomasyon, sözleşme konusu ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri ve kullanım 
    kılavuzları ile teslim edilmesinden sorumludur.     
    </p>
    <p>
    <row><strong> 4.5.</strong></row>
    Sözleşme konusu ürünün teslimatı için bedelinin ALICI'nın tercih ettiği ödeme şekli ile ödenmiş olması şarttır. Herhangi bir nedenle ürün bedeli ödenmez
     veya banka kayıtlarında iptal edilir ise, ADP Endüstriyel Otomasyon ürünün teslimi yükümlülüğünden kurtulmuş kabul edilir.
    </p>
    <p>
    <row><strong> 4.6.</strong></row>
    Ürünün tesliminden sonra ALICI'ya ait kredi kartının ALICI'nın kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı olarak 
    kullanılması nedeni ile ilgili,banka veya finans kuruluşunun ürün bedelini ADP Endüstriyel Otomasyon'a ödememesi halinde, ALICI'nın kendisine teslim edilmiş olması
     kaydıyla ürünün 3 gün içinde ADP Endüstriyel Otomasyon'a gönderilmesi zorunludur. Bu takdirde nakliye giderleri ALICI'ya aittir. Eğer ürünler ADP Endüstriyel Otomasyon'a iade
      edilmezse hiçbir ihtar ve ihbara gerek kalmaksızın ürün bedeli olan alacağın muaccel hale geleceğini ALICI gayri kabili rücu olarak kabul, beyan ve taahhüt eder.                          
    </p>
    <p>
    <row><strong> 4.7.</strong></row>
    ADP Endüstriyel Otomasyon mücbir sebepler veya nakliyeyi engelleyen hava muhalefeti, ulaşımın kesilmesi gibi olağanüstü durumlar nedeni ile sözleşme konusu ürünü süresi
    içinde teslim edemez ise, durumu ALICI'ya bildirmekle yükümlüdür. Bu takdirde ALICI siparişin iptal edilmesini, sözleşme konusu ürünün varsa emsali ile
    değiştirilmesini, ve/veya teslimat süresini engelleyici durum ortadan kalkana kadar siparişin ertelenmesi haklarından birini kullanabilir. ALICI'nın siparişi
    iptal etmesi halinde ödediği tutar 15 iş günü içinde kendisine nakden ve defaten ödenir.    
    </p>
    <p>
    <row><strong> 4.8.</strong></row>
    Sitede satışı yapılan tüm ürünler üretici firmaların garantisi altında olup üretici firmaların garanti koşulları geçerlidir. Satılan ürünlerden arızalı veya bozuk olanlar,
    garanti şartları içinde gerekli onarımın yapılması için ADP Endüstriyel Otomasyon'a gönderilebilir, bu takdirde kargo giderleri ALICI tarafından karşılanacaktır.   
    </p>
    <p>
    <row><strong> 4.9.</strong></row>
    İşbu sözleşme, ALICI tarafından onaylanmasından sonra geçerlilik kazanır.   
    </p> 
    <p> <strong>  MADDE 5 - CAYMA HAKKI </strong> </p>
    <p>
    ALICI, sözleşme konusu ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa tesliminden itibaren (7) gün içinde cayma hakkına sahiptir. Cayma hakkının kullanılması
    için bu süre içinde ADP Endüstriyel Otomasyon'a faks, e-posta veya telefon ile bildirimde bulunulması ve ürünün iade koşullarında belirtilen hükümler çerçevesinde kullanılmamış
    olması şarttır. Bu hakkın kullanılması halinde, 3. kişiye veya ALICI'ya teslim edilen ürünün ADP Endüstriyel Otomasyon'a gönderildiğine ilişkin kargo teslim tutanağı örneği ile
    fatura aslı ve kopya suretinin iadesi zorunludur. Bu belgelerin ulaşmasını takip eden 10 gün içinde ürün bedeli ALICI'ya iade edilir. Cayma hakkı nedeni ile iade edilen
    ürünün kargo bedeli ALICI tarafından karşılanır.
    </p>
    <p>
    Niteliği itibariyle iade edilemeyecek ürünler, tek kullanımlık ürünler, kopyalanabilir yazılım ve programlar için ve sitede "Stoktan teslim" ibaresi bulunmayan stok dışı ürünlerde
    cayma hakkı kullanılamaz. Her türlü yazılım ve programlar, DVD, VCD, CD ve kasetler, piller, sarf malzemeleri (toner, kartuş, şerit vb.) gibi ürünlerde cayma hakkının kullanılması,
     ürünün ambalajının açılmamış, bozulmamış ve ürünün kullanılmamış olması şartına bağlıdır.
    Ayrıca, tüketicinin özel istek ve talepleri uyarınca üretilen veya üzerinde değişiklik ya da ilaveler yapılarak kişiye özel hale getirilen mallarda tüketici cayma hakkını kullanamaz.
    </p>
    <p>
    Ödemenin kredi kartı veya benzeri bir ödeme kartı ile yapılması halinde tüketici, kartın kendi rızası dışında ve hukuka aykırı biçimde kullanıldığı gerekçesiyle ödeme işleminin
     iptal edilmesini talep edebilir. Bu halde, kartı çıkaran kuruluş itirazın kendisine bildirilmesinden itibaren 15 iş günü içinde ödeme tutarını tüketiciye iade eder.
    Siparişin sonuçlanması durumunda ALICI işbu sözleşmenin tüm koşullarını kabul etmiş sayılacaktır.
    </p>

      
      {/* Add more rows as needed */}
    </div>
  );




  //--------------

  useEffect(() => {
    if (!cart.shippingAddress.address) {
      navigate('/shipping');
    } else if (!cart.paymentMethod) {
      navigate('/payment');
    }
  }, [cart.paymentMethod, cart.shippingAddress.address, navigate]);

  useEffect(() => {
    if (trig) {
    
    } 
  }, [trig]);

  useEffect(() => {
    if (trig) {
    
    } 
  }, [trig]);


const offer = cart.cartItems.find(({priceShow,advertisementShow})=>{return priceShow === false && advertisementShow===false});




  const placeOrderHandler = async () => {
   
 if(offer)
 {
  try {
    setTrig("1");
    const res = await createOrder({
      orderItems: cart.cartItems,
      shippingAddress: cart.shippingAddress,
      paymentMethod: cart.paymentMethod,
      paymentResult: cart.paymentResult,
      itemsPrice: cart.itemsPrice,
      creditCard: cart.creditCard,
      taxPrice: cart.taxPrice,
      totalPrice: cart.totalPrice,
    }).unwrap();
    navigate(`/placeorder/${res._id}`);

  } catch (error) {
  }


 } else{
    try {

      var currentDate = new Date();

  // Add 3 hours to the current date
  currentDate.setHours(currentDate.getHours() + 3);


      setTrig("1");
      const res = await createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
        offeredAt:currentDate,
        isOffered : true,
      }).unwrap();

      navigate(`/placeorder/${res._id}`);

    } catch (error) { 
      
    }

    
  }    
  };



const fetchData = async () => {
//create Order
let xmlData;
   try {
    var currentDate = new Date();

    // Add 3 hours to the current date
    currentDate.setHours(currentDate.getHours() + 3);


    setTrig("1");
    const res = await createOrder({
      orderItems: cart.cartItems,
      shippingAddress: cart.shippingAddress,
      paymentMethod: cart.paymentMethod,
      creditCard: cart.creditCard,
      itemsPrice: cart.itemsPrice,
   //   shippingPrice: cart.shippingPrice,
      taxPrice: cart.taxPrice,
      totalPrice: cart.totalPrice,
      offeredAt: currentDate,
      isOffered : true,
    }).unwrap();

    const password = '357Me159';

    // Hash the password using SHA-1
    const sha1Hash =  CryptoJS.SHA1(password).toString(CryptoJS.enc.Hex);
    
    // Convert hexadecimal hash to binary string
    const binaryHash = CryptoJS.enc.Hex.parse(sha1Hash);
    
    // Convert binary string to Base64 encoded string
    const hashedPassword = CryptoJS.enc.Base64.stringify(binaryHash);
    
    
    
    //console.log('Hashed Password (Base64):', hashedPassword);
      
    
        // Define your variables
    const MerchantId = 102123;
    const MerchantOrderId = res._id;
    const Amount =currency && `${accounting.formatMoney(cart.totalPrice * currency.BanknoteSelling,{ symbol: "", precision: 2, thousand: "", decimal: "" })}`;
    const OkUrl = 'https://www.adpotomasyon.com.tr/cardverification';
    const FailUrl = 'https://www.adpotomasyon.com.tr/cardverification1';
    const UserName = 'Adp';
    const HashedPassword = `${hashedPassword}`;

    const numberString = String(cart.creditCard.expiry);
    const expiryDay = numberString.slice(-2);
    const expiryMonth = numberString.slice(0, 2);
    
    // Concatenate your variables
    const concatenatedString = MerchantId + MerchantOrderId + Amount + OkUrl + FailUrl + UserName + HashedPassword;
    
    // Hash the concatenated string using SHA-1
    const sha1Hash1 = CryptoJS.SHA1(concatenatedString).toString(CryptoJS.enc.Hex);
      
    // Convert hexadecimal hash to binary string
    const binaryHash1 = CryptoJS.enc.Hex.parse(sha1Hash1);
    
    // Convert binary string to Base64 encoded string
    const hashedData = CryptoJS.enc.Base64.stringify(binaryHash1);
    
    // Print the hashed data
    //console.log('Hashed Data (Base64):', hashedData);
    
    

    
    xmlData = `<KuveytTurkVPosMessage xmlns:xsi="http://www.w3.org/2001/XMLSchemainstance"
    xmlns:xsd="http://www.w3.org/2001/XMLSchema">
    <APIVersion>TDV2.0.0</APIVersion>
    <OkUrl>https://www.adpotomasyon.com.tr/cardverification</OkUrl>
    <FailUrl>https://www.adpotomasyon.com.tr/cardverification1</FailUrl>
    <HashData>${hashedData}</HashData>
    <MerchantId>102123</MerchantId>
    <CustomerId>97719890</CustomerId>
    <DeviceData>
    <DeviceChannel>02</DeviceChannel>
    <ClientIP>${cart.creditCard.ip}</ClientIP>
    </DeviceData>
    <CardHolderData>
    <BillAddrCity>İstanbul</BillAddrCity>
    <BillAddrCountry>792</BillAddrCountry>
    <BillAddrLine1>XXX Mahallesi XXX Caddesi No 55 Daire 1</BillAddrLine1>
    <BillAddrPostCode>34000</BillAddrPostCode>
    <BillAddrState>40</BillAddrState>
    <Email>xxxxx@gmail.com</Email>
    <MobilePhone>
    <Cc>90</Cc>
    <Subscriber>5324948492</Subscriber> 
    </MobilePhone>
    </CardHolderData>
    <UserName>Adp</UserName>
    <CardNumber>${cart.creditCard.number}</CardNumber>
    <CardExpireDateYear>${expiryDay}</CardExpireDateYear>
    <CardExpireDateMonth>${expiryMonth}</CardExpireDateMonth>
    <CardCVV2>${cart.creditCard.cvv}</CardCVV2>
    <CardHolderName>METE ISIK</CardHolderName>
    <CardType>VISA</CardType>
    <BatchID>0</BatchID>
    <TransactionType>Sale</TransactionType>
    <InstallmentCount>0</InstallmentCount> 
    <Amount>${Amount}</Amount>
    <DisplayAmount>${Amount}</DisplayAmount>
    <CurrencyCode>0949</CurrencyCode>
    <MerchantOrderId>${MerchantOrderId}</MerchantOrderId>
    <TransactionSecurity>3</TransactionSecurity>
    </KuveytTurkVPosMessage>`;
    
  } catch (error) { 

    console.log('hata oluştu');
  }

// 3D Kart Doğrulama
 try {
  const response = await fetch('/api/data', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/xml'
    },
    body: xmlData
  }); 
  const responseData = await response.json();
  setData(responseData);

  setIframeVisible(true);


  
} catch (error) {

}
 
}

  return (
    (currency) && 
    <>
        {userInfo ? (trig==="" ?(<CheckoutStatusBar1 shipping payment ></CheckoutStatusBar1>):(<CheckoutStatusBar1 shipping payment placeOrder ></CheckoutStatusBar1>) ): (trig==="" ? (<CheckoutStatusBar userfill shipping payment ></CheckoutStatusBar>):(<CheckoutStatusBar userfill shipping payment placeOrder ></CheckoutStatusBar>))}
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Sevkiyat</h2>
              <Row>
              <Col> 
              <p>
                {cart.shippingAddress.receiver}
              </p>
              <p>
                <strong>Adres:</strong>
                {' '}{cart.shippingAddress.address}, {cart.shippingAddress.city}{' '} 
                {cart.shippingAddress.state}
                </p>
              </Col>
              <Col>
              <p>{cart.shippingAddress.email}</p>
              
                <p>
                <strong>Kargo:</strong>
                {' '}{(cart.shippingAddress.cargoSelection==='Yurtiçi Kargo')?(<img src='/images/yurtici.jpg' />):(cart.shippingAddress.cargoSelection==='Aras Kargo' ?(<img src='/images/aras.jpg' />):(<img src='/images/mng.jpg' />))}{' '}{'Alıcı Ödemeli'}
                </p>
                </Col>
                </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Ödeme Şekli</h2>
              <strong>Tercih: </strong>
              {cart.paymentMethod}
              <Row>
                <Col>
                {cart.paymentMethod ==="Banka Transferi" && 
                <Row><strong>ADP ENDÜSTRİYEL OTOMASYON SİSTEMLERİ SAN. VE TİC. LTD. ŞTİ.</strong></Row> }
                {cart.paymentMethod ==="Banka Transferi" &&
                <Row>Kuveyt Türk Katılım Bankası- Fikirtepe Şubesi</Row>}
                {cart.paymentMethod ==="Banka Transferi" &&
                <Row>TRY IBAN:TR82 0020 5000 0977 1989 0000 02</Row>}
                

                {cart.paymentMethod==="Kredi Kartı" && !offer &&
                    
                    <div id="PaymentForm">
                    <Cards
                      cvc={cart.creditCard.cvv}
                      expiry={cart.creditCard.expiry}
                      //focused={cvv}
                      name= {cart.creditCard.name}
                      number ={cart.creditCard.number}
                    />
                    
                  </div>
                                        
                    }

                </Col>
              </Row>
             
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Sipariş Listesi</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Sepetiniz Boş</Message>
              ) : (
                <ListGroup variant='flush'>
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item._id}`}>
                            {item.name}
                          </Link>
                        </Col>
                        {item.priceShow && item.advertisementShow===false ?( <Col md={4}>
                          {item.qty} x {accounting.formatMoney(item.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} € = { accounting.formatMoney(item.qty *item.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
                        </Col>):( 

                    item.advertisementShow===false ?
                    (<Col md={4}>
                      {item.qty} x Teklif verilecek
                      </Col>
                      ):
                      (<Col md={4}>
                        {item.qty} x {accounting.formatMoney(item.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} € = { accounting.formatMoney(item.qty *item.discountPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
                        </Col>
                        )
                        
                        )}
                       
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Sipariş Özeti</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Ürünler</Col>
                  <Col>{(!offer) ?  accounting.formatMoney(cart.itemsPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) +" €" : ""}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>K.D.V(%20)</Col>
                 
                    <Col>{(!offer)? accounting.formatMoney(cart.taxPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) +" €" : ""}</Col>
                 
                  
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Toplam Tutar</Col>

                  <Col>
                  <Row>
                  &nbsp;&nbsp;&nbsp;{cart.cartItems.find(item=>item.priceShow === false && item.advertisementShow===false)? ('Teklif verilecek'): (accounting.formatMoney(cart.totalPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) + ' €') }
                  </Row>
                  <Row> <strong>
                  {!cart.cartItems.find(item=>item.priceShow === false && item.advertisementShow===false) && (accounting.formatMoney(cart.totalPrice * currency.BanknoteSelling,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) + ' ₺') }
                  </strong>
                  </Row>
                  </Col>
                </Row>
                <Row>
                {"Euro Kuru:"+" "+accounting.formatMoney(currency.BanknoteSelling,{ symbol: "", precision: 4, thousand: ".", decimal: "," })+" ₺"}
              </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {error && (
                  <Message variant='danger'>{error.data.message}</Message>
                )}

                {trig==="1" && !error && (
                  <Message variant='success'>{ offer ? ('Teklif İsteği Gönderildi. email adresine en kısa zamanda fiyat teklifi iletilecektir.'):(cart.paymentMethod ==="Kredi Kartı"?('Kart doğrulama bekleniyor!. Lütfen çıkacak Butona tıklayınız'):('Sipariş isteği başarı ile alındı. Emaile gelen siparişinizi başlatın'))}</Message>
                )}
              </ListGroup.Item>
              <ListGroup.Item>
              <Row>
                  <Col  md={4}>
             { (cart.paymentMethod ==="Banka Transferi" || (cart.paymentMethod ==="Kredi Kartı" && offer ))   ?
                (
                <Button
                  type='button'
                  className='btn-block'
                  disabled={trig === "1"}
                  onClick={placeOrderHandler}
                >
                
                  { offer ? ('Teklif İsteği Gönder'):('Sipariş İsteği Gönder')}
                </Button>
                )
                :
                ( !data1 &&
                  <Button
                  type='button'
                  className='btn-block'
                  disabled={ isConfirm===false}
                  onClick={fetchData}
                >
                Satın Al
                </Button>

                )
                }
                </Col>
                { cart.paymentMethod ==="Kredi Kartı" && 
                <Col  md={1}>
                <Form.Group controlId='isAdmin' className="my-1">
               <Form.Check
               type="checkbox"
               checked = {isConfirm}
               disabled = {isEnable===false}
               onChange={(e)=> setIsConfirm(e.target.checked)}
               >
                </Form.Check>
                </Form.Group>
       </Col>
       }
       { cart.paymentMethod ==="Kredi Kartı" &&
       <Col md={7}> 
                <row onClick={() => setIsModalOpen(true)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
               Mesafeli Satış  Sözleşmesini
             </row> kabul ediyorum. 

      <DocumentModal
        isOpen={isModalOpen}
        content={documentContent}
        onClose={() => (setIsModalOpen(false), setIsEnable(true))}
      />
</Col>
}
      </Row>
               

                 {/* Render the HTML code using dangerouslySetInnerHTML */}
   {/* 
     <div  style={{ width: '100%', height: '500px' }} dangerouslySetInnerHTML={{ __html:data1}} /> 
   
   
   
   
   */}
 {   <div>
      {isIframeVisible && (
        <iframe
          srcDoc={data1}
          title="Secure Form"
          width="100%"
          height="500px"
          style={{ border: 'none' }}
        />
      )}
      
    </div>
     }






{/* 
      <a href="/secure-form.html" target="_blank" rel="noopener noreferrer">
        Go to Secure Form
      </a>     
              
    */}          
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PlaceOrderScreen;
