import React, { useState,useEffect,useRef } from 'react';
import {Link,useParams} from 'react-router-dom';
import {Row,Col,ListGroup, Image, Button, Card,Form, FormGroup, ListGroupItem } from 'react-bootstrap';
import { useNavigate,useLocation } from 'react-router-dom'
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useGetOrderDetailsQuery,useDeliverOrderMutation,usePayOrderMutation,useOrderRequestMutation,useOfferSendMutation,useActivateSendMutation,useCreateOrderMutation} from '../slices/ordersApiSlice';
import { useDispatch,useSelector } from 'react-redux';
import { saveCreditCard } from '../slices/cartSlice'
import accounting from 'accounting-js'
import { useGetCurrencyQuery,useVerifiedMutation } from '../slices/ordersApiSlice';
import {setXmlData} from '../slices/xmlSlice';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css'
import CryptoJS from 'crypto-js';
import DocumentModal from '../components/DocumentModal.jsx';

const OrderScreen = () => {
    const {id: orderId} =useParams();
    const {data: order,refetch, isLoading, error} = useGetOrderDetailsQuery(orderId);
    const [payOrder, { isLoading: loadingPay }] = usePayOrderMutation();
    const [deliverOrder, {isLoading: loadingDeliver}] = useDeliverOrderMutation();
    const [orderRequest,{isLoading: loadingOrder}] = useOrderRequestMutation();
    const [offerSend,{isLoading: loadingOffer}] = useOfferSendMutation();
    const [activateSend,{isLoading: loadingActivate}] = useActivateSendMutation();


    const {userInfo} = useSelector((state)=>state.auth);
    const [tempOrder,setTempOrder] = useState(order);
    const [orderItems,setOrderItems] = useState();
    const [deliverNumber,setDeliverNumber] = useState();
    const[tempItemPrice,setTempItemPrice]= useState();
    const[tempTaxPrice,setTempTaxPrice]= useState();
    const[tempTotalPrice,setTempTotalPrice]= useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const[trig,setTrig] = useState();
    
    const [name,setName] = useState('');
    const [cvv,setCvv] = useState('');
    const [expiry,setExpiry] = useState('');
    const [number,setNumber] = useState('');
    const [ip,setIp] = useState('');
    const {data:currency} = useGetCurrencyQuery();
    
    const[formErrors,setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false); 
    
    const [createOrder] = useCreateOrderMutation();
    const cart = useSelector((state) => state.cart);
    
    const [paymentMethod,setPaymentMethod] = useState(cart?.paymentMethod || 'Banka Transferi');
    const [data1, setData] = useState('');
    const [verified] = useVerifiedMutation();
 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [isEnable,setIsEnable] = useState(false);
    const [documentContent, setDocumentContent] = useState();

    
    

    let responseData;
    useEffect(() => {
      const fetchIp = async () => {
        try {
          const response = await fetch('https://api.ipify.org');
          const data = await response.text();
          setIp(data);
        } catch (error) {
          console.error('Error fetching IP address:', error);
        }
      };
  
      fetchIp();
    }, []); // Empty dependency array ensures that this effect runs only once on mount

    
      const placeOrderHandler1 = () => {  
        {
    
            setFormErrors(validate());
            console.log(ip);
                  setIsSubmit(true);
                  dispatch(saveCreditCard({number,name,expiry,cvv,ip}));
   
        }

    }

 
    
    const validate = () => {
        const errors ={};
        if(!name)
        {
          errors.name = "Kart üzerindeki adı soyadı giriniz";
        }
        if(number.length<16)
        {
          errors.number = "Kart Numarası eksik";
        }
        if(!number)
        {
          errors.number = "Kart Numarasını giriniz"
        }
        if(expiry.length<4)
        {
          errors.expiry = "Ay/Yıl eksik";
        }
        if(!expiry ){
          errors.expiry = "Ay/Yıl giriniz"
        }
        if(cvv.length<3)
        {
          errors.cvv = "Cvv Numarası eksik";
        }
        if(!cvv  ){
          errors.cvv = "Cvv numarasını giriniz"
        }  
      return errors;
      }



 

    useEffect(() =>{
    
        if(Object.keys(formErrors).length === 0 && isSubmit)
        {
          fetchData();
        }
      
      },[isSubmit]);
   

      const fetchData = async () =>{
        const updatedOrder = {
            orderId,
            number,   
        };
        try {
            await verified(updatedOrder);
          //  refetch();
         fetchData1();
        //    toast.success('Order delivered');  
          } catch (err) {
        //      toast.error(err?.data?.message || err.message);
          }


      }
   

      const fetchData1 = async () => {
        //create Order
        let xmlData;

        
           try {

            
            setTrig("1");
            
            const password = '357Me159';
        
            // Hash the password using SHA-1
            const sha1Hash =  CryptoJS.SHA1(password).toString(CryptoJS.enc.Hex);
            
            // Convert hexadecimal hash to binary string
            const binaryHash = CryptoJS.enc.Hex.parse(sha1Hash);
            
            // Convert binary string to Base64 encoded string
            const hashedPassword = CryptoJS.enc.Base64.stringify(binaryHash);
            
              
            
                // Define your variables
            const MerchantId = 102123;
            const MerchantOrderId = orderId;
            const Amount =currency && `${accounting.formatMoney(tempOrder.totalPrice * currency.BanknoteSelling,{ symbol: "", precision: 2, thousand: "", decimal: "" })}`;
            const OkUrl = 'https://www.adpotomasyon.com.tr/cardverification';
            const FailUrl = 'https://www.adpotomasyon.com.tr/cardverification1';
            const UserName = 'Adp';
            const HashedPassword = `${hashedPassword}`;


            const numberString = String(expiry);
            const expiryDay = numberString.slice(-2);
            const expiryMonth = numberString.slice(0, 2);

            
            
            // Concatenate your variables
            const concatenatedString = MerchantId + MerchantOrderId + Amount + OkUrl + FailUrl + UserName + HashedPassword;
            
            // Hash the concatenated string using SHA-1
            const sha1Hash1 = CryptoJS.SHA1(concatenatedString).toString(CryptoJS.enc.Hex);
              
            // Convert hexadecimal hash to binary string
            const binaryHash1 = CryptoJS.enc.Hex.parse(sha1Hash1);
            
            // Convert binary string to Base64 encoded string
            const hashedData = CryptoJS.enc.Base64.stringify(binaryHash1);
            
            // Print the hashed data
            //console.log('Hashed Data (Base64):', hashedData);
            
            
            xmlData = `<KuveytTurkVPosMessage xmlns:xsi="http://www.w3.org/2001/XMLSchemainstance"
            xmlns:xsd="http://www.w3.org/2001/XMLSchema">
            <APIVersion>TDV2.0.0</APIVersion>
            <OkUrl>https://www.adpotomasyon.com.tr/cardverification</OkUrl>
            <FailUrl>https://www.adpotomasyon.com.tr/cardverification1</FailUrl>
            <HashData>${hashedData}</HashData>
            <MerchantId>102123</MerchantId>
            <CustomerId>97719890</CustomerId>
            <DeviceData>
            <DeviceChannel>02</DeviceChannel>
            <ClientIP>${ip}</ClientIP>
            </DeviceData>
            <CardHolderData>
            <BillAddrCity>İstanbul</BillAddrCity>
            <BillAddrCountry>792</BillAddrCountry>
            <BillAddrLine1>XXX Mahallesi XXX Caddesi No 55 Daire 1</BillAddrLine1>
            <BillAddrPostCode>34000</BillAddrPostCode>
            <BillAddrState>40</BillAddrState>
            <Email>xxxxx@gmail.com</Email>
            <MobilePhone>
            <Cc>90</Cc>
            <Subscriber>5324948427</Subscriber> 
            </MobilePhone>
            </CardHolderData>
            <UserName>Adp</UserName>
            <CardNumber>${number}</CardNumber>
            <CardExpireDateYear>${expiryDay}</CardExpireDateYear>
            <CardExpireDateMonth>${expiryMonth}</CardExpireDateMonth>
            <CardCVV2>${cvv}</CardCVV2>
            <CardHolderName>METE</CardHolderName>
            <CardType>VISA</CardType>
            <BatchID>0</BatchID>
            <TransactionType>Sale</TransactionType>
            <InstallmentCount>0</InstallmentCount> 
            <Amount>${Amount}</Amount>
            <DisplayAmount>${Amount}</DisplayAmount>
            <CurrencyCode>0949</CurrencyCode>
            <MerchantOrderId>${MerchantOrderId}</MerchantOrderId>
            <TransactionSecurity>3</TransactionSecurity>
            </KuveytTurkVPosMessage>`;
           dispatch(setXmlData({xmlData}));
           navigate('/calc');
          } catch (error) { 
          }
    
        }


    if(!userInfo && trig==true)
    {
  
      if(order.user._id == "65b4216620f7fabf737b0c73") 
      {
        navigate(`/order/${orderId}`);
      }
      else{ 

        navigate('/login',
            {
                state: {
                    post_id: orderId,
                }
            });

        }  
    }


  


// TESTING ONLY! REMOVE BEFORE PRODUCTION
   const onApproveTest = async ()=> {
     await payOrder({ orderId, details: { payer: {} } });
     refetch();


   }



const deliverOrderHandler = async () => {
    const updatedOrder = {
        orderId,
        deliverNumber,       
    };
    try {
      await deliverOrder(updatedOrder);
      refetch();
  //    toast.success('Order delivered');  
    } catch (err) {
  //      toast.error(err?.data?.message || err.message);
    }
}

const orderRequestHandler = async () => {
    try {
      await orderRequest(orderId);
      refetch();
      
    //  toast.success('Order Requested');  
    } catch (err) {
    //    toast.error(err?.data?.message || err.message);
    }
}

const offerSendHandler = async () => {
    const updatedOrder = {
        orderId,
        orderItems,
        tempItemPrice,
        tempTaxPrice,
        tempTotalPrice,
       
    };

    try {
      await offerSend(updatedOrder);
      refetch();
    //  toast.success('Offer Send');  
    } catch (err) {
    //    toast.error(err?.data?.message || err.message);
    }  
   
}

const activateSendHandler = async () => {
   
    try {
        await activateSend(orderId);
        refetch();

      } catch (err) {

      }
}



useEffect(() => {
    if (order) {
        setTempOrder(order);
        setOrderItems(order.orderItems);
        activateSendHandler();

        var currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
        const year = currentDate.getFullYear();


        //----------------------

         setDocumentContent(
          <div>
      
              <p>
              <h4><strong>MESAFELİ SATIŞ SÖZLEŞMESİ </strong> </h4>
              <p> <strong> MADDE 1 - KONU </strong> </p>
              <Row>
              İşbu sözleşmenin konusu, SATICI'nın, ALICI'ya satışını yaptığı, aşağıda nitelikleri ve satış fiyatı belirtilen ürünün
              </Row>
              <Row>
              satışı ve teslimi ile ilgili olarak Borçlar Kanunu ve/veya Türk Ticaret Kanunu hükümleri gereğince, tarafların hak ve
              </Row>
              <Row>
              yükümlülüklerini kapsamaktadır.
              </Row>
              </p>
               
              
              <p> <strong> MADDE 2.1 - SATICI BİLGİLERİ </strong> </p>
              <p><strong>Ünvan:</strong> ADP ENDÜSTRİYEL OTOMASYON SİSTEMLERİ SAN. ve TİC. LTD. ŞTİ.</p>
              <p><strong>Ticaret Sicil No: 0008-0809-1670-0010 </strong></p>
              <p><strong>Adres: Mandıra Cad. Teker Sok. No:2/D Kadıköy/İstanbul </strong> </p>
              <p><strong>Telefon: 0532 494 84 27</strong></p>
              <p><strong>E-Posta: satis@adpotomasyon.com.tr </strong></p>
              <p> <strong> MADDE 2.2 - ALICI BİLGİLERİ </strong> </p>
              <p><strong>Alıcı: </strong>{order.shippingAddress.companyName} {order.shippingAddress.name} {order.shippingAddress.surname}</p>
              <p><strong>Alıcı Fatura Adresi: </strong>{order.shippingAddress.companyAddress},{order.shippingAddress.companyCity},{order.shippingAddress.companyState}</p>
              {order.shippingAddress.isCompany ==="true" &&  <p><strong>VKN: </strong>{order.shippingAddress.taxNumber}</p>  }
              <p><strong>Alıcı Telefonu: </strong>{order.shippingAddress.phoneNumber}</p>
              <p><strong>Alıcı E-Posta: </strong>{order.shippingAddress.email}</p>
      
              <p> <strong> MADDE 3 - SÖZLEŞME KONUSU ÜRÜN BİLGİLERİ </strong> </p>
              <Row>
                <Col> <strong>Ürün</strong> </Col>
                <Col md={4} ><strong>Toplam Fiyat </strong> </Col>
              </Row>
              <ListGroup variant='flush'>
                        {order.orderItems.map((item, index) => (
                          <ListGroup.Item key={index}>
                            <Row>
                              <Col>
                                  {item.name}
                              </Col>
                              {item.priceShow && item.advertisementShow===false ?( <Col md={4}>
                                {item.qty} x {accounting.formatMoney(item.price,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} € = { accounting.formatMoney(item.qty *item.price,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
                              </Col>):( 
      
                                item.advertisementShow===false ?
                                (<Col md={4}>
                                  {item.qty} x Teklif verilecek
                                  </Col>
                                  ):
                                  (<Col md={4}>
                                    {item.qty} x {accounting.formatMoney(item.price,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} € = { accounting.formatMoney(item.qty *item.price,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
                                    </Col>
                                    )    
                              )}
                             
                            </Row>
                          </ListGroup.Item>
                        ))}
            </ListGroup>
              <Card>
             
                  <ListGroup variant='flush'>
                    <ListGroup.Item>
                      <strong>Sipariş Özeti</strong>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Ürünler</Col>
                        <Col>{  accounting.formatMoney(order.itemsPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) +" €"}</Col>
                      </Row>
                    </ListGroup.Item>
      
                    <ListGroup.Item>
                      <Row>
                        <Col>K.D.V(%20)</Col>
                       
                          <Col>{ accounting.formatMoney(order.taxPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) +" €" }</Col>
                       
                        
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Genel Toplam Tutar</Col>
      
                        <Col>
                        <Row>
                        &nbsp;&nbsp;&nbsp;{ (currency) && order.orderItems.find(item=>item.priceShow === false && item.advertisementShow===false)? ('Teklif verilecek'): (accounting.formatMoney(order.totalPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) + ' €') }
                        </Row>
                        <Row> <strong>
                        { (currency) && !order.orderItems.find(item=>item.priceShow === false && item.advertisementShow===false) && (accounting.formatMoney(order.totalPrice * currency.BanknoteSelling,{ symbol: "", precision: 2, thousand: ".", decimal: "," }) + ' ₺') }
                                        </strong>
                        </Row>
                        </Col>
                      </Row>
                      <Row>
                      { (currency) && "Euro Kuru:"+" "+accounting.formatMoney(currency.BanknoteSelling,{ symbol: "", precision: 4, thousand: ".", decimal: "," })+" ₺"}
                    </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
             
                <p></p>
                {(order.isOrdered || order.isPaid) ?(
                <p><strong>Sipariş Numarası:</strong>{order._id}</p>  
                )
                :
                (
                ''
                )}

                {(order.isOrdered || order.isPaid)&&
                  (order.isOrdered)
                  ?
                  (
                    <p><strong>Sözleşme Tarihi: </strong>{order.orderedAt}</p>
                  )
                  :
                  (
                    (order.isPaid) ?(<p><strong>Sözleşme Tarihi: </strong>{order.paidAt}</p>):(<p><strong>Sözleşme Tarihi: </strong>{day}.{month}.{year}</p> )
                    
                  )
                }
               
                <p><strong>Alıcı: </strong>{order.shippingAddress.receiver}</p> 
                <p><strong>Alıcı Sevk Adresi: </strong>{order.shippingAddress.address},{order.shippingAddress.city},{order.shippingAddress.state}</p>
                <p><strong>Kargo Tercihi: </strong>{order.shippingAddress.cargoSelection} Alıcı Ödemeli </p>
          <p>      
          <p> <strong>  MADDE 4 - GENEL HÜKÜMLER </strong> </p>
          <row><strong> 4.1. </strong> ALICI, Madde 3'te belirtilen sözleşme konusu ürün veya ürünlerin temel nitelikleri, satış fiyatı ve ödeme şekli ile</row>
          <row>teslimata ilişkin tüm ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan eder.</row>
          </p>
          <p>
          <row><strong> 4.2.</strong>Sözleşme konusu ürün veya ürünler, yasal 30 günlük süreyi aşmamak koşulu ile her bir ürün için ALICI'nın yerleşim yerinin </row>
          <row>uzaklığına bağlı olarak ön bilgiler içinde açıklanan süre dâhilinde ALICI veya gösterdiği adresteki kişi/kuruluşa teslim edilir. Bu süre</row>
          <row>ALICI’ya daha önce bildirilmek kaydıyla en fazla 10 gün daha uzatılabilir.</row>  
          </p>
          <p>
          <row><strong> 4.3.</strong>Sözleşme konusu ürün, ALICI'dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul</row>
          <row>etmemesinden ADP Endüstriyel Otomasyon sorumlu tutulamaz.</row>       
          </p>
          <p>
          <row><strong> 4.4.</strong></row>
          ADP Endüstriyel Otomasyon, sözleşme konusu ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri ve kullanım 
          kılavuzları ile teslim edilmesinden sorumludur.     
          </p>
          <p>
          <row><strong> 4.5.</strong></row>
          Sözleşme konusu ürünün teslimatı için bedelinin ALICI'nın tercih ettiği ödeme şekli ile ödenmiş olması şarttır. Herhangi bir nedenle ürün bedeli ödenmez
           veya banka kayıtlarında iptal edilir ise, ADP Endüstriyel Otomasyon ürünün teslimi yükümlülüğünden kurtulmuş kabul edilir.
          </p>
          <p>
          <row><strong> 4.6.</strong></row>
          Ürünün tesliminden sonra ALICI'ya ait kredi kartının ALICI'nın kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya hukuka aykırı olarak 
          kullanılması nedeni ile ilgili,banka veya finans kuruluşunun ürün bedelini ADP Endüstriyel Otomasyon'a ödememesi halinde, ALICI'nın kendisine teslim edilmiş olması
           kaydıyla ürünün 3 gün içinde ADP Endüstriyel Otomasyon'a gönderilmesi zorunludur. Bu takdirde nakliye giderleri ALICI'ya aittir. Eğer ürünler ADP Endüstriyel Otomasyon'a iade
            edilmezse hiçbir ihtar ve ihbara gerek kalmaksızın ürün bedeli olan alacağın muaccel hale geleceğini ALICI gayri kabili rücu olarak kabul, beyan ve taahhüt eder.                          
          </p>
          <p>
          <row><strong> 4.7.</strong></row>
          ADP Endüstriyel Otomasyon mücbir sebepler veya nakliyeyi engelleyen hava muhalefeti, ulaşımın kesilmesi gibi olağanüstü durumlar nedeni ile sözleşme konusu ürünü süresi
          içinde teslim edemez ise, durumu ALICI'ya bildirmekle yükümlüdür. Bu takdirde ALICI siparişin iptal edilmesini, sözleşme konusu ürünün varsa emsali ile
          değiştirilmesini, ve/veya teslimat süresini engelleyici durum ortadan kalkana kadar siparişin ertelenmesi haklarından birini kullanabilir. ALICI'nın siparişi
          iptal etmesi halinde ödediği tutar 15 iş günü içinde kendisine nakden ve defaten ödenir.    
          </p>
          <p>
          <row><strong> 4.8.</strong></row>
          Sitede satışı yapılan tüm ürünler üretici firmaların garantisi altında olup üretici firmaların garanti koşulları geçerlidir. Satılan ürünlerden arızalı veya bozuk olanlar,
          garanti şartları içinde gerekli onarımın yapılması için ADP Endüstriyel Otomasyon'a gönderilebilir, bu takdirde kargo giderleri ALICI tarafından karşılanacaktır.   
          </p>
          <p>
          <row><strong> 4.9.</strong></row>
          İşbu sözleşme, ALICI tarafından onaylanmasından sonra geçerlilik kazanır.   
          </p> 
          <p> <strong>  MADDE 5 - CAYMA HAKKI </strong> </p>
          <p>
          ALICI, sözleşme konusu ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa tesliminden itibaren (7) gün içinde cayma hakkına sahiptir. Cayma hakkının kullanılması
          için bu süre içinde ADP Endüstriyel Otomasyon'a faks, e-posta veya telefon ile bildirimde bulunulması ve ürünün iade koşullarında belirtilen hükümler çerçevesinde kullanılmamış
          olması şarttır. Bu hakkın kullanılması halinde, 3. kişiye veya ALICI'ya teslim edilen ürünün ADP Endüstriyel Otomasyon'a gönderildiğine ilişkin kargo teslim tutanağı örneği ile
          fatura aslı ve kopya suretinin iadesi zorunludur. Bu belgelerin ulaşmasını takip eden 10 gün içinde ürün bedeli ALICI'ya iade edilir. Cayma hakkı nedeni ile iade edilen
          ürünün kargo bedeli ALICI tarafından karşılanır.
          </p>
          <p>
          Niteliği itibariyle iade edilemeyecek ürünler, tek kullanımlık ürünler, kopyalanabilir yazılım ve programlar için ve sitede "Stoktan teslim" ibaresi bulunmayan stok dışı ürünlerde
          cayma hakkı kullanılamaz. Her türlü yazılım ve programlar, DVD, VCD, CD ve kasetler, piller, sarf malzemeleri (toner, kartuş, şerit vb.) gibi ürünlerde cayma hakkının kullanılması,
           ürünün ambalajının açılmamış, bozulmamış ve ürünün kullanılmamış olması şartına bağlıdır.
          Ayrıca, tüketicinin özel istek ve talepleri uyarınca üretilen veya üzerinde değişiklik ya da ilaveler yapılarak kişiye özel hale getirilen mallarda tüketici cayma hakkını kullanamaz.
          </p>
          <p>
          Ödemenin kredi kartı veya benzeri bir ödeme kartı ile yapılması halinde tüketici, kartın kendi rızası dışında ve hukuka aykırı biçimde kullanıldığı gerekçesiyle ödeme işleminin
           iptal edilmesini talep edebilir. Bu halde, kartı çıkaran kuruluş itirazın kendisine bildirilmesinden itibaren 15 iş günü içinde ödeme tutarını tüketiciye iade eder.
          Siparişin sonuçlanması durumunda ALICI işbu sözleşmenin tüm koşullarını kabul etmiş sayılacaktır.
          </p>
      
            
            {/* Add more rows as needed */}
          </div>
        
        );
      



        //----------------------
     
      
    }
    
  }, [order,isLoading]);



  useEffect(() => {
    if (userInfo){ 
    if(userInfo.isAdmin)  {
        setTrig(true);

    }
    else{
        setTrig(false);
    }
    
}
  }, [userInfo]);



const submitHandler = (e) => {

    setTempOrder({...tempOrder,  
    itemsPrice: tempOrder.orderItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2), 
    taxPrice: ((tempOrder.orderItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2))*0.2).toFixed(2),
    totalPrice: tempOrder.orderItems.reduce((acc, item) => acc + item.qty * item.price*1.20, 0).toFixed(2),
    });
     setOrderItems(tempOrder.orderItems);
     setTempItemPrice(tempOrder.orderItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2));
     setTempTaxPrice(((tempOrder.orderItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2))*0.2).toFixed(2)) ;
     setTempTotalPrice(tempOrder.orderItems.reduce((acc, item) => acc + item.qty * item.price*1.20, 0).toFixed(2)); 
} 


  return (isLoading ) ? (
  <Loader />
  ) : error ? (
  <Message variant="danger" />
  ) : ((tempOrder) && 
    <>
    <h1>Sipariş No: {tempOrder._id}</h1>
    <Row>
        <Col md={8}>
            <ListGroup variant='flush'>
                <ListGroup.Item>
           
            <h2>Sevkiyat</h2>
            <Row>
                <Col>
                <p>
                <strong>İsim:</strong> {tempOrder.shippingAddress.receiver}
            </p>
            <p>
            <strong>Sevk Adresi:</strong> 
                {tempOrder.shippingAddress.address}, {tempOrder.shippingAddress.city}{''}
                ,{tempOrder.shippingAddress.state}
               
            </p>
            </Col>
            <Col>
            <p>
                <strong>Email:</strong> {tempOrder.shippingAddress.email}
            </p>
            <strong>Kargo:</strong>
                {' '}{(tempOrder.shippingAddress.cargoSelection==='Yurtiçi Kargo')?(<img src='/images/yurtici.jpg' />):(tempOrder.shippingAddress.cargoSelection==='Aras Kargo' ?(<img src='/images/aras.jpg' />):(<img src='/images/mng.jpg' />))}{' '}{'Alıcı Ödemeli'}
    
            </Col>
            </Row>
                
            {tempOrder.isPaid &&(tempOrder.isDelivered ?(
                <Message variant='success'>
                    Kargoya verildi {tempOrder.deliveredAt +" Kargo Takip No: "+ tempOrder.deliverNumber}
                </Message>
            ):(
                <Message variant='danger'>Henüz Kargoya verilmedi</Message>
            ))}
            </ListGroup.Item>

            <ListGroup.Item>
                <h2>Ödeme Şekli</h2>
                <Row>
                    <Col>
                    <p>
                    <strong>Tercih: </strong>
                    {tempOrder.paymentMethod}
                    </p>
                    {tempOrder.paymentMethod==="Kredi Kartı" && !tempOrder.isPaid && !data1 &&
<form>

<Row>
  <Col xs={12} md={12}>
  <Form.Group className='my-1'>
  <Form.Control
  type="text"
  name="number"
  placeholder="KART NUMARASI"
  value= {number}
  onChange={(e)=> e.target.value.length <17 && 
    setNumber(e.target.value)}
  ></Form.Control>  
</Form.Group>
<p style={{ color: 'red' }}>{formErrors.number}</p> 
</Col>
</Row>
<Row>
<Col xs={12} md={12}>
<Form.Group className='my-1'>
<Form.Control
  type="text"
  name="name"
  placeholder="ADI SOYADI"
  value= {name}
  onChange={(e)=> setName(e.target.value)}
  ></Form.Control>
</Form.Group>
<p style={{ color: 'red' }}>{formErrors.name}</p> 
</Col>

</Row>
<Row>
<Col xs={12} md={6}>
  <Form.Group className='my-1'>
  <Form.Control
  type="text"
  name="expiry"
  placeholder="AY/YIL"
  value= {expiry}
  onChange={(e)=>
    e.target.value.length <5 &&  
    setExpiry(e.target.value)}
  ></Form.Control>
</Form.Group>
<p style={{ color: 'red' }}>{formErrors.expiry}</p> 
</Col>
<Col xs={12} md={6}>
  <Form.Group className='my-1'>
  <Form.Control
  type="text"
  name="cvv"
  placeholder="CVV"
  value= {cvv}
  onChange={(e)=>
    e.target.value.length <4 &&  
    setCvv(e.target.value)}
  ></Form.Control>
 </Form.Group>
 <p style={{ color: 'red' }}>{formErrors.cvv}</p> 
</Col>
</Row>

</form>
                    }
                                
                    </Col>
                    {tempOrder.paymentMethod==="Banka Transferi" ? (
                    <Col className='text-center py-0'>
                    <p className='text-start m-0'><strong>ADP ENDÜSTRİYEL OTOMASYON SİSTEMLERİ SAN. VE TİC. LTD. ŞTİ.</strong></p>
                    <p className='text-start m-0'>Kuveyt Türk Katılım Bankası-Fikirtepe Şubesi</p>
                    <p className='text-start m-0'>TRY IBAN: TR82 0020 5000 0977 1989 0000 02</p>
                    </Col>)
                     :( tempOrder.isPaid  && !data1 ? (
                    <Col className='text-center py-4'> 
                     <Cards
                      cvc={''}
                      expiry={''}
                      //focused={cvv}
                      name= {''}
                      number ={tempOrder.paymentResult.id}
                    />
                    </Col>
                    )
                    :
                    (
                        <Col className='text-center py-4'> 
                        <Cards
                         cvc={cvv}
                         expiry={expiry}
                         //focused={cvv}
                         name= {name}
                         number ={number}
                       />
                       </Col>

                    )
                    )}
                </Row>
               
                {order.isPaid ? (
                    <Message variant='success'>
                        Ödendi {tempOrder.paidAt}

                    </Message>
                ):(
                    <Message variant='danger'>Henüz ödenmedi</Message>
                )}
            </ListGroup.Item>
            <ListGroup.Item>
                <h2>Sipariş Listesi</h2>
                { tempOrder.orderItems.map((item,index) =>(
                    <ListGroup.Item key={index}>
                        <Row>
                            <Col md={1}>
                                <Image src={item.image} alt={item.name} fluid rounded />
                            </Col>
                            <Col>
                            <Link to={`/product/${item.product}`}>
                                {item.name}
                            </Link>
                            </Col>
                            {((tempOrder.isOffered=== true && item.priceShow === false) || trig === true || item.priceShow === true )?
                            <Col md={4}>
                     

                            { (trig=== true & !tempOrder.isOffered) && (<input disabled={item.priceShow  === true} name={item._id} type="number" onChange={(e)=> { setTempOrder({...tempOrder,  orderItems:[...tempOrder.orderItems.map(product=>{
        if(product===item && product.priceShow===false) return  {...product,  price : parseFloat(e.target.value,2) } 
        else{
         return  (product);
       }
 })]});
 
 } }/>) }
                    
                            {item.qty} x {accounting.formatMoney(item.price,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} € = {accounting.formatMoney(item.qty * item.price,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €

                           
                            </Col>
                             :
                             <Col md={8}>
                                Teklif Bekleniyor
                            </Col>
                             }
                             
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup.Item>
            </ListGroup>
        </Col>
        <Col md={4}>
        <Card>
            <ListGroup variant='flush'>
                <ListGroup.Item>
                    <h2>Sipariş Özeti</h2>
                </ListGroup.Item>
                <ListGroup>
                <Row>
                    <Col>Ürünler:</Col>
                    {(order.isOffered=== true || trig === true)?
                    <Col>{accounting.formatMoney(tempOrder.itemsPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €    
                    </Col>              
                    :
                    <Col></Col>
                    }
                </Row>     
             <Row>
                <Col>K.D.V(%20):</Col>
                {(order.isOffered=== true || trig === true)?
                <Col>{accounting.formatMoney(tempOrder.taxPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €</Col>
                :
                <Col></Col>
                }
                </Row> 

                <Row>
                <Col>Toplam Tutar:</Col>
                {(order.isOffered=== true || trig === true)?
                <Col>
                
                {accounting.formatMoney(tempOrder.totalPrice,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} €
               
                <h5> <p className='text-start py-2'>
                {accounting.formatMoney(tempOrder.totalPriceTl,{ symbol: "", precision: 2, thousand: ".", decimal: "," })} ₺
                </p></h5>    
                </Col>
                :
                    <Col> Teklif Bekleniyor</Col>
                }
                </Row>  
                 {"Euro Kuru:"+" "+accounting.formatMoney(tempOrder.currencyEur,{ symbol: "", precision: 4, thousand: ".", decimal: "," })+" ₺"}
            </ListGroup>
            <ListGroup>
                <ListGroup.Item>
                { tempOrder.isOrdered && !tempOrder.isPaid &&
            <Col>
            
            <Message variant='success'>Siparişiniz Başarı ile başlatıldı.Takibini Siparişlerimden devam edebilirsiniz.</Message>
            <row onClick={() => setIsModalOpen(true)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
            Mesafeli Satış  Sözleşmesi
            </row>
            <DocumentModal
            isOpen={isModalOpen}
            content={documentContent}
            onClose={() => (setIsModalOpen(false), setIsEnable(true))}
          />
          </Col>
            }
              { tempOrder.isPaid &&
              <Col>
<Message variant='success'>Ödemeniz Alındı. Alışverişiniz için teşekkür ederiz.</Message>
<row onClick={() => setIsModalOpen(true)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
            Mesafeli Satış  Sözleşmesi
            </row>
            <DocumentModal
            isOpen={isModalOpen}
            content={documentContent}
            onClose={() => (setIsModalOpen(false), setIsEnable(true))}
          />
</Col>
}
            </ListGroup.Item>
            </ListGroup>
            <ListGroup>

                {/*PAY ORDER PLACEHOLDER */}
                {loadingDeliver&& <Loader/>}

                {userInfo && userInfo.isAdmin  && !tempOrder.isDelivered && tempOrder.isPaid && (
                    <ListGroup.Item>
                        <Button type='button' className = 'btn btn-block'
                        onClick={deliverOrderHandler}>
                          Kargoda İşaretle  
                        </Button>
                    </ListGroup.Item>
                    
                )}


                
                {/*ORDER PLACEHOLDER */}
                {loadingOrder&& <Loader/>}

                {!data1 && (tempOrder.paymentMethod==="Kredi Kartı" ? ( tempOrder.isPaid===false &&(
                      <ListGroup.Item>
                 <Row>
                          <Col>
                <Button
                type='button'
                className='btn-block'
                disabled={ isConfirm===false}

                onClick={placeOrderHandler1}
                >

                Satın Al
                </Button>
                </Col>
                <Col md={1}>
{!tempOrder.isPaid&&                
<Form.Check
type="checkbox"
checked = {isConfirm}
disabled = {isEnable===false}
onChange={(e)=> setIsConfirm(e.target.checked)}
>
 </Form.Check>
                }
 </Col>
{order &&
       <Col md={7}> 
                <row onClick={() => setIsModalOpen(true)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
               Mesafeli Satış  Sözleşmesini
             </row> {(tempOrder.isOrdered || tempOrder.isPaid) ?(''):('kabul ediyorum.')} 

      <DocumentModal
        isOpen={isModalOpen}
        content={documentContent}
        onClose={() => (setIsModalOpen(false), setIsEnable(true))}
      />
</Col>
}
</Row>
                </ListGroup.Item>
                ))
                :
                (userInfo ? (!tempOrder.isOrdered &&   !userInfo.isAdmin  &&
                    <ListGroup.Item>
                        <Row>
                        <Col>
                        <Button type='button' className = 'btn btn-block'
                        disabled={ isConfirm===false}
                        onClick={orderRequestHandler}>
                          Siparişi Başlat
                        </Button>
                        </Col>
                <Col md={1}> 
{!tempOrder.isOrdered &&                               
<Form.Check
type="checkbox"
checked = {isConfirm}
disabled = {isEnable===false}
onChange={(e)=> setIsConfirm(e.target.checked)}
>
 </Form.Check>
                }
 </Col>
                 {order &&

 
       <Col md={7}> 
                <row onClick={() => setIsModalOpen(true)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
               Mesafeli Satış  Sözleşmesini
             </row> {(tempOrder.isOrdered || tempOrder.isPaid) ?(''):('kabul ediyorum.')} 

      <DocumentModal
        isOpen={isModalOpen}
        content={documentContent}
        onClose={() => (setIsModalOpen(false), setIsEnable(true))}
      />
</Col>
}
</Row>
                    </ListGroup.Item>
                    
                ):(!tempOrder.isOrdered && <ListGroup.Item>
                    <Row>
                   <Col>                    <Button type='button' className = 'btn btn-block'
                   disabled={ isConfirm===false}
                    onClick={orderRequestHandler}>
                      Siparişi Başlat
                    </Button>
                    </Col>


                    
                <Col md={1}>
{!tempOrder.isOrdered &&                 
<Form.Check
type="checkbox"
checked = {isConfirm}
disabled = {isEnable===false}
onChange={(e)=> setIsConfirm(e.target.checked)}
>
 </Form.Check>
}
 </Col>
                 {order &&

 
       <Col md={7}> 
                <row onClick={() => setIsModalOpen(true)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
               Mesafeli Satış  Sözleşmesini
             </row> {(tempOrder.isOrdered || tempOrder.isPaid) ?(''):('kabul ediyorum.')} 

      <DocumentModal
        isOpen={isModalOpen}
        content={documentContent}
        onClose={() => (setIsModalOpen(false), setIsEnable(true))}
      />
</Col>
}
</Row>
                </ListGroup.Item>)))}

                {/*OFFER PLACEHOLDER */}
                {loadingOffer&& <Loader/>}

                {userInfo && userInfo.isAdmin && !tempOrder.isOffered && (
                    <ListGroup.Item>
                        <Button type='button' className = 'btn btn-block'
                        onClick={offerSendHandler}>
                          Teklifi Gönder
                        </Button>
                    </ListGroup.Item>
                    
                )}        


                
                {loadingPay&& <Loader/>}
                { userInfo && userInfo.isAdmin && !tempOrder.isPaid &&
                (
                    <ListGroup.Item>
                     {/* THIS BUTTON IS FOR TESTING! REMOVE BEFORE PRODUCTION! */}
                      <Button
                        style={{ marginBottom: '10px' }}
                        onClick={onApproveTest}
                      >
                        Ödendi İşaretle
                      </Button> 
                      </ListGroup.Item>
                      )}


                      </ListGroup>
            </ListGroup>
        </Card>
        
     {userInfo&&(userInfo.isAdmin&& !tempOrder.isOffered &&            
     <Button
                  type='button'
                  className='btn-block'

                  onClick={submitHandler}
                >
                
                 Teklifi Güncelle
     </Button>
  )}
              
   {userInfo&&(userInfo.isAdmin&& !tempOrder.isDelivered &&  
     <label>
          Input:
          {/* Input element with value and onChange event handler */}
          <input type="Number" value={deliverNumber}  onChange={(e)=> setDeliverNumber(e.target.value)} />
    </label>
    )}

        </Col>

    </Row>
   
   
    </>
    
  ) ;
  
}

export default OrderScreen